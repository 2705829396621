import React, { useState, useEffect } from "react";
import Template from "../../Components/Template";
import { RangeSlider } from "../../Components/Slider";
import ContentHeader from "../../Components/ContentHeader";
import * as moment from "moment";
import { site_type } from "../../config";

let s = site_type

const Settings = () => {
  // const _5m_change = localStorage.getItem('5m_change') || 0
  // const _10m_change = localStorage.getItem('10m_change') || 0
  // const _15m_change = localStorage.getItem('15m_change') || 0

  const rows_per_page = localStorage.getItem("rows_per_page") || 20;

  const last_traded_display = localStorage.getItem('last_traded') || 0

  const slider_or_dropdown = localStorage.getItem('slider_or_dropdown') || 0 //0 for dropdown, anything else: slider

  // const chart_visibility = localStorage.getItem('defaultChartVisibility') || "Show"

  // const streak_select = localStorage.getItem('streak_select') || "bar"

  // const vol_filter = localStorage.getItem('vol_filter') || 0

  useEffect(() => {
    let mySelect = document.getElementById("rows_per_page");
    for (var i, j = 0; (i = mySelect.options[j]); j++) {
      if (i.value == rows_per_page) {
        mySelect.selectedIndex = j;
        break;
      }
    }

    mySelect = document.getElementById("last_traded_display");
    for (var i, j = 0; (i = mySelect.options[j]); j++) {
      if (i.value == last_traded_display) {
        mySelect.selectedIndex = j;
        break;
      }
    }

    mySelect = document.getElementById('slider_or_dropdown');
    for (var i, j = 0; i = mySelect.options[j]; j++) {
        if (i.value == slider_or_dropdown) {
            mySelect.selectedIndex = j;
            break;
        }
    }

    // mySelect = document.getElementById('chart_show');
    // for (var i, j = 0; i = mySelect.options[j]; j++) {
    //     if (i.value == chart_visibility) {
    //         mySelect.selectedIndex = j;
    //         break;
    //     }
    // }

    // mySelect = document.getElementById('streak_select');
    // for (var i, j = 0; i = mySelect.options[j]; j++) {
    //     if (i.value == streak_select) {
    //         mySelect.selectedIndex = j;
    //         break;
    //     }
    // }

    // mySelect = document.getElementById('vol_filter');
    // for (var i, j = 0; i = mySelect.options[j]; j++) {
    //     if (i.value == vol_filter) {
    //         mySelect.selectedIndex = j;
    //         break;
    //     }
    // }
  }, []);

  const handleSelection = ((k ,v) => {
    localStorage.setItem(k, v)
    window.location.reload()
  })

  return (
    <Template>
      <ContentHeader text={`Display Settings`} />
      <div className="card card-default">
        <div className="card-header">
          <h3 className="card-title">Select Display Format</h3>
          <div className="card-tools">
            <button
              type="button"
              className="btn btn-tool"
              data-card-widget="collapse"
            >
              <i className="fas fa-minus" />
            </button>
          </div>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 ">
              <div className="form-group">
                <label>Rows Per page</label>
                <select
                  className="custom-select"
                  onChange={(e) => handleSelection('rows_per_page', e.target.value)
                  }
                  id="rows_per_page"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25" >25</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="60">60</option>
                  <option value="70">70</option>
                  <option value="80">80</option>
                  <option value="90">90</option>
                  <option value="100" disabled={s != 'pro'}>100 {s != 'pro' ? 'Pro users only' : ''}</option>
                  <option value="150" disabled={s != 'pro'}>150 {s != 'pro' ? 'Pro users only' : ''}</option>
                  <option value="200" disabled={s != 'pro'}>200 {s != 'pro' ? 'Pro users only' : ''}</option>
                  <option value="250" disabled={s != 'pro'}>250 {s != 'pro' ? 'Pro users only' : ''}</option>
                  <option value="500" disabled={s != 'pro'}>500 {s != 'pro' ? 'Pro users only' : ''}</option>
                  <option value="1000" disabled={s != 'pro'}>1000 {s != 'pro' ? 'Pro users only' : ''}</option>
                </select>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 ">
              <div className="form-group">
                <label>Last Traded Display Format</label>
                <select
                  className="custom-select"
                  onChange={(e) => handleSelection('last_traded_display', e.target.value)
                  }
                  id="last_traded_display"
                >
                  <option value="0">November 30, 2020 3:44 PM</option>
                  <option value="1">29 minutes ago</option>     
                  <option vlaue="2">1606769067000</option>          
                </select>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 ">
              <div className="form-group">
                <label>Filter View</label>
                <select
                  className="custom-select"
                  onChange={(e) => handleSelection('slider_or_dropdown', e.target.value)
                  }
                  id="slider_or_dropdown"
                >
                  <option value="0">Dropdowns</option>
                  <option value="1">Sliders + Dropdowns</option>     
                </select>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Template>
  );
};

export default Settings;
