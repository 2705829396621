import React, { useState, useEffect } from 'react'
import { CustomTable } from "../../Components/CustomTable";
import Pagination from "../../Components/CustomTable/pagination"
import Template from "../../Components/Template";
import ContentHeader from "../../Components/ContentHeader";
import "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import { APIURL, site_type, ROWS_PER_PAGE } from "../../config";
import { getHeaderMapSkew, getCSVHeaderCustom } from "../../Util/index";
import { ScatterChart } from "../../Components/Chart/scatterChart"
import moment from 'moment';
import CallAbout from "./CallAbout"
import { LoadingIcon } from "../../Components/LoadingIcon"
import UpgradeFooter from '../../Components/Upgrade/UpgradeFooter';
import { CSVLink } from "react-csv";
import Tippy from "@tippyjs/react";

export default function CallSkew() {
    const [data, setData] = useState([]);
    const [dldata, setDLData] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [scatterData, setScatterData] = useState([])

    const [page, setPage] = useState(0)

    const loadInfo = async (loadall = false) => {

        const res = await fetch(
            `${APIURL}/skew-index?p=${site_type == "pro" ? "1" : "0"}&page_size=${ROWS_PER_PAGE}&type=call&page=${page}&loadall=${!loaded ? '1' : '0'}`
        );
        if (res.status == 200) {
            const json = await res.json();
            setData(json)
            setDLData(<CSVLink
                headers={getCSVHeaderCustom('call_skew')}
                data={json.data}
                filename={"download.csv"}
                className="btn"
                target="_blank"
            >
                <Tippy content="Download this page. Change rows per page in settings to download more at once."><i className="fas fa-download"></i></Tippy>
            </CSVLink>)
            if (json.all) {
                setScatterData(json.all)
            }
            setLoaded(true)
        } else {
            console.log("API CALL FAIL");
        }
    }

    useEffect(() => {
        loadInfo(true)
    }, [page])

    return (
        <Template>
            <ContentHeader text={`Call Skew Index`} />
            <div className="card">
                <div className="card-body">
                    <CallAbout />
                    {loaded && <ScatterChart
                        data={scatterData}
                        xlabel={'Implied Volatility %'}
                        ylabel={'Call Skew Index'}
                        title='Call Skew'
                        x="iv"
                        y="pd"
                        mapping={defaultMapping}

                    />}
                    <div>
                        <div className="text-centered">{!loaded && <LoadingIcon />}</div>
                        <div className="row">
                            <div className="col">{dldata}</div>
                            <div className="col text-center">{data.last_updated && `Last Updated: ${moment(new Date(0).setUTCSeconds(data.last_updated)).toString()}`}</div>
                            <div className="col text-right">{loaded && <Pagination page={data.current_page} setPage={setPage} maxPage={data.pages} />}
                            </div>
                        </div>
                        {loaded && <CustomTable data={data.data} headerMap={getHeaderMapSkew('Call')} currentPage={data.current_page} pageSize={20} />}
                    </div>
                </div>
               
            </div>
            <UpgradeFooter page="Skew Scanner"/>

        </Template>
    )
}



const defaultMapping = {
    'tk': {
        'name': 'Ticker',
        'prepend': '',
        'append': ''
    },
    'pd': {
        'name': 'Call Skew',
        'prepend': '',
        'append': ''
    },
    'name': {
        'name': 'Name',
        'prepend': '',
        'append': ''
    },
    'sec': {
        'name': 'Sector',
        'prepend': '',
        'append': ''
    },
    'sp': {
        'name': 'Share Price (SP)',
        'prepend': '$',
        'append': ''
    },
    'iv': {
        'name': 'IV',
        'prepend': '',
        'append': '%'
    },
    'hp': {
        'name': '110% SP',
        'prepend': '$',
        'append': ''
    },
    'lp': {
        'name': '90% SP',
        'prepend': '$',
        'append': ''
    },
    'hpcp': {
        'name': '110% SP Call Price',
        'prepend': '$',
        'append': ''
    },
    'lppp': {
        'name': '90% SP Put Price',
        'prepend': '$',
        'append': ''
    },

}
