import React, { useEffect, useState } from "react";
import "./index.css";
import { filterMapReverse } from "../../../Util";
import { APIURL } from "../../../config";
import DropdownFilter from "../DropdownFilter";

import Toast from "../../../Components/Toast";

const saveSuccess = {
  title: "Save Success",
  body:
    'Click "bookmarked filters" on the left menu to view and manage your bookmarked filters.',
  color: "success",
};

const saveFail = {
  title: "Nothing to save!",
  body: "No filters were set for saving.",
  color: "warning",
};

const saveFailEmptyName = {
  title: "Empty Name",
  body: "Set a name to save filter",
  color: "warning",
};

export const SavedFiltersTab = () => {
  // const [allFilters, setAllFilters] = useState({});

  // useEffect(() => {
  //   setAllFilters(JSON.parse(localStorage.getItem("savedFilters") || "{}"));
  // }, []);

  // const deleteFilter = (key) => {
  //   let newAllFilters = allFilters;
  //   delete newAllFilters[key];
  //   setAllFilters((curr) => newAllFilters);
  //   localStorage.setItem("savedFilters", JSON.stringify(newAllFilters));
  // };

  // const loadFilter = (key) => {};

  const [showToast, setShowToast] = useState(false);
  const [toastInfo, setToastInfo] = useState(saveSuccess);

  const clickedSave = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 5000);
  };

  const saveFilter = (text) => {
    let savedFilters = JSON.parse(localStorage.getItem("savedFilters") || "{}");
    console.log(savedFilters);
    let currentFilter = JSON.parse(sessionStorage.getItem("currentFilter"));
    if (Object.keys(currentFilter).length > 0 && text) {
      savedFilters[text] = currentFilter;
      localStorage.setItem("savedFilters", JSON.stringify(savedFilters));
      console.log(savedFilters);
      setToastInfo(saveSuccess);
    } else {
      console.log("no filter set");
      setToastInfo(saveFail);
    }
    clickedSave();
  };

  return (
    <>
      {showToast && (
        <Toast
          title={toastInfo.title}
          body={toastInfo.body}
          color={toastInfo.color}
        />
      )}

      <form className="form-horizontal">
        <div className="card-body">
          <div className="form-group row">
            <label htmlFor="inputName" className="col-sm-2 col-form-label">
              Filter Name
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="inputName"
                placeholder="Theta Gang Monthlies, Value Stocks"
              />
            </div>
          </div>
        </div>
        {/* /.card-body */}
      </form>
      <button
        className="btn btn-info"
        onClick={() => {
          saveFilter(document.getElementById("inputName").value);
        }}
      >
        Save
      </button>
    </>
  );
};
