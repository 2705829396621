import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import auth from '../../Routes/auth';
import { PatreonLogin } from '../../Routes/patreonLogin';
import { LOGINURL  } from "../../config";
import queryString from 'query-string'
import Cookies from 'js-cookie';
import {
    useParams,
} from "react-router-dom";
const Login = () => {

    const [errorMsg, setErrorMsg] = useState('')
    let { code } = useParams()

    const WPlogin = async () => {
        let token = await auth.WPlogin()
        //console.log(token)
        if (!token) {
            console.log("INVALID USER OR PASS")
            setErrorMsg('Invalid email or password')
            return false
        }

        let tokenCheck = await auth.WPtokenCheck(token)
        if (!tokenCheck) {
            setErrorMsg('Not subscribed to FDscanner')
            console.log("Not subscribed")
            return false
        }

        window.location.href = "/"
    }

    useEffect(() => {
        var queryDict = {}
        window.location.search.substr(1).split("&").forEach(function (item) { queryDict[item.split("=")[0]] = item.split("=")[1] })
        console.log(queryDict)

        if (queryDict.code) {
            console.log(queryDict.code)
            getUserInfo(queryDict.code)
            return
        } else {
            console.log('no code')
        }
    }, [])


    const getUserInfo = (code) => {
        const formData = new URLSearchParams();
        console.log(code)
        formData.append('code', code);
        console.log(formData.get("code"))

        fetch(`${LOGINURL}/users/signin`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                //'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            //redirect: 'follow', // manual, *follow, error
            body: formData // body data type must match "Content-Type" header
        })
            .then(data => {
                console.log(data)
                const status = data.status
                console.log('d1', data)
                data.json().then(data => {
                    console.log('d2', data)

                    Cookies.set('access_token', data.access_token, { expires: 7 })
                    Cookies.set('email', data.email, { expires: 7 })
                    Cookies.set('tier', data.tier, { expires: 7 })
                    Cookies.set('pm', data.cents_pledged, { expires: 7 })
                    // window.location.href = "/"
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    return (
        <div className="login-page">
            <div className="login-box">
                <div className="login-logo">
                    <b>FD</b>Scanner Pro
                </div>
                {/* /.login-logo */}
                <div className="card">
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">Sign in to start your session</p>
                        <div className="input-group mb-3">
                            <input type="email" className="form-control" placeholder="Email" id="email" />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-envelope" />
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input type="password" className="form-control" placeholder="Password" id="password" />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                </div>
                            </div>
                        </div>
                        <p className="text-center text-danger">{errorMsg}</p>
                        <div className="row">
                            <div className="col-8">

                            </div>
                            {/* /.col */}
                            <div className="col-4">
                                <button className="btn btn-primary btn-block" onClick={() => WPlogin()}>Sign In</button>
                            </div>
                            {/* /.col */}
                        </div>


                        <p className="mb-1">
                            <a href="https://fdscanner.com/lost-password/" target="_blank">I forgot my password</a>
                        </p>

                    </div>
                    {/* /.login-card-body */}
                    
                </div>
                <p className="text-center">Don't have an account? <a href="https://fdscanner.com/subscribe/" target="_blank">Sign up here</a></p>

                <br/><br/>
                <div className="d-flex justify-content-center align-items-center"><PatreonLogin /></div>
            </div>
        </div>
    )
}

export default Login;