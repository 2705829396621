import React, { useState, useEffect } from "react";
import { createChart } from "lightweight-charts";
import { APIURL, MIIALGOURL } from "../../config";
import * as moment from "moment";
import "moment-timezone";
import "./index.css";

const SeriesMarker = ({ ticker, date }) => {
  const [data, setData] = useState([]);
  const [signals, setSignals] = useState([]);

  const tzoffset = moment(moment.utc()).tz("America/New_York").utcOffset()/60
  console.log('tz offset', tzoffset)

  const colorSwitch = (text) => {
    let _type = text.split(" ")[0];
    switch (_type) {
      case "Waning":
        return "#ffc447";

      case "Steady":
        return "#17a2b8";

      case "Surge":
        return "#dc3545";
    }
  };

  const labelSwitch = (text) => {
    let _type = text.split(" ")[0];
    switch (_type) {
      case "Waning":
        return "W";

      case "Steady":
        return "S";

      case "Surge":
        return "SSS";
    }
  };

  const DataTransform = (data) => {
    let timeMap = {};

    let graphData = [];
    for (let i = 0; i < data.length; i++) {
      let row = data[i];
      if (row.time in timeMap) {
        continue;
      } else {
        timeMap[row.time] = true;
      }
      let rowTransformed = {
        time: moment(row.time).add("hours", tzoffset).unix(),
        open: row.Open,
        high: row.High,
        low: row.Low,
        close: row.Price,
      };
      graphData.push(rowTransformed);
    }

    return graphData;
  };

  const SignalTransform = (data) => {
    let signalData = [];
    for (let i = 0; i < data.length; i++) {
      let row = data[i];
      let rowTransformed = {
        time: moment(row.time).add("hours", tzoffset).unix(),
        position: row.type.includes("Up") ? "belowBar" : "aboveBar",
        color: colorSwitch(row.type),
        shape: row.type.includes("Up") ? "arrowUp" : "arrowDown",
        text: labelSwitch(row.type.split(" ")[0]),
      };
      signalData.push(rowTransformed);
    }

    //console.log('raw signals', signalData)
    return signalData;
  };

  const GetData = async () => {
    const res = await fetch(
      `${MIIALGOURL}/ticker?date=${date}&days=1&desc=0&ticker=${ticker.toUpperCase()}`
    );
    const res2 = await fetch(
      `${MIIALGOURL}/historical/marketmovers?date=${date}&days=1&type=Exist&desc=0&ticker=${ticker.toUpperCase()}`
    );

    if (res.status == 200 && res2.status == 200) {
      const json = await res.json();
      const json2 = await res2.json();
      if (json.data.length == 0) {
        return;
      }
      let transformedData = DataTransform(json.data);
      setData(transformedData);

      let transformedSignals = SignalTransform(json2.data);
      setSignals(transformedSignals);

      console.log(json.data);
      console.log(json2.data);

      try {
        CreateChart(transformedData, transformedSignals);
      } catch {
        console.log("Chart create error");
      }
    } else {
      setData([]);
    }
  };

  const CreateChart = (transformedData, transformedSignals) => {
    var chart = createChart(document.getElementById("chart2424241"), {
      width: window.innerWidth * 0.8,
      height: 400,
      timeScale: {
        timeVisible: true,
        borderColor: "#D1D4DC",
      },
      rightPriceScale: {
        borderColor: "#D1D4DC",
      },
      layout: {
        backgroundColor: "#ffffff",
        textColor: "#000",
      },
      grid: {
        horzLines: {
          color: "#F0F3FA",
        },
        vertLines: {
          color: "#F0F3FA",
        },
      },
    });

    var series = chart.addCandlestickSeries({
      upColor: "rgb(38,166,154)",
      downColor: "rgb(255,82,82)",
      wickUpColor: "rgb(38,166,154)",
      wickDownColor: "rgb(255,82,82)",
      borderVisible: false,
    });

    // console.log("create charrt", transformedData)
    // console.log("signals", transformedSignals)

    series.setData(transformedData);
    series.setMarkers(transformedSignals);

    console.log(transformedData);
    console.log(transformedSignals);
  };

  useEffect(() => {
    //console.log(date)
    GetData();
    // console.log('tz offset', moment(moment.utc()).tz("America/New_York").utcOffset())
  }, [date]);

  return (
    <div className="mw">
        <div id="chart2424241">
          {data.length == 0 && "No Data"}
        </div>
    </div>
  );
};

export default SeriesMarker;
