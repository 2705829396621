import React, { useEffect, useState } from "react";
import "./index.css";
import DropdownFilter from "../DropdownFilter"
import { FilterNameValueMap } from "../../../Util";

export const GreeksTab = () => {
    return (
        <div className="row">
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Delta" options={FilterNameValueMap['Delta']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Gamma" options={FilterNameValueMap['Gamma']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Vega" options={FilterNameValueMap['Vega']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Theta Annual %" options={FilterNameValueMap['Theta Annual %']} /></div>
        </div>
    )
}
