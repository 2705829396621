import React from 'react'
import { Link } from 'react-router-dom'

export function Button({ text = "empty", color = "primary", disabled = false }) {
    return (
        <button type="button" className={`btn-block btn-xs btn-${color}`} disabled={disabled} style={{width: 75}}>{text}</button>
    )
}

export function LinkedButton({ link, text = "empty", color = "primary", disabled = false }) {
    return (
        <Link to={link} target="_blank">
            <Button text={text} color={color} disabled={disabled} />
        </Link>
    )
}

export function Icon({ iconName = "fas fa-chevron-up", color }) {
    return (
        <i className={`${iconName} ${color && `text-${color}`}`} />
    )
}

export function LinkedIcon({ iconName = "fas fa-chevron-up", link, color }) {
    return (
        <Link to={link} target="_blank">
            <Icon iconName={iconName} color={color}/>
        </Link>
    )
}

