import React from "react";
import Header from "./Header/index";
import Menu from "./Menu";
import Modal from "../../Components/Modal";
import Footer from "./Footer";
import { site_type } from "../../config";

export default function index({ children }) {
  return (
    <div>
      <Header />
      <Menu />
      <Modal />
      <div className="content-wrapper">
        <section className="content">
          {site_type != "pro" && <div className="alert alert-info text-center">
            {/* <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
            <h5><i className="icon fas fa-info" /> Alert!</h5> */}
            <span className="text-center">
              <a href="https://fdscanner.com/subscribe/" target="_blank">NEW: FDscanner Pro Version available.</a>
            </span>
          </div>}
          {children}</section>
      </div>
      <Footer />
    </div>
  );
}

