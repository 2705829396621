import React, { useEffect, useState } from "react";
import "./index.css";
import TradingViewWidget from 'react-tradingview-widget';


export const TradingViewChart = ({ ticker }) => {


    // const createChart = () => {
    //     let script = document.createElement("script");
    //     script.async = false;
    //     script.src = "https://s3.tradingview.com/tv.js";
    //     document.body.appendChild(script)


    // }

    // useEffect(() => {
    //     createChart()
    // }, [])

    return <div className="c">
        <TradingViewWidget symbol={ticker} autosize range="12m" timezone="America/New_York"/>
    </div>

}

