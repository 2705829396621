import React from "react";
import Template from "../../../Components/Template";
import ContentHeader from "../../../Components/ContentHeader";
import {descriptions} from "../../../Util";

export default function ColumnDescriptions() {

    return (
        <Template>
            <ContentHeader text={`Column Descriptions - Option Scanner`} />
            <section className="content">
                {/* Default box */}
                <div className="card">
                    <div className="card-body p-0">
                        <ul>
                            {Object.keys(descriptions).map(e => {
                                return <>
                                    <h3>{e}</h3>
                                    <ul>
                                        {Object.keys(descriptions[e]).map(f => <li><b>{f}</b> - {descriptions[e][f]}</li>)}
                                    </ul>
                                    <br/>
                                </>
                            })}
                        </ul>
                    </div>
                    {/* /.card-body */}
                </div>
                {/* /.card */}
            </section>
        </Template>
    );
}
