import React, { useState, useEffect } from "react";
import { APIURL, MIIALGOURL } from "../../../config";
import SeriesMarker from "../../../Components/Chart/SeriesMarker";
import FixedHeaderTable from "../../../Components/FixedHeaderTable";
import Nodata from "./Nodata";

export const MiiAlertTab = ({ ticker }) => {
  const [data, setData] = useState([]);
  const [date, setDate] = useState("");
  const [loaded, setLoaded] = useState(false);

  const [isEmpty, setIsEmpty] = useState(false)

  const GetTickerData = async () => {
    const res = await fetch(`${MIIALGOURL}/ticker-fd?ticker=${ticker}`);
    if (res.status == 200) {
      const json = await res.json();
      if (json.data.length == 0){
          setIsEmpty(true)
          return
      }

      setDate(json.t);
      setData(json.data)

    } else{
        setIsEmpty(true)
    }
    setLoaded(true);
  };

  useEffect(() => {
    GetTickerData();
  }, []);

  if (!loaded){
      return <>loading</>
  }

  if (loaded && isEmpty){
      return <Nodata ticker={ticker}/>
  }

  return (
    <>
      <h5 className="text-center">
        {ticker} MiiAlgo Alerts
      </h5>
      <p>
        <b>{ticker}</b> recently triggered alerts on <b>{date}</b> from having
        extreme momentum and volume. You can get these alerts in real time on{" "}
        <a href="https://miialgo.com/" target="_blank">
          MiiAlgo
        </a>
        , an intraday momentum stock screener I made.
      </p>
      <b>Legend</b>
      <ul>
        <li>
          <span>
            <i className="fas fa-fire-alt text-danger"></i> - <b>SURGE</b> Majority of price movement occurred in last 3 minutes.
          </span>
        </li>
        <li>
          <span>
            <i className="fas fa-bacon text-info" /> - <b>STEADY</b> Price movement distributed evenly in the last 15 minutes.
          </span>
        </li>
        <li>
          <span>
            <i className="fas fa-water text-warning" />
            - <b>WANING</b> Weakening Momentum, decreasing Rate of Change. Tighten your trailing stops.
          </span>
        </li>
      </ul>
      <p><a href="https://miialgo.com/" target="_blank">MiiAlgo</a> is primarily built for intraday momentum traders, traders that aim to jump in early on a strong momentum movement, ride the wave and exit within the hour.<br/>
      You can search for other tickers that experienced strong price movements recently to see if my algorithm also picked it up.</p>
      <h5 className="text-center">
        {ticker} Chart for {date}
      </h5>
      <p>Shift and zoom in/out the graph to see exactly when the alerts triggered.</p>
      {loaded && <SeriesMarker ticker={ticker} date={date} />}
      <FixedHeaderTable body={data} headerMap={headerMap} />
    </>
  );
};

// return <span><i className="fas fa-bacon text-info" />&nbsp;&nbsp;<b>STEADY</b></span >

// case "Waning Down Momentum":
// case "Waning Up Momentum":
//     return <span><i className="fas fa-water text-warning" />&nbsp;&nbsp;<b>WANING</b></span>

// case "Surge Up Momentum":
// case "Surge Down Momentum":
//     return <span><i className="fas fa-fire-alt text-danger"></i>&nbsp;&nbsp;<b>SURGE</b></span >

// default:
//     return ''

const headerMap = {
  time: "Time",
  Ticker: "Ticker",
  Dir: "Dir",
  Price: "Price",
  "5m_pct": "5m Change",
  "10m_pct": "10m Change",
  "15m_pct": "15m Change",
  Roc: "Roc",
};
