import React from 'react'
import Template from "../../Components/Template";
import ContentHeader from "../../Components/ContentHeader";
import { Link } from 'react-router-dom';
import NTRimage from "../../assets/img/NTR-msft.png"
import HelmetComponent from '../../Components/Helmet';

let title = `Tendies Index for Options - About | FDscanner`
let description = `Tendies index shows how many times the options will be worth at the end of this week, if a reasonable price target is hit.
                    We build this index because option mispricing occurs more often on short expiration, OTM options.`


export default function TendiesAbout() {
    return (
        <Template>
            <HelmetComponent title={title} description={description} />
            <ContentHeader text={`Computing Tendies Index`} />

            <div className="card">
                <div className="card-body">
                    <section className="content">
                        <h4>Table of contents</h4>
                        <ul>
                            <li>About Max Tendies Index</li>
                            <li>Background on NTR indicator</li>
                            <li>What does NTR indicate?</li>
                            <li>What is NTR used for?</li>
                            <li>Step by Step NTR computation</li>
                            <li>Why not just use ATR, Bollinger Bands or some existing indicator?</li>
                            <li>What are Tendies?</li>
                            <li>Fridays</li>
                        </ul>
                        <br />
                        <h3>About Max Tendies Index</h3>
                        <hr />
                        <p>Which weekly option has the highest multibagger potential? The Tendies Index finds all weekly OTM options with good liquidity and assumes the stock will hit
                        a reasonable price target based on the stock's past 10 days price movement, using a modified Average True Range, called Not True Range. If that price target is hit,
            dividing the option's intrinsic value/current option price gives the <b>Tendies Index</b>.

                </p>
                        <br />
                        <h4>Background on NTR indicator</h4>
                        <hr />
                        <p>
                            NTR is a quant indicator I designed from scratch back in 2017. With that indicator, I built an intraday mean reversion trading algorithm
                that won <a href="https://www.quantopian.com/contest" target="_blank">Quantopian's</a> 6 months paper algo trading contest.
                 Quantopian funded my algorithm with their money and my algorithm traded live for 11 months. <br /><br />
                Because this index is based on options expiring the same week (or even the same day), the holding periods are very similar and
                we needed a strong proxy for above average price movement, NTR is the custom indicator chosen for the job.
                </p>
                        <br />
                        <h4>What does NTR indicate?</h4>
                        <hr />
                        <p>
                            It's a historical volatility indicator based on the stock's past 10 days price movement.
            NTR uses the same computation as <a href="https://www.investopedia.com/terms/a/atr.asp" target="_blank">Average True Range</a> to compute daily True Range for the past 10 days.
            From those 10 days, the highest and 4 lowest True Range is excluded, and the remaining 5 True Range is averaged to give NTR.
            <br /><br />
            To compare NTR between different stocks, the value is divided by current stock price to give NTR %. This value can be used to compare between stocks, high NTR % means
            that stock experienced greater volatility over the past 10 days. NTR % is not used in this index, but provides an apples to apples comparison of volatility between stocks.
                </p>
                        <br />
                        <h4>What is NTR used for?</h4>
                        <hr />
                        <p>
                            To create price targets.
            <ul>
                                <li>Current Price + NTR = Price Target for Calls</li>
                                <li>Current Price - NTR = Price Target for Puts</li>
                            </ul>
            Think of it as hitting the upper or lower Bollinger Bands, but instead hitting the upper/lower NTR range.
            With the price target, we compute how much each option is worth at expiry, and divide that by current option midpoint price to get the <b>Tendies Index</b>.
                </p>
                        <br />
                        <h4>Step by Step NTR computation</h4>
                        <hr />
        Visit the individual stock ticker page by searching for it in the search bar.
        Navigate to the tab to view the step by step computation of NTR and Call/Put NTR price target.<br />

                        <ul>
                            <li><Link to="/ticker/msft" target="_blank">MSFT's NTR computation</Link></li>
                            <li><Link to="/ticker/aapl" target="_blank">AAPL's NTR computation</Link></li>
                            <li><Link to="/ticker/amzn" target="_blank">AMZN's NTR computation</Link></li>
                        </ul>
                        <br />
       Example below for MSFT on 2020-05-22.<br />
                        <div><img src={NTRimage} style={{ 'max-width': '100%' }}></img></div>
                        <br />
                        <h4>Why not just use ATR, Bollinger Bands or some existing indicator?</h4>
                        <hr />
                        <p>
                            Most existing indicators uses some form of time period average. I dislike this because it gets heavily skewed by an extremely high or low value over the time period.
            It also jumps a lot, especially if yesterday's data OR the earliest day (that's just removed) used for the indicator had an extreme high/low value.<br /><br />

            The NTR indicator is more stable because it excludes extreme movements, and the value doesn't always change day to day if the new value or newly removed value is excluded in the calculation.
            If the value changes (being one of the 5 out of 10 accepted values), it's also not a huge value change too as it's within the 40-90 percentile of True Range values. <br /><br />

                        </p>

                        <br />


                        <h3>Computing Tendies Index</h3>
                        <hr />
                        <b>Tendies Index</b> = Option Instrinsic Value/Current Midpoint Option Price, if the Price Target is hit.
                <ul>
                            <li>Price Target = Current Price + NTR for Calls</li>
                            <li>Price Target = Current Price - NTR for Puts</li>
                        </ul>
        It's a straightforward number that show how many times the option will be worth relative to right now, if price target is hit at expiry. Tendies index of 20 means the
        option will be a 20 bagger at price target. It means it will be a worthwhile trade if price target is hit with greater than 1/20, or 5% probability. The actual value is higher
        if the price target is hit and the position is closed before expiry, because there will still be extrinsic value in the option, which is not accounted for in this index.
        <br /><br />

                For the option to be included in the Tendies Index, the strike must be OTM right now, be ITM at the price target and pass a custom liquidity filter.


        <br /> <br />
                        <h4>Fridays</h4>
                        <hr />
                Because the current option price decreases exponentially as the week goes on, the longer the Tendies Index is running, the higher the Tendies multiplier becomes.
                For the same price movement, the multibagging potential is many times higher than an equal trade earlier in the week.<br />
                The option time to expiry may be 1/3 if opened on Friday compared to Wednesday, but the tendies index can be 10x - 30x higher.
        <br />
                Consider playing both sides and opening a strangle if you do not have a directional thesis and the options are priced really low. The Tendies index stops updating Friday, 90 mins after open.


        <p></p>
                        <br />

                    </section>
                </div>
            </div>

        </Template>
    )
}
