import React from 'react'
import Template from "../../Components/Template";
import ContentHeader from "../../Components/ContentHeader";
import { Link } from 'react-router-dom';

export default function CallAbout() {
    return (
       <>
            <h4>Skew Index</h4>
            <p>Call skew is defined as the price of 10% OTM calls/10% OTM puts for the next monthly option expiry. A call skew of 1.3 means the 10% OTM call is 1.3x the price of 10% OTM put. There are often underlying reasons worth researching before entering option position for stocks with high call or put skew.
            <Link to="/aboutskew">Click here</Link> to read the detailed formula to compute skew. Alternatively search for your ticker and view the Skew Calculation tab to see the step by step computation</p>
            <h4>Potential Causes of Call Skew</h4>
            <p>Theoretically, an equally OTM put and call trades at the same price after accounting for cost of carry, dividends and shorting costs. However, the real world doesn't always follow theory. Demand supply imbalances do happen and below are some possible reasons.</p>
            <ul>
                <li>Unusual and extreme speculation of stocks. Eg: TSLA battery day</li>
                <li>Stocks with limited downside but very high upside. Eg: Bankrupt stock trading at a fraction of book value, or SPACs near PIPE value</li>
                <li>High demand/Low supply for calls, driving up call prices.</li>
                <li>Low demand/High supply for puts, driving down put prices.</li>
            </ul>
            <h4>Stock Universe</h4>
            <p>Calculating the skew requires 4 option prices, the 2 put options surrounding 90% current share price, and the 2 call options surrounding 110% of current share price. All 4 of these options must have bid/ask spread of below 25% to be included in the Put/Call Skew Index.</p>
            <p>From the universe of S&P500 stocks, if the stock passes the above liquidity criteria, it will be placed in Put Skew if the put price exceeds call price, and placed in Call Skew if call price exceeds put price.</p>
            <h4>Option Expiry Used</h4>
            <p>Skew uses the next monthly expiration prices for computation.</p>
            <h4>Computation</h4>
            <p><Link to="/aboutskew">Click here</Link> to view detailed step by step computation of skew</p>
        </>
    )
}
