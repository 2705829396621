import React from 'react'
import { Link } from 'react-router-dom'
import "./index.css"

export default function index({ text }) {
    return (
        <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>{text}</h1>
                    </div>
                    <div className="col-sm-6">
                        <div className="pullright">
                            <Link to="/column-descriptions">
                                <button type="button"
                                    className={`btn btn-info`}
                                >Column Descriptions</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
