import React, { useState, useEffect } from "react";
import { ScatterChart } from "../../Components/Chart/scatterChart";
import { APIURL, site_type, JSONURL } from "../../config";
import { Link } from "react-router-dom";

export const SpacIntro = ({}) => {
  const [universe, setUniverse] = useState([]);

  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [selectedTicker, setSelectedTicker] = useState("PSTH");
  const [tickerInfo, setTickerInfo] = useState({});

  const loadUniverse = async () => {
    const res = await fetch(`${APIURL}/spac-universe`);
    if (res.status == 200) {
      const json = await res.json();
      setUniverse(json.data);
      //   setSelectedTicker(
      //     json.data[Math.floor(Math.random() * json.data.length)]
      //   );
      setLoaded(true);
    } else {
      console.log("API CALL FAIL");
    }
  };

  const loadTickerInfo = async () => {
    const res = await fetch(`${JSONURL}/stockdata/${selectedTicker}/info`);
    if (res.status == 200) {
      const json = await res.json();
      setTickerInfo(json);
      setLoaded(true);
    } else {
      console.log("API CALL FAIL");
    }
  };

  useEffect(() => {
    loadUniverse();
  }, []);

  useEffect(() => {
    setLoaded(false);
    setTickerInfo({});
    loadTickerInfo();
  }, [selectedTicker]);

  return (
    <div className="">
      <div className="row">
        {universe.map((e) => {
          return (
            <div className="col-md-2 col-4" key={e}>
              <button
                type="button"
                className={`btn btn-block ${
                  selectedTicker == e ? "btn-info" : "btn-outline-info"
                } btn-sm tk-btn`}
                onClick={() => setSelectedTicker(e)}
              >
                {e}
              </button>
            </div>
          );
        })}
      </div>
      <br />
      {!loaded && "Loading"}
      {Object.keys(tickerInfo).length > 0 && (
        <>
          <h5>
            {selectedTicker} Summary - ${tickerInfo.p}
          </h5>
          <p>{tickerInfo.desc}</p>
          {parseFloat(tickerInfo.call_skew) > 0 && (
            <span>
              <b>{selectedTicker}</b> has a Call Skew of{" "}
              <b>{tickerInfo.call_skew}</b>. 10% OTM <b>calls</b> are{" "}
              <b>{tickerInfo.call_skew}x</b> the price of 10% OTM <b>puts</b>{" "}
              for next monthly expiry.
              <br />
              <br />
            </span>
          )}
          {parseFloat(tickerInfo.put_skew) > 0 && (
            <span>
              <b>{selectedTicker}</b> has a Put Skew of{" "}
              <b>{tickerInfo.put_skew}</b>. 10% OTM <b>puts</b> are{" "}
              <b>{tickerInfo.put_skew}x</b> the price of 10% OTM <b>calls</b>{" "}
              for next monthly expiry.
              <br />
              <br />
            </span>
          )}

          <h6>{tickerInfo.opt.length} Available Option Expirations</h6>
          <div className="row">
            {tickerInfo.opt.length > 0 &&
              tickerInfo.opt.map((e) => {
                return (
                  <div className="col-md-2 col-4" key={e}>
                    <button className="btn btn-block btn-info btn-sm exp-btn">
                      {e}
                    </button>
                  </div>
                );
              })}
          </div>
          <br />
          {loaded && (
            <div className="d-flex justify-content-center align-items-center">
              <Link to={`/ticker/${selectedTicker}`} target="_blank">
                <button className="btn btn-block btn-primary bmax2">
                  View More {selectedTicker} info
                </button>
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
};
