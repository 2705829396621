import React, { useEffect, useState } from "react";
import "./index.css";
import { APIURL } from "../../../config";
import DropdownFilter from "../DropdownFilter";
import { FilterNameValueMap } from "../../../Util";

export const OverviewTab = () => {
  useEffect(() => {
    sessionStorage.setItem("showCalls", "true");
    sessionStorage.setItem("showPuts", "true");
  }, []);

  return (
    <div className="row">
          <div className="col-lg-2 col-md-3 col-sm-4">
              <DropdownFilter
                  name="Option Selection"
                  options={FilterNameValueMap["Option Selection"]}
              />
          </div>
      <div className="col-lg-2 col-md-3 col-sm-4">
        <DropdownFilter
          name="% of Strike"
          options={FilterNameValueMap["% of Strike"]}
        />
      </div>
      <div className="col-lg-2 col-md-3 col-sm-4">
        <DropdownFilter name="% OTM" options={FilterNameValueMap["% OTM"]} />
      </div>
      <div className="col-lg-2 col-md-3 col-sm-4">
        <DropdownFilter name="IV" options={FilterNameValueMap["IV"]} />
      </div>

      <div className="col-lg-2 col-md-3 col-sm-4">
        <DropdownFilter name="Strike" options={FilterNameValueMap["Strike"]} />
      </div>
      <div className="col-lg-2 col-md-3 col-sm-4">
        <DropdownFilter name="Last" options={FilterNameValueMap["Last"]} />
      </div>
    </div>
  );
};
