import Cookies from 'js-cookie';
import { JWTLOGIN, site_type, APIURL, AUTHURL } from "../config";

class Auth{
    constructor(){
        this.authenticated = false
        this.dedicated = false
    }

    //Once auth, then just get this variable on every page
    checkIsAuth(){
        if (this.authenticated || site_type != "pro"){
            return true
        }
        let t = Cookies.get('access_token')
        if (!t){
            return false
        }
        if (this.WPtokenCheck(t)){
            return true
        }

        return false
    }

    async checkIsDedicated(){
        if (site_type != "pro"){
            return false
        }
        if (this.dedicated){
            return "true"
        }
        await this.serverDedicatedCheck()
        return this.dedicated
    }

    //Logs in with email/password, return token if success
    async WPlogin(){
        const config = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "username": document.getElementById('email').value,
                "password": document.getElementById('password').value
            })
        }

        const res = await fetch(`${JWTLOGIN}/wp-json/jwt-auth/v1/token`, config);
        const json = await res.json();
        //console.log(res.status)
        if (res.status !== 200) {
            //console.log("error")
            return false
        }
        return json.token
    }

    async WPtokenCheck(token){
        try{
            const config2 = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            }
            //console.log(`Bearer ${token}`)
            const res2 = await fetch(`${JWTLOGIN}/wp-json/wp/v2/users/me?context=edit`, config2);
            const json2 = await res2.json();
            //console.log(res2.status)
            // if (res2.status !== 200) {
            //     console.log("error")
            //     return
            // } else {
            //     onsole.log(json2)
            // }

            console.log(json2.roles)

            for (let i = 0; i < json2.roles.length; i++) {
                // console.log(json2.roles[i])
                if (json2.roles[i] == "dedicated"){
                    Cookies.set("dedicated", token, { expires: 7})
                }

                if (json2.roles[i] == "pro" || json2.roles[i] == "member" || json2.roles[i] == "dedicated") {
                    //AUTH OK
                    console.log("auth ok")
                    Cookies.set('access_token', token, { expires: 7 })
                    Cookies.set('email', json2.email, { expires: 7 })
                    Cookies.set('tier', json2.roles[i], { expires: 7 })
                    Cookies.set('pm', '21551', { expires: 7 })
                    this.authenticated = true
                    //window.location.href = "/"
                    return true
                }
            }
            return false
        } catch {
            return false
        }
    }

    //Just send to my backend, no need for checking by frontend
    async serverDedicatedCheck(){
        const res = await fetch(`${AUTHURL}/token-check?token=${Cookies.get("access_token")}`);
        const json = await res.json();
        if (res.status == 200){
            if (json.role == "dedicated"){
                this.dedicated = true
                return true
            }
        }
        return false
    }


}

export default new Auth()




























// async getAuth(){
//     const token = Cookies.get('access_token') || 'testtoken'
//     const formData = new URLSearchParams();
//     formData.append('token', token);
//     const config = {
//         method: 'POST',
//         headers: {
//             "Content-Type": "application/x-www-form-urlencoded",
//         },
//         body: formData
//     }
//     let res = await fetch(`${LOGINURL}/auth/check`, config);

//     return res
// }

// async isAuthenticated2() {
//     const res = await this.getAuth()
//     if (res.status == 200) {
//         return true
//     } else {
//         return false
//     }
// }


// async checkAffiliateApproval() {
//     const token = Cookies.get('access_token') || 'testtoken'

//     const formData = new URLSearchParams();
//     formData.append('token', token);
//     const config = {
//         method: 'POST',
//         headers: {
//             "Content-Type": "application/x-www-form-urlencoded",
//         },
//         body: formData
//     }
//     const res = await fetch(`${LOGINURL}/affiliate/checkapproval`, config);
//     const json = await res.json();
//     const data = json.data
//     console.log(json)
//     if (res.status == 200) {

//         Cookies.set('email', data.email, { expires: 14 })
//         Cookies.set('tier', data.tier, { expires: 14 })
//         if (data.tier == "dedicated") {
//             Cookies.set('pm', 9900, { expires: 14 })

//         } else if (data.tier == "pro" || data.tier == "member") {
//             Cookies.set('pm', 2000, { expires: 14 })

//         }

//         return true
//     } else {
//         return false
//     }

//     return false
// }


// async getUserObject() {
//     const res = await this.getAuth()
//     const json = await res.json()

//     if (res.status == 200) {
//         return json
//     } else {
//         return false
//     }
// }

// async getEmail(){
//     const res = await this.getAuth()
//     const json = await res.json()

//     if (res.status == 200) {
//         return json['data']['email']
//     } else {
//         return false
//     }
// }

// async isMember() {
//     if (site_type == "free") {
//         return true
//     }

//     const res = await this.getAuth()
//     const json = await res.json()

//     if (res.status == 200) {
//         if (json['data']['tier'] !== 'free') {
//             return true
//         } else {
//             return false
//         }
//     } else {
//         return false
//     }
// }

// async isDedicated(){
//     const res = await this.getAuth()
//     const json = await res.json()
//     if (res.status == 200) {
//         if (json['data']['tier'] == 'dedicated') {
//             return true
//         } else {
//             return false
//         }
//     } else {
//         return false
//     }
// }