import React, { useState, useEffect } from 'react'
import { CustomTable } from "../../Components/CustomTable";
import Pagination from "../../Components/CustomTable/pagination"
import Template from "../../Components/Template";
import ContentHeader from "../../Components/ContentHeader";
import "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import { APIURL, ROWS_PER_PAGE, site_type } from "../../config";
import { getHeaderMapArbOP, getHeaderMapArbUP, getCSVHeaderCustom,  filterMap } from "../../Util/index";
import moment from 'moment';
import HelmetComponent from '../../Components/Helmet';
import { CSVLink } from "react-csv";
import Tippy from "@tippyjs/react";

import { LoadingIcon } from "../../Components/LoadingIcon"
import UpgradeFooter from '../../Components/Upgrade/UpgradeFooter';

export default function ArbScanner({ price, options }) {
    const [data, setData] = useState([]);
    const [dldata, setDLData] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const [page, setPage] = useState(0)

    const [sortBy, setSortBy] = useState("");
    const [sortOrder, setSortOrder] = useState(true); // True for descending order


    const loadInfo = async () => {

        const res = await fetch(
            `${APIURL}/arbscanner?p=${site_type == "pro" ? "1" : "0"}&page_size=${ROWS_PER_PAGE}&page=${page}&price=${price}&options=${options}`
        );
        if (res.status == 200) {
            const json = await res.json();
            setData(json)
            setDLData(<CSVLink
                headers={price == "overpriced" ? getCSVHeaderCustom('arb_op') : getCSVHeaderCustom('arb_up')}
                data={json.data}
                filename={"download.csv"}
                className="btn"
                target="_blank"
            >
                <Tippy content="Download this page. Change rows per page in settings to download more at once."><i className="fas fa-download"></i></Tippy>
            </CSVLink>)

            console.log(price == "overpriced" ? getCSVHeaderCustom('arb_op') : getCSVHeaderCustom('arb_up'))
            console.log(json.data)
            setLoaded(true)
        } else {
            console.log("API CALL FAIL");
        }
    }

    useEffect(() => {
        loadInfo()
    }, [page, sortBy, sortOrder])

    let title = `Options Arbitrage Scanner | FDscanner`
    let description = `Use our Arbitrage Scanner to find underpriced or overpriced options relative to their their Theo value.`


    return (
        <Template>
            <HelmetComponent title={title} description={description} />
            <ContentHeader text={`Options Arbitrage Scanner | ${price[0].toUpperCase()}${price.slice(1)} ${options[0].toUpperCase()}${options.slice(1)}s`} />
            <p>The Arbitrage Scanner finds options that deviate significantly from their theoretical value (Theo).
            Underpriced options are defined as options with an Ask below Theo, allowing traders to buy below the theoretical value. <br/>Overpriced options have their bids above Theo,
                allowing traders to open short positions above theoretical value, useful for put option sellers.
                </p>

            <h6>Example use case: Run covered calls on stocks with overpriced calls, or sell cash secured puts on overpriced puts.</h6>
            <div className="card">
                <div className="card-body">

                    <div>
                        <div className="text-centered">{!loaded && <LoadingIcon />}</div>

                        <div className="row">
                            <div className="col">{dldata}</div>
                            <div className="col text-center">{data.last_updated && `Last Updated: ${moment(new Date(0).setUTCSeconds(data.last_updated)).toString()}`}</div>
                            <div className="col text-right">{loaded && <Pagination page={data.current_page} setPage={setPage} maxPage={data.pages} />}
                            </div>
                        </div>
                        {loaded && <CustomTable
                            data={data.data}
                            headerMap={price == "overpriced" ? getHeaderMapArbOP() : getHeaderMapArbUP()}
                            currentPage={data.current_page}
                            pageSize={20}
                        // headerClicked={headerClicked}
                        // sortedBy={sortBy}
                        // sortedOrder={sortOrder} 
                        />}
                        
                    </div>
                </div>
            </div>
            <UpgradeFooter page="Arbitrage Scanner" />

        </Template>
    )
}


