import React, { useState, useEffect } from 'react'
import './index.css'
import Toast from "../../../Components/Toast"
import Tippy from "@tippyjs/react";
import auth from "../../../Routes/auth"
import { site_type } from '../../../config';

const saveSuccess = {
    'title': 'Save Success',
    'body': 'Click "bookmarked filters" on the left menu to view and manage your bookmarked filters.',
    'color': 'success'
}

const saveFail = {
    'title': 'Nothing to save!',
    'body': 'No filters were set for saving.',
    'color': 'warning'
}

const runningScan = {
    'title': 'Running Scan!',
    'body': 'Should take just a second. :)',
    'color': 'info'
}



const runningDedicatedScanComplete = {
    'title': 'Completed Dedicated Scan!',
    'body': `Thanks for waiting!`,
    'color': 'success'
}

const clearedFilters = {
    'title': 'Cleared Filters!',
    'body': 'Click Run Filters to get the latest data',
    'color': 'success'
}

const switchFilter = {
    'title': 'Filters Switched & Cleared!',
    'body': 'The default filter view and rows per page can be set in Settings.',
    'color': 'info'
}

//currentView 0 for dropdown, anything else for filter
export const ScannerFilterSummary = ({ GetOptionData, loadingIcon, csvDL, filterView=0, setFilterView, GetDedicatedOptionData }) => {

    const [showToast, setShowToast] = useState(false)
    const [toastInfo, setToastInfo] = useState(saveSuccess)

    const [isDedicated, setIsDedicated] = useState(false)

    useEffect(() => {
      dedicatedInit()
    }, [])
  
    const dedicatedInit = async () => {
      let a = await auth.checkIsDedicated()
      setIsDedicated(a)
    }

    const clickedSave = (t=5000) => {
        setShowToast(true)
        setTimeout(() => {
            setShowToast(false)
        }, t)
    }

    return (
        <>
            {showToast && <Toast title={toastInfo.title} body={toastInfo.body} color={toastInfo.color} />}
            {/* <div id="toastsContainerTopRight" className="toasts-top-right fixed"><div className="toast bg-success fade show" role="alert" aria-live="assertive" aria-atomic="true"><div className="toast-header"><strong className="mr-auto">Toast Title</strong><small>Subtitle</small><button data-dismiss="toast" type="button" className="ml-2 mb-1 close" aria-label="Close"><span aria-hidden="true">×</span></button></div><div className="toast-body">Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</div></div></div> */}
            <div className="card">
                <div className="card-body less-pad">
                    <p>Actions {loadingIcon && <i className="fas fa-spinner fa-spin" />}</p>
                    <button type="button" className="btn btn-block btn-primary btn-sm"
                        onClick={() => {
                            sessionStorage.setItem('scannerpage', 0)
                            setToastInfo(runningScan)
                            clickedSave()
                            GetOptionData()
                        }}>Run Filters</button>
                       <Tippy content="Pulls latest data and run scan. Up to 10 tickers. Requires Dedicated Tier">
                        <span className="btn-block">
                        <button type="button" disabled={!isDedicated} className="btn btn-block btn-primary btn-sm"
                        onClick={() => {
                            sessionStorage.setItem('scannerpage', 0)
                            // setToastInfo(runningDedicatedScan)
                            // clickedSave(8000)
                            GetDedicatedOptionData()
                        }}>Pull Data & Run Filters</button>
                        </span>
                        </Tippy>
                        {csvDL}
                        <button type="button" className="btn btn-block btn-info btn-sm"
                        onClick={() => {
                            setFilterView(filterView == 0 ? 1 : 0)
                            setToastInfo(switchFilter)
                            clickedSave()
                            sessionStorage.setItem('currentFilter', '{}')

                        }}>Switch to {filterView == 0 ? 'Slider' : 'Dropdown'} Filters</button>
                    {/* <button type="button" className="btn btn-block btn-primary btn-sm"
                        onClick={() => csvDownload()}>Download Page as CSV</button> */}
                    {/* <button type="button" className="btn btn-block btn-primary btn-sm "
                        onClick={() => {
                            let savedFilters = JSON.parse(localStorage.getItem('savedFilters') || '{}')
                            console.log(savedFilters)
                            let currentFilter = JSON.parse(sessionStorage.getItem('currentFilter'))
                            if (Object.keys(currentFilter).length > 0) {
                                savedFilters[JSON.stringify(new Date())] = currentFilter
                                localStorage.setItem('savedFilters', JSON.stringify(savedFilters))
                                console.log(savedFilters)
                                setToastInfo(saveSuccess)
                            } else {
                                console.log("no filter set")
                                setToastInfo(saveFail)
                            }
                            clickedSave()
                        }}>Save Filter</button> */}
                </div>
            </div>
        </>

    )
}
