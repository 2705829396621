import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HelmetComponent from "../../Components/Helmet";
import Template from "../../Components/Template";
import { site_type, APIURL } from "../../config";

const Sitemap = () => {
  const title = "FDscanner Sitemap";
  const description = "Sitemap for FDscanner";

  const [allTickers, setAllTickers] = useState([])

  const loadUniverse = async () => {
    const res = await fetch(`${APIURL}/universe`);
    if (res.status == 200) {
      const json = await res.json();
      console.log(json)
      setAllTickers(json.data);
    } else {
      console.log("API CALL FAIL");
    }
  };

  useEffect(() => {
    loadUniverse()
  }, [])

  if (allTickers.length == 0){
    return null
  }

  return (
    <Template>
      <HelmetComponent title={title} description={description} />
      <div className="card-body">
        <ul>
          <li>
            <Link to={`/scanner`}>Option Scanner</Link>
          </li>
          <li>
            <Link to={`/filters/presets`}>Preset Filters</Link>
          </li>
          <li>
            <Link to={`/filters/bookmarked`}>Bookmarked Filters</Link>
          </li>
          <li>
            <Link to={`/filters/livestream`}>Scanner Livestream</Link>
          </li>
          <li>
            <Link to={`/tendies/call`}>Call Tendies Index</Link>
          </li>
          <li>
            <Link to={`/tendies/put`}>Put Tendies Index</Link>
          </li>
          <li>
            <Link to={`/tendies/computation`}>Tendies Computation</Link>
          </li>
          <li>
            <Link to={`/callskew`}>Call Skew Index</Link>
          </li>
          <li>
            <Link to={`/putskew`}>Put Skew Index</Link>
          </li>
          <li>
            <Link to={`/aboutskew`}>Computing Skew</Link>
          </li>
          <li>
            <Link to={`/unusualvolume`}>Unusual Option Activity</Link>
          </li>
          <li>All Stock Tickers</li>
          {/* {JSON.stringify(allTickers)} */}
          {allTickers.map((ticker) => {
            return (
              <ul key={ticker}>
                <li>
                  <Link to={`/ticker/${ticker}`}>
                    {ticker} Overview & Research
                  </Link>
                </li>
                <li>
                  <Link to={`/ticker/${ticker}/options`}>
                    {ticker} Put/Calls Option Chain
                  </Link>
                </li>
                <li>
                  <Link to={`/ticker/${ticker}/strikechain`}>
                    {ticker} Strike Chain
                  </Link>
                </li>
                <li>
                  <Link to={`/ticker/${ticker}/tendies`}>
                    {ticker} Tendies Index
                  </Link>
                </li>
                <li>
                  <Link to={`/ticker/${ticker}/skew`}>
                    {ticker} Option Skew & Computation
                  </Link>
                </li>
                <li>
                  <Link to={`/ticker/${ticker}/ntr`}>
                    {ticker} Price Target & NTR Calculation
                  </Link>
                </li>
              </ul>
            );
          })}
        </ul>
      </div>
    </Template>
  );
};

export default Sitemap;
