import React from "react";
import { Sparklines, SparklinesLine } from "react-sparklines";

export const SparklineChart = ({color="green", data=[]}) => {
  return (
    <Sparklines data={data}>
      <SparklinesLine color={color} />
    </Sparklines>
  );
};
