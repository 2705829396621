import React, { Component, useEffect, useState } from "react";
import { APIURL, JSONURL } from "../../config";
import "./index.css"
import { Link } from "react-router-dom";
// import auth from '../../routes/auth';
// import HelmetComponent from "../../components/Helmet"

export const TendiesTab = ({ ticker, name }) => {

    const [loaded, setLoaded] = useState(false)
    const [showCalls, setShowCalls] = useState(true)
    const [data, setData] = useState([])
    const [updateTime, setUpdateTime] = useState('')

    const [price, setPrice] = useState('')
    const [priceTarget, setPriceTarget] = useState('')

    useEffect(() => {
        loadData()
    }, [ticker, showCalls])

    const loadData = () => {
        fetch(`${APIURL}/ticker-tendies?ticker=${ticker}&type=${showCalls ? 'call' : 'put'}`)
            .then(data => {
                if (data.status === 200) {
                    data.json().then(data => {
                        setData(data.data)
                        setPrice(data.price)
                        setPriceTarget(data.priceTarget)
                        setUpdateTime(data.time * 1000)
                        console.log(data)
                        setLoaded(true)
                    });
                } else {
                    console.log("not 200")
                }
            })
            .catch(e => {
                console.log("not err")
            });
    }

    if (!loaded) {
        return null
    }

    // if (data.length == 0) {
    //     return <>
    //         {headerPart}<br />
    //         No {showCalls ? 'Call' : 'Put'} Tendies for {ticker}. 
    //         {showCalls && <Link to={`${APIURL}/stockdata/${ticker}/put_tendies`}>
    //             <button className="btn bg-light blue">Try viewing Put Tendies</button></Link>}
    //     </>
    // }


    const headerPart = <div className="row">
        <div className="col-sm-4">
            <div className="text-left">
                <button className={`btn ${showCalls ? 'bg-primary' : 'bg-secondary'}`} onClick={() => {
                    //setLoaded(false)
                    setShowCalls(true)
                }}>Calls</button>&nbsp;&nbsp;
                    <button className={`btn ${!showCalls ? 'bg-primary' : 'bg-secondary'}`} onClick={() => {
                    //setLoaded(false)
                    setShowCalls(false)
                }}>Put</button>
            </div>
        </div>
        <div className="col-sm-4"><div className="text-center"><b>NTR Price Target: ${priceTarget}</b></div></div>
        <div className="col-sm-4 text-right"><b>{ticker} {showCalls ? 'Call' : 'Put'} Tendies Index</b></div>
    </div>


    let title = `${ticker} Tendies Index | FDscanner`
    let description = `Find the highest returns multibagger weekly options for ${ticker}, if it expires at our Price Target.`

    return <>
        {/* <HelmetComponent title={title} description={description} /> */}
        {headerPart}
        <br />

        <div className="table-responsive p-0">
        <table className="table table-head-fixed text-nowrap table-bordered table-striped dataTable dtr-inline">            <thead>
                <tr>
                    {(Object.keys(headerMap)).map(e => <th key={e}>{headerMap[e]}</th>)}
                </tr>
            </thead>
            <tbody>
                {data.length > 0 &&
                    Object.keys(data).map((e, i) => {
                        return (
                            <tr key={i}>
                                {(Object.keys(headerMap)).map((f, g) => {
                                    if (f == 'ltd') return <td className="text-left" key={`${g}${data[e][f]}`}>
                                        {new Date(data[e][f]).toString().split(" ").slice(1, 5).join(" ")}</td>
                                    if (f == 't') return <td key={`${g}${data[e][f]}`}>{data[e][f] == "c" ? 'Call' : 'Put'}</td>

                                    return <td key={`${g}${data[e][f]}`}>{prependMap[f]}{data[e][f]}{appendMap[f]}</td>
                                })}
                            </tr>)
                    })
                }

            </tbody>
        </table>
        </div>
        <div className="row">
            <div className="col-md-4">
                <b>Last Updated: {new Date(updateTime).toString().split(" ").slice(1, 5).join(" ")}</b>
            </div>
            <div className="col-md-4 text-center">
                <b>Final Update on Fridays, 90mins after Open</b>
            </div>
            <div className="col-md-4 text-right">
                <b>{ticker} price at last update: ${price}</b>
            </div>
        </div>


    </>
}

const headerMap = {
    'ltd': 'Last Trade Time',
    's': 'Strike',
    't': 'Type',
    'tendies': 'Tendies Index',
    'op': 'Last Price',
    'b': 'Bid',
    'a': 'Ask',
    'v': 'Volume',
    'oi': 'Open Interest',
    'iv': 'Implied Volatility',
    'pos': 'pctOptionStrike',
    'po': '% OTM',

}


const prependMap = {
    's': '$',
    'op': '$',
    'b': '$',
    'a': '$',

}

const appendMap = {
    'iv': '%',
    'pos': '%',
    'po': '%'
}