import React, { Component } from "react";
import { Helmet } from "react-helmet";

const HelmetComponent = ({ title, description }) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content={description} />
        </Helmet>
    )

}

export default HelmetComponent;