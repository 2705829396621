import React, { useEffect } from 'react'
import { filterMap } from "../../Util"

export default function DropdownFilter({ name, options }) {

    const HandleFilterSelection = (name, value) => {
        let currentFilter = JSON.parse(sessionStorage.getItem('currentFilter'))
        const apiNameCode = filterMap[name]
        currentFilter[apiNameCode] = value
        sessionStorage.setItem('currentFilter', JSON.stringify(currentFilter))
    }

    useEffect(() => {
        let initFilters = JSON.parse(sessionStorage.getItem('currentFilter'))
        if (filterMap[name] in initFilters){
            
            let mySelect = document.getElementById(filterMap[name]);
            for (var i, j = 0; i = mySelect.options[j]; j++) {
                if (i.value == initFilters[filterMap[name]]) {
                    mySelect.selectedIndex = j;
                    break;
                }
            }
        }

    }, [])

    return (
        <div className="form-group">
            <label>{name}</label>
            <select
                className="form-control select2bs4 select2-hidden-accessible"
                style={{ width: "100%" }}
                onChange={(e) => HandleFilterSelection(name, e.target.value)}
                id={filterMap[name]}
            >
                {Object.keys(options).map(e => {
                    return (
                        <option value={options[e]} key={e}>
                            {e}
                        </option>
                    )
                })}
            </select>
        </div>
    )
}

