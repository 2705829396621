import React from "react";
import { filterMapReverse, FilterNameValueMap } from "../../Util";
import { site_type } from "../../config";

export default function displayContent({ content }) {
  console.log(content)
  return (
    <div>
      {Object.keys(content).map((e) => {
        if (!filterMapReverse[e]) {
          return null
        }
        // console.log(e)
        let allOptions = FilterNameValueMap[filterMapReverse[e]]
        let minval = content[e].split(',')[0]
        let maxval = content[e].split(',')[1]
        // console.log(allOptions)
        let lol
        let allOptionKeys = Object.keys(allOptions)
        for (let i = 0; i < allOptionKeys.length; i++) {
          let key = allOptionKeys[i]
          let two_val_arr = allOptions[key]
          // console.log(two_val_arr)

          if (minval == two_val_arr[0] && maxval == two_val_arr[1]) {
            lol = allOptionKeys[i]
          }

        }

        if (!lol) {
          if (maxval == 9999) {
            lol = `> ${colDataType(filterMapReverse[e], minval)}`
          }
          if (minval == 0 || minval == -9999) {
            lol = `< ${colDataType(filterMapReverse[e], maxval)}`
          }

          if (maxval != 9999 && !(minval == 0 || minval == -9999)) {
            lol = `${colDataType(filterMapReverse[e], minval)} - ${colDataType(filterMapReverse[e], maxval)}`
          }
        }

        return (
          <span key={e}>
            {filterMapReverse[e]} - {lol}<br />
          </span>
        );
      })}
    </div>
  );
}



const colDataType = (key, value) => {
  //Add extra cases as necessary.
  try {
    switch (key) {

      case "Strike":
      case "Bid":
      case "Ask":
      case "Last":
      case "SMA200":
      case "SMA50":
      case "Price":
      case "Close to Open":
      case "5 Days Chg":
      case "Price Target":
      case "1x NTR":
      case "0.5x NTR":
      case "0.25x NTR":
      case "110% SP Call Price":
      case "90% SP Put Price":
      case "110% SP":
      case "90% SP":
      case "Share Price (SP)":
      case "Share Price":
        return `$${value}`;

      case "Theo":
      case "Underpriced By":
      case "Overpriced By":
        return `$${Math.round(1000 * value, 3) / 1000}`;

      case "1X NTR %":
      case "0.5X NTR %":
      case "0.25X NTR %":
      case "% of Strike":
      case "% OTM":
      case "SMA200 %":
      case "SMA50 %":
      case "Short Interest":
      case "Close to Open %":
      case "5 Days Chg %":
      case "Short Interest":
      case "IV":
      case "Theta Annual %":
      case "Theta Daily %":
      case "52 Week Change":
        return `${value}%`;

      case "Underpriced %":
      case "Overpriced %":
        return `${Math.round(1000 * value, 1) / 10}%`;

      case "Market Cap":
        let l = value.toString().length
        if (l > 12) {
          return `$${Math.round(value / 10 ** 10) / 100}T`
        }
        if (l > 9) {
          return `$${Math.round(value / 10 ** 7) / 100}B`
        }
        if (l > 6) {
          return `$${Math.round(value / 10 ** 4) / 100}M`
        }
        return `$${value}`

      case "Type":
        if (value == "C" || value == "c") {
          return "Call"
        }
        return "Put"

      default:
        return value;
    }
  }
  catch {
    return ''
  }
};
