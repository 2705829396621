import React, { useEffect, useState } from 'react'
import { TradingViewChart } from "./TradingViewChart"
import Template from '../../Components/Template'
import TickerHeader from '../../Components/ContentHeader/TickerHeader'
import { APIURL, JSONURL } from "../../config";

import { DescriptionTab } from "./DescriptionTab"
import { OptionTab } from "./OptionTab"
import { Trends } from "./Trends"
import { TendiesTab } from "./TendiesTab"
import { NTRtab } from "./NTRtab"
import { OptionCompare } from "./OptionCompare_too_complexlol"

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";
import { StrikeChainTab } from './StrikeChainTab';
import { LoadingIcon } from '../../Components/LoadingIcon';
import HelmetComponent from '../../Components/Helmet';
import { SkewComputationTab } from './SkewComputationTab';
import { MiiAlertTab } from './MiiAlerts';

export default function Ticker({page=""}) {
    let { ticker } = useParams();
    ticker = ticker.toUpperCase()
    const [info, setInfo] = useState({})
    const [loaded, setLoaded] = useState(false)

    const loadInfo = async () => {
        const res = await fetch(
            `${JSONURL}/stockdata/${ticker}/info`
        );
        if (res.status == 200) {
            const json = await res.json();
            setInfo(json)
            setLoaded(true)
        } else {
            console.log("API CALL FAIL");
        }
    }

    useEffect(() => {
        setInfo({})
        setLoaded(false)
        loadInfo()
    }, [ticker])


    let title = getPageSEO(page, info.name, ticker)['title']
    let description = getPageSEO(page, info.name, ticker)['description']
                    
    return (
        <Template>
            <HelmetComponent title={title} description={description}/>
            <TickerHeader ticker={ticker} />
            <div className="row">
                <div className="col-sm-6"><b>{info.name}&nbsp;({ticker})</b></div>
                <div className="col-sm-6 text-right"><b>Sector: {info.sec}</b></div>
                
            </div>
            <TradingViewChart ticker={ticker} />
            <div className="card card-primary card-outline card-outline-tabs">
                <div className="card-header p-0 border-bottom-0">
                    <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home" aria-selected="true">Description</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill" href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">Option Chain</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="custom-tabs-four-demandskew-tab" data-toggle="pill" href="#custom-tabs-four-demandskew" role="tab" aria-controls="custom-tabs-four-demandskew" aria-selected="false">Option Trends</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="custom-tabs-four-miialgo-tab" data-toggle="pill" href="#custom-tabs-four-miialgo" role="tab" aria-controls="custom-tabs-four-miialgo" aria-selected="false">MiiAlgo Alerts</a>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link" id="custom-tabs-four-compare-tab" data-toggle="pill" href="#custom-tabs-four-compare" role="tab" aria-controls="custom-tabs-four-compare" aria-selected="false">Option Compare</a>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link" id="custom-tabs-four-messages-tab" data-toggle="pill" href="#custom-tabs-four-messages" role="tab" aria-controls="custom-tabs-four-messages" aria-selected="false">Strike Chain</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="custom-tabs-four-settings-tab" data-toggle="pill" href="#custom-tabs-four-settings" role="tab" aria-controls="custom-tabs-four-settings" aria-selected="false">Tendies Index</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="custom-tabs-four-skew-tab" data-toggle="pill" href="#custom-tabs-four-skew" role="tab" aria-controls="custom-tabs-four-skew" aria-selected="false">Skew Calculation</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="custom-tabs-four-ntr-tab" data-toggle="pill" href="#custom-tabs-four-ntr" role="tab" aria-controls="custom-tabs-four-ntr" aria-selected="false">NTR Calculation</a>
                        </li>
                        
                    </ul>
                </div>
                <div className="card-body">
                    <div className="tab-content" id="custom-tabs-four-tabContent">
                        <div className="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                            {loaded && <DescriptionTab ticker={ticker} data={info} />}
                            {!loaded && <LoadingIcon/>}
                        </div>
                        <div className="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel" aria-labelledby="custom-tabs-four-profile-tab">
                            {loaded && <OptionTab ticker={ticker} expiry={info.opt} name={info.name}/>}
                        </div>
                        <div className="tab-pane fade" id="custom-tabs-four-demandskew" role="tabpanel" aria-labelledby="custom-tabs-four-profile-tab">
                            {loaded && <Trends ticker={ticker} expiry={info.opt}/>}
                        </div>
                        <div className="tab-pane fade" id="custom-tabs-four-miialgo" role="tabpanel" aria-labelledby="custom-tabs-four-miialgo-tab">
                            {loaded && <MiiAlertTab ticker={ticker}/>}
                        </div>
                        <div className="tab-pane fade" id="custom-tabs-four-messages" role="tabpanel" aria-labelledby="custom-tabs-four-messages-tab">
                            <StrikeChainTab ticker={ticker} name={info.name} /></div>
                        <div className="tab-pane fade" id="custom-tabs-four-settings" role="tabpanel" aria-labelledby="custom-tabs-four-settings-tab">
                            <TendiesTab ticker={ticker} name={info.name}/>
                        </div>
                        <div className="tab-pane fade" id="custom-tabs-four-skew" role="tabpanel" aria-labelledby="custom-tabs-four-skew">
                            <SkewComputationTab ticker={ticker} name={info.name}/>
                        </div>
                        <div className="tab-pane fade" id="custom-tabs-four-ntr" role="tabpanel" aria-labelledby="custom-tabs-four-ntr">
                            <NTRtab ticker={ticker} name={info.name}/>
                        </div>
                    </div>
                </div>
                {/* {loaded && <MiiAlertTab ticker={ticker}/>} */}

                {/* /.card */}
            </div>

        </Template>
    )
}


const getPageSEO = (page, name, ticker) => {
    switch(page){
        case "":
            return {
                title: `${name ? name : ''} ${ticker} Stock Price, Fundamentals & Option Chain | FDscanner`,
                description: `Find up to date Options information for ${name} (${ticker}), including Stock Price, Option Chain, PE ratio, Short Interest
                50 and 200 day moving averages, Institutional Holdings, Profit Margins and more`
            }

        case "options":
            return {
                title: `${name} ${ticker} Option Chain | Calls/Puts | FDscanner`,
                description: `Get the latest call and put option data for ${name} (${ticker}) for trading and research`
            }

        case "strikechain":
            return {
                title: `${name} ${ticker} All Option Expiries | FDscanner`,
                description: `View all option expiries for ${ticker} in a grid view`
            };
        
        case "tendies":
            return {
                title: `${ticker} Tendies Index | FDscanner`,
                description: `Find the highest returns multibagger weekly options for ${ticker}, if it expires at our Price Target.`
            };

        case "skew":
            return {
                title: `${name ? name : ''} ${ticker} Option Skew`,
                description: `Step by step computation for ${ticker} option skew.`
            };

        case "ntr":
            return {
                title: `${name} ${ticker} Price Target with Custom Indicators | FDscanner`,
                description: `Using a modified 10 days Average True Range value, we set upper and lower price targets for ${ticker}.`
            };
    }
}