import React, { useState, useEffect } from "react";
import { APIURL, MIIALGOURL } from "../../../config";
import SeriesMarker from "../../../Components/Chart/SeriesMarker";
import FixedHeaderTable from "../../../Components/FixedHeaderTable";
import LineChartTime from "../../../Components/Chart/lineChartTime";

const ratio_tabs = {
  'callput_oi_ratio': 'Call/Put OI',
  'callput_vol_ratio': 'Call/Put Vol',
}

const call_tabs = {
  'call_volume': 'Call Volume',
  'call_oi': 'Call OI',
  'call_voloi': 'Call Vol/OI',
}

const put_tabs = {
  'put_volume': 'Put Volume',
  'put_oi': 'Put OI',
  'put_voloi': 'Put Vol/OI'
}

const ylabelMap = {
  'callput_oi_ratio': 'Call/Put OI',
  'callput_vol_ratio': 'Call/Put Vol',
  'call_volume': 'Call Volume',
  'call_oi': 'Call OI',
  'call_voloi': 'Call Vol/OI',
  'put_volume': 'Put Volume',
  'put_oi': 'Put OI',
  'put_voloi': 'Put Vol/OI'
}

export const Trends = ({ ticker, expiry }) => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [shownTab, setShownTab] = useState('call_volume')
  const [chartData, setChartData] = useState([])

  const GetTickerData = async () => {
    const res = await fetch(`${APIURL}/demand-imbalance-ticker?ticker=${ticker}`);
    if (res.status == 200) {
      const json = await res.json();
      setData(json.data)
      setLoaded(true);
    }
  };

  useEffect(() => {
    GetTickerData();
  }, []);

  useEffect(() => {
    let output = []
    for (let i = 0; i < Object.keys(data).length; i++) {
      output.push({
        x: Object.keys(data)[i],
        y: data[Object.keys(data)[i]][shownTab]
      })
    }
    // console.log('trend', output)
    setChartData(output)
  }, [shownTab, data])

  const headerPart = <div className="row">
    <div className="col-sm-4 strikechain-header-pad text-center">
      <b>Call/Put Ratios:</b>&nbsp;
      <div class="btn-group" role="group">
        {Object.keys(ratio_tabs).map(e =>
          <button type="button"
            className={`btn btn-${shownTab == e ? 'primary' : 'secondary'} btn-sm w100`}
            onClick={() => setShownTab(e)}>
            {ratio_tabs[e]}
          </button>)}
      </div>
    </div>
    <div className="col-sm-4 text-center strikechain-header-pad">
      <b>Call Trends:</b>&nbsp;
      <div class="btn-group" role="group">
        {Object.keys(call_tabs).map(e =>
          <button type="button"
            className={`btn btn-${shownTab == e ? 'primary' : 'secondary'} btn-sm w100`}
            onClick={() => setShownTab(e)}>
            {call_tabs[e]}
          </button>)}
      </div>
    </div>
    <div className="col-sm-4 text-center strikechain-header-pad">
      <b>Put Trends:</b>&nbsp;
      <div class="btn-group" role="group">
        {Object.keys(put_tabs).map(e =>
          <button type="button"
            className={`btn btn-${shownTab == e ? 'primary' : 'secondary'} btn-sm w100`}
            onClick={() => setShownTab(e)}>
            {put_tabs[e]}
          </button>)}
      </div>
    </div>
  </div>

  if (!loaded) {
    return <>loading</>
  }

  return (
    <>
      <h5 className="text-center">
        {ticker} Option Trends
      </h5>
      <p className="text-center">This section visualizes the volume, open interest, vol/oi and call/put ratios across all expiries for the ticker. </p>
      {headerPart}
      {/* {JSON.stringify(data)} */}
      <LineChartTime data={chartData} key={Math.random()} xlabel="Expiry" ylabel={ylabelMap[shownTab]} title={ylabelMap[shownTab]}/>
    </>
  );
};



const headerMap = {
  time: "Time",
  Ticker: "Ticker",
  Dir: "Dir",
  Price: "Price",
  "5m_pct": "5m Change",
  "10m_pct": "10m Change",
  "15m_pct": "15m Change",
  Roc: "Roc",
};
