import React from 'react'
import Template from "../../Components/Template";
import ContentHeader from "../../Components/ContentHeader";
import { Link } from 'react-router-dom';


export default function TendiesAbout() {
    
    return (
        <>
            <h4>Overview</h4>
            <p>Which weekly option has the highest multibagger potential? <br /><br />The Tendies Index finds all weekly OTM options with good liquidity and assumes the stock will hit a reasonable price target based on the stock's past 10 days price movement, using a modified Average True Range, called Not True Range.
            <br />If that price target is hit, dividing the option's intrinsic value/current option price gives the Tendies Index.
            <br /><br />
                <Link to="/tendies/computation">Click here</Link> to read the full background and formula to compute Tendies and NTR. Or use the searchbar to search for a ticker to see NTR computation for that stock.</p>
            <h4>The Tendies Index is not...</h4>
            <p>
                This is NOT an indicator or suggestion to buy options with high tendies index. Financial theory states that the expected value of an option is equal to the current premium.
                <br /> With commissions and spreads, if all options with high tendies index is bought, unless there's an significant increase in IV, the expected gain should be slightly negative, like buying lottery tickets.
            <br /><br />
            For option buyers, the tendies index should be combined with your own directional analysis on the stock. Comparing the index between all options for the stock helps to give an idea of risk/reward ratio.
            <br /><br />
            For option premium sellers, closing positions with high tendies index helps to avoid gamma risk. Options with low tendies index could be a good starting point to research short puts play at the beginning of the week.
            </p>
            <h4>Update Frequency</h4>
            <p>It updates once every hour during trading hours, and performs a final update for the week on Friday, 90 minutes after open.</p>

            <h4>Option Universe</h4>
            <p>FDscanner: S&P500 stock options. FDscanner Pro: All US stock options
<br />
Options must also pass a strict liquidity filter, be OTM and with Strike below Price Target for calls, above Price Target for puts.
</p>
            <h4>How it's calculated</h4>
            <p><Link to="/tendies/computation">Read the Tendies computation page here</Link>.</p>
        </>
    )
}
