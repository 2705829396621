import React from 'react'
import { Link } from "react-router-dom";
import HelmetComponent from '../../Components/Helmet';
import Template from "../../Components/Template";
import ContentHeader from "../../Components/ContentHeader";

export default function Contact() {
    const title = "FDscanner Contact"
    return (
        <Template>
            <HelmetComponent title={title}/>
            <ContentHeader text={`Contact`} />

            <div className="card-body">
                <p>Email: kayin@fdscanner.com</p>
                <p>Discord: @arrow222#3861</p>
            </div>
        </Template>
    )
}

