import React from "react";
import { Link } from "react-router-dom";
import HelmetComponent from "../../Components/Helmet";
import Template from "../../Components/Template";
import ContentHeader from "../../Components/ContentHeader";

export default function FAQ() {
  const title = "FDscanner FAQ";
  return (
    <Template>
      <HelmetComponent title={title} />
      <ContentHeader text={`FAQ`} />

      <section className="content">
        <div className="row">
          <div className="col-12" id="accordion">
            <div className="card card-primary card-outline">
              <a
                className="d-block w-100"
                data-toggle="collapse"
                href="#collapseOne"
                aria-expanded="true"
              >
                <div className="card-header">
                  <h4 className="card-title w-100">1. What is FDscanner?</h4>
                </div>
              </a>
              <div
                id="collapseOne"
                className="collapse show"
                data-parent="#accordion"
                style={{}}
              >
                <div className="card-body">
                  FDscanner is an options scanner. Like a stock scanner, you can
                  selected from a wide range of parameters. We will filter the
                  millions of options to your exact specifications
                </div>
              </div>
            </div>
            <div className="card card-primary card-outline">
              <a
                className="d-block w-100 collapsed"
                data-toggle="collapse"
                href="#collapseTwo"
                aria-expanded="false"
              >
                <div className="card-header">
                  <h4 className="card-title w-100">
                    2. How do I find profitable options?
                  </h4>
                </div>
              </a>
              <div
                id="collapseTwo"
                className="collapse"
                data-parent="#accordion"
                style={{}}
              >
                <div className="card-body">
                  To fully leverage FDscanner, it's best if you already have a
                  profitable working strategy. Then use our tools to find 10x
                  similar plays within a minute. <br />
                  <br />
                  If you do not have that, it would be a similar question to
                  asking how to set up a stock scanner to find companies that
                  will go up.
                  <br />
                  <br />
                  I'm sure no reasonable stock scanner setup will list out the EV
                  companies that exploded this year. There's no easy answer out
                  there, other than to first find your edge, then use our tool
                  to find 10x the plays.
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div className="card card-primary card-outline">
              <a
                className="d-block w-100"
                data-toggle="collapse"
                href="#collapseThree"
              >
                <div className="card-header">
                  <h4 className="card-title w-100">
                    3. How do I benefit most from FDscanner?
                  </h4>
                </div>
              </a>
              <div
                id="collapseThree"
                className="collapse"
                data-parent="#accordion"
              >
                <div className="card-body">
                  I mainly built FDscanner for traders who already have a clear
                  idea what they are seeking. If you're new to options and
                  trading, I suggest reading up the maths behind the skew and
                  tendies index.
                  <br />
                  If you agree with the math, get a few opinions from other
                  traders, find out their thoughts. Then from the top 50 of the
                  list find potential trades.
                  <br />
                  Keep learning and asking. Reddit's /r/options and /r/thetagang
                  is a decent place to start.
                </div>
              </div>
            </div>
            <div className="card card-primary card-outline">
              <a
                className="d-block w-100"
                data-toggle="collapse"
                href="#collapseFour"
              >
                <div className="card-header">
                  <h4 className="card-title w-100">
                    4. What's do I get by subscribing to Pro?
                  </h4>
                </div>
              </a>
              <div
                id="collapseFour"
                className="collapse"
                data-parent="#accordion"
              >
                <div className="card-body">
                  Pro version unlocks all 1961 US stock with options for all
                  features (Scanner, Skew/Tendies index, Unusual Volume), free
                  version is limited to S&P500 stocks. For both versions you can
                  search all 1961 tickers on our site.
                  <br />
                  <br />
                  Pro users also get access to our discord.
                  <br />
                  <br />
                  For advanced traders requiring the fastest data updates, the dedicated tier allows pulling the latest data for the options scanner, as well as multi expiry scans and ability
                  to refresh a ticker page at any time.
                </div>
              </div>
            </div>
            <div className="card card-primary card-outline">
              <a
                className="d-block w-100"
                data-toggle="collapse"
                href="#collapseFive"
              >
                <div className="card-header">
                  <h4 className="card-title w-100">5. What's MiiAlgo?</h4>
                </div>
              </a>
              <div
                id="collapseFive"
                className="collapse"
                data-parent="#accordion"
              >
                <div className="card-body">
                  It's a real-time scanner I made that has 2 algorithms.
                  <ul>
                  <li>1) Market Movers - It finds stocks exhibiting unusual and extreme directional momentum and alerts them to you in a dashboard</li>
                  <li>2) Consistent Movers - It finds stocks significant momentum, yet with very low volatility (imagine a string of 20 small green bars all which are the same size),
                       and alerts them to you in a dashboard</li>
                  </ul>
                  It's built for intraday momentum stock traders. 
                </div>
              </div>
            </div>

          
          </div>
        </div>
  
      </section>
    </Template>
  );
}
