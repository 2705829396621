import React, { Component, useEffect, useState } from "react";
import { APIURL, JSONURL } from "../../config";
import "./index.css"
// import { Link } from "react-router-dom";
// import HelmetComponent from "../../components/Helmet"

export const NTRtab = ({ ticker, name }) => {

    const [price, setPrice] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [showCalls, setShowCalls] = useState(true)
    const [data, setData] = useState([])
    const [updateTime, setUpdateTime] = useState('')

    useEffect(() => {
        loadData()
    }, [ticker, showCalls])

    const loadData = () => {
        fetch(`${APIURL}/ntr-price-history?ticker=${ticker}`)
            .then(data => {
                if (data.status === 200) {
                    data.json().then(data => {
                        setData(data.data)
                        setUpdateTime(data.time)
                        setPrice(data.price)
                        console.log(data)
                        setLoaded(true)
                    });
                } else {
                    console.log("not 200")
                }
            })
            .catch(e => {
                console.log("not err")
            });

        // fetch(`${JSONURL}/stockdata/${ticker}/call_tendies`)
        //     .then(data => {
        //         if (data.status === 200) {
        //             data.json().then(data => {
        //                 setUpdateTime(data.time * 1000)
        //                 setPrice(data.price)
        //             });
        //         } else {
        //             console.log("not 200")
        //         }
        //     })
        //     .catch(e => {
        //         console.log("not err")
        //     });
    }

    if (!loaded) {
        return null
    }


    const headerPart = <div className="row">
        <div className="col-sm-4">
            <div className="text-left">
              
            </div>
        </div>
        <div className="col-sm-4"><div className="text-center"><b>Computation Steps for NTR used in Tendies Index</b></div></div>
        <div className="col-sm-4 text-right"><b></b></div>
    </div>

    let selectedTR = []
    for (let i = 1; i < data.length; i++){
        selectedTR.push(data[i]['TrueRange'])
    }
    selectedTR = selectedTR.sort((a,b) => {return a - b})
    let highest = selectedTR[selectedTR.length - 1]
    console.log(highest)
    console.log(selectedTR)
    selectedTR = selectedTR.slice(4,9)
    console.log(selectedTR)

    let sum = 0
    selectedTR.forEach(number => {
        sum += number;
    })
    console.log(sum)

    let average = sum/5
    console.log(average)

    let title = `${name} ${ticker} Price Target with Custom Indicators | FDscanner`
    let description = `Using a modified 10 days Average True Range value, we set upper and lower price targets for ${ticker}.`

    return <>
        {/* <HelmetComponent title={title} description={description} /> */}

        {headerPart}
        <br />
        <b>1) OHLC Data</b>
        <p>First, retrieve past 11 days of OHLC data, excluding the current trading day.</p>

        <b>2) Compute Daily True Range</b>
        <p>Using the same formula as Average True Range, we first compute daily True Range. It's the higher of</p>
        <ul>
            <li>
                High - Low
            </li>
            <li>
                Absolute value of (High - Previous Close)
            </li>
            <li>
                Absolute value of (Low - Previous Close)
            </li>
        </ul>
        <b>3) Remove highest and 4 lowest Daily True Range</b>
        <p>By removing the highest value, we exclude the extreme scenario. The 4 lowest value are removed to give us an optimstic view of daily price movement. 
            The 5 selected True Range values are highlighted in the table below.
        </p>
        
        <div className="table-responsive p-0">
        <table className="table table-head-fixed text-nowrap table-bordered table-striped dataTable dtr-inline">            <thead>
                <tr>
                    {(Object.keys(headerMap)).map(e => <th key={e}>{headerMap[e]}</th>)}
                </tr>
            </thead>
            <tbody>
                {
                    Object.keys(data).map((e, i) => {
                        return (
                            <tr key={i}>
                                {(Object.keys(headerMap)).map((f, g) => {
                                    if (f == 'date') return <td className="text-left" key={`${g}${data[e][f]}`}>
                                        {new Date(data[e][f]).toString().split(" ").slice(1, 4).join(" ")}</td>
                                    if (f == 'TrueRange') return <td key={`${g}${data[e][f]}`} className={selectedTR.includes(data[e][f]) ? 'hlcell' : ''}>
                                        {data[e][f] && prependMap[f]}{data[e][f]}</td>
                                    return <td key={`${g}${data[e][f]}`}>{data[e][f] && prependMap[f]}{data[e][f]}{appendMap[f]}</td>
                            })}
                            </tr>)//
                    })
                }
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>NTR:</td>
                    <td>${average.toFixed(2)}</td>
                </tr>

            </tbody>
        </table>
        </div>
        <b>4) Average the 5 remaining Daily True Range</b>
        <p>The output will be Not True Range, or NTR for short.</p>
        <b>5) Get the Call and Put price target</b>
        <p>Simply add NTR to current price for the call price target, or subtract NTR from current price for put price target.</p>
        <br/>
        <p className="text-center">
            {ticker} price at last update: ${price}<br/>
            High NTR Price Target: ${price} + ${average.toFixed(2)} = <b>${(price + average).toFixed(2)}</b><br/>
            Low NTR Price Target: ${price} - ${average.toFixed(2)} = <b>${(price - average).toFixed(2)}</b>
            </p>
        <br />
        <b>6) Done!</b>
        <p>With the price targets, we take the subset of options between current price and NTR price target. Then compute the option intrinsic value at Friday close,
        assuming it closed at <b>Price Target</b>. This value is divided by the <b>option bid/ask midpoint</b> to get the <b>Tendies Index</b>.</p>
        <br/>
        <div className="row">
                <div className="col-md-4">
                <b>Last Updated: {new Date(updateTime).toString().split(" ").slice(1, 5).join(" ")}</b>
                </div>
            <div className="col-md-4 text-center">
                <b>Final Update on Fridays, 90mins after Open</b>
            </div>
            <div className="col-md-4 text-right">
                <b>{ticker} price at last update: ${price}</b>
            </div>
        </div>
        
    </>
}

const headerMap = {
    'date': 'Date',
    'High': 'High',
    'Low': 'Low',
    'Close': 'Close',
    'prevClose': 'prevClose (PC)',
    'H-L': 'High-Low',
    'H-PC': 'High-PC',
    'L-PC': 'Low-PC',
    'TrueRange': 'TrueRange',

}


const prependMap = {
    'Open': '$',
    'High': '$',
    'Low': '$',
    'Close': '$',
    'prevClose': '$',
    'H-L': '$',
    'H-PC': '$',
    'L-PC': '$',
    'TrueRange': '$',
}

const appendMap = {

}