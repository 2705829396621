import React, { useEffect, useState } from "react";
import "./index.css";
import { APIURL } from "../../../config";
import DropdownFilter from "../DropdownFilter";
import { FilterNameValueMap } from "../../../Util";
import { RangeSlider } from "../../../Components/Slider";

export const OverviewTabSlider = () => {
  useEffect(() => {
    sessionStorage.setItem("showCalls", "true");
    sessionStorage.setItem("showPuts", "true");
  }, []);

  return (
    <div className="row">
      <div className="col-lg-6 col-md-6 col-sm-12">
        <DropdownFilter
          name="Option Selection"
          options={FilterNameValueMap["Option Selection"]}
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12"/>

      <div className="col-lg-6 col-md-6 col-sm-12">
        <RangeSlider name="% OTM" unit="%" />

        {/* <DropdownFilter name="% OTM" options={FilterNameValueMap["% OTM"]} /> */}
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <RangeSlider name="% of Strike" unit="%" multiplier={0.5} />
        {/* <DropdownFilter
          name="% of Strike"
          options={FilterNameValueMap["% of Strike"]}
        /> */}
      </div>

      <div className="col-lg-6 col-md-6 col-sm-12">
        <RangeSlider name="IV" unit="%" multiplier={5} />
        {/* <DropdownFilter name="IV" options={FilterNameValueMap["IV"]} /> */}
      </div>

      <div className="col-lg-6 col-md-6 col-sm-12">
        <RangeSlider name="Strike" unit="$" multiplier={5} />

        {/* <DropdownFilter name="Strike" options={FilterNameValueMap["Strike"]} /> */}
      </div>

      <div className="col-lg-6 col-md-6 col-sm-12">
        <RangeSlider name="Last" unit="$" multiplier={0.25} />

        {/* <DropdownFilter name="Strike" options={FilterNameValueMap["Strike"]} /> */}
      </div>
    </div>
  );
};
