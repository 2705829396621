import React, { useEffect, useState } from "react";
import "./index.css";
import { APIURL, site_type } from "../../../config";
import Tippy from "@tippyjs/react";
import auth from "../../../Routes/auth"

export const ExpiryTab = () => {

    const [allExpiry, setExpiry] = useState([])
    const [selected, setSelected] = useState()

    const [multiExpiry, setMultiExpiry] = useState(false)
    const [multiExpirySelected, setMultiExpirySelected] = useState([])

    const [isDedicated, setIsDedicated] = useState(false)

    useEffect(() => {
      if (site_type != "pro"){
          return
      }
      dedicatedInit()
    }, [])
  
    const dedicatedInit = async () => {
      let a = await auth.checkIsDedicated()
      setIsDedicated(a)
    }

    const GetExpiry = async () => {

        const res = await fetch(
            `${APIURL}/expiry`
        );
        if (res.status == 200) {
            const json = await res.json();
            setExpiry(json.data);
            setSelected(json.data[0])
            setMultiExpirySelected([json.data[0]])
        } else {
            console.log("API CALL FAIL");
        }
    };

    useEffect(() => {
        GetExpiry()
    }, [])

    useEffect(() => {
        if (allExpiry.length > 0){
            setSelected(allExpiry[0])
            sessionStorage.setItem('expiry', allExpiry[0])
        }
    }, [multiExpiry])

    const selectExpiry = (e) => {
        setSelected(e)
        sessionStorage.setItem('expiry', e)
    }

    const selectMultiExpiry = (e) => {
        let n = JSON.parse(JSON.stringify(multiExpirySelected))
        const index = n.indexOf(e)
        if (index > -1 && n.length > 1){
            n.splice(index, 1);
        } else {
            n.push(e)
        }

        setMultiExpirySelected(n)
        sessionStorage.setItem('expiry', n)
    }

    const groupSelectMultiExpiry = (e) => {
        setMultiExpirySelected(e)
        sessionStorage.setItem('expiry', e)
    }

    const enableMultiExpiry = () => {
        if (isDedicated){
            setMultiExpiry(true)
        }
    }

    if (multiExpiry){
        return (
            <>
            <div className="row">
                {
                    allExpiry.map(e => {
                        return (
                            <div className="col-lg-2 col-md-2 col-sm-3" key={e}>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={multiExpirySelected.includes(e)}
                                        onClick={() => selectMultiExpiry(e)}
                                    />
                                    <label className="form-check-label">{e}</label>
                                </div>
                            </div>
                        )
                    })}
            </div>
            <br/>
            <button className="btn btn-sm btn-secondary btnpad" onClick={() => setMultiExpiry(false)}>Disable Multi Expiry</button>&nbsp;&nbsp;
            <button className="btn btn-sm btn-info btnpad" onClick={() => groupSelectMultiExpiry(allExpiry)}>Select All Expiry</button>&nbsp;&nbsp;
            <button className="btn btn-sm btn-info btnpad" onClick={() => groupSelectMultiExpiry(allExpiry.slice(Math.ceil(allExpiry.length*2/3),))}>Select All LEAPS</button>&nbsp;&nbsp;
            <button className="btn btn-sm btn-info btnpad" onClick={() => groupSelectMultiExpiry(allExpiry.slice(0, 6))}>Select All within 1 month</button>&nbsp;&nbsp;
            <button className="btn btn-sm btn-info btnpad" onClick={() => groupSelectMultiExpiry([allExpiry[0]])}>Deselect All</button>
            </>
        )
    }

    return (
        <>
        <div className="row">
            {
                allExpiry.map(e => {
                    return (
                        <div className="col-lg-2 col-md-2 col-sm-3" key={e}>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    checked={e == selected}
                                    onClick={() => selectExpiry(e)}
                                />
                                <label className="form-check-label">{e}</label>
                            </div>
                        </div>
                    )
                })}
        </div>
        <br/>
        <Tippy content="Requires Dedicated tier. Run scans across multiple expirations." placement="right">
            <span><button className="btn btn-sm btn-info" disabled={!isDedicated} onClick={() => enableMultiExpiry()}>Enable Multi Expiry</button></span>
        </Tippy>
        </>
    )
}