import React, { useEffect, useState } from "react";
import "./index.css";
import DropdownFilter from "../DropdownFilter"
import { APIURL } from "../../../config";
import { ExpiryTab } from "./ExpiryTab"
import { LiquidityTab } from "./LiquidityTab"
import { OverviewTab } from "./OverviewTab"
import { OverviewTabSlider } from "./OverviewTabSlider"
import { FundamentalsTab } from "./FundamentalsTab"
import { TechnicalsTab } from "./TechnicalsTab"
import { SavedFiltersTab } from "./SavedFiltersTab"
import { GreeksTab } from "./GreeksTab";
import { TickersTab } from "./TickersTab";
import { UOATab } from "./UOATab";

export default function ScannerFilter({ GetOptionData, filterView }) {
    return (
        <>
            <div className="card-body no-pad">
                <div className="card card-primary card-outline card-outline-tabs">
                    <div className="card-header p-0 border-bottom-0">
                        <ul
                            className="nav nav-tabs"
                            id="custom-tabs-two-tab"
                            role="tablist"
                        >
                            <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    id="custom-tabs-two-home-tab"
                                    data-toggle="pill"
                                    href="#custom-tabs-two-home"
                                    role="tab"
                                    aria-controls="custom-tabs-two-home"
                                    aria-selected="true"
                                >
                                    Overview
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="custom-tabs-two-profile-tab"
                                    data-toggle="pill"
                                    href="#custom-tabs-two-profile"
                                    role="tab"
                                    aria-controls="custom-tabs-two-profile"
                                    aria-selected="false"
                                >
                                    Expiry
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="custom-tabs-two-greeks-tab"
                                    data-toggle="pill"
                                    href="#custom-tabs-two-greeks"
                                    role="tab"
                                    aria-controls="custom-tabs-two-greeks"
                                    aria-selected="false"
                                >
                                    Greeks
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="custom-tabs-two-messages-tab"
                                    data-toggle="pill"
                                    href="#custom-tabs-two-messages"
                                    role="tab"
                                    aria-controls="custom-tabs-two-messages"
                                    aria-selected="false"
                                >
                                    Liquidity
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="custom-tabs-two-uoa-tab"
                                    data-toggle="pill"
                                    href="#custom-tabs-two-uoa"
                                    role="tab"
                                    aria-controls="custom-tabs-two-uoa"
                                    aria-selected="false"
                                >
                                    UOA
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="custom-tabs-two-technical-tab"
                                    data-toggle="pill"
                                    href="#custom-tabs-two-technical"
                                    role="tab"
                                    aria-controls="custom-tabs-two-technical"
                                    aria-selected="false"
                                >
                                    Technicals
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="custom-tabs-two-settings-tab"
                                    data-toggle="pill"
                                    href="#custom-tabs-two-settings"
                                    role="tab"
                                    aria-controls="custom-tabs-two-settings"
                                    aria-selected="false"
                                >
                                    Fundamentals
                            </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="custom-tabs-two-tickers-tab"
                                    data-toggle="pill"
                                    href="#custom-tabs-two-tickers"
                                    role="tab"
                                    aria-controls="custom-tabs-two-tickers"
                                    aria-selected="false"
                                >
                                    Tickers
                            </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="custom-tabs-two-settings-tab"
                                    data-toggle="pill"
                                    href="#custom-tabs-two-bookmarked"
                                    role="tab"
                                    aria-controls="custom-tabs-two-bookmarked"
                                    aria-selected="false"
                                >
                                    Save Filter
                            </a>
                            </li>
                        </ul>
                    </div>
                    <div className="card-body">
                        <div className="tab-content" >
                            <div
                                className="tab-pane fade show active"
                                id="custom-tabs-two-home"
                            >
                                {filterView == 0 ? <OverviewTab /> : <OverviewTabSlider/>}
                                
                            </div>
                            <div
                                className="tab-pane fade"
                                id="custom-tabs-two-profile"
                            >
                                <ExpiryTab />
                            </div>
                            <div
                                className="tab-pane fade"
                                id="custom-tabs-two-messages"

                            >
                                <LiquidityTab />
                            </div>
                            <div
                                className="tab-pane fade"
                                id="custom-tabs-two-uoa"

                            >
                                <UOATab />
                            </div>
                            <div
                                className="tab-pane fade"
                                id="custom-tabs-two-greeks"

                            >
                                <GreeksTab />
                            </div>
                            <div
                                className="tab-pane fade"
                                id="custom-tabs-two-technical"
                            >
                                <TechnicalsTab />

                            </div>
                            <div
                                className="tab-pane fade"
                                id="custom-tabs-two-settings"
                            >
                                <FundamentalsTab />

                            </div>
                            <div
                                className="tab-pane fade"
                                id="custom-tabs-two-tickers"
                            >
                                <TickersTab />

                            </div>
                            {/* <div
                                className="tab-pane fade"
                                id="custom-tabs-two-all"
                            >
                                <h4 className="text-centered">Overview</h4>
                                <OverviewTab />
                                <h4 className="text-centered">Liquidity</h4>
                                <LiquidityTab />
                                <h4 className="text-centered">Technicals</h4>
                                <TechnicalsTab />
                                <h4 className="text-centered">Fundamentals</h4>
                                <FundamentalsTab />

                            </div> */}
                            <div
                                className="tab-pane fade"
                                id="custom-tabs-two-bookmarked"
                            >
                                <SavedFiltersTab />

                            </div>
                        </div>
                    </div>
                    {/* /.card */}
                </div>

            </div>
        </>
    );
}
