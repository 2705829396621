import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import Tippy from "@tippyjs/react";
import Cookies from "js-cookie";
import { AUTHURL, DEDICATEDURL } from "../../config";
import Toast from "../Toast";

const Loading = {
  title: "Pulling & Recomputing Data",
  body:
    <p>Please wait. It takes about 5 seconds. When ready, your page will refresh. <i className="fas fa-spinner fa-spin"></i></p>,
  color: "info",
};

const InvalidRole = {
  title: "Requires Dedicated Tier",
  body: <p>Real time refresh of ticker data requires the dedicated tier. 
      You may upgrade on our <a href="https://fdscanner.com/subscribe" target="_blank">subscription page</a>.<br/>
      If you have just upgraded, please re-login.</p>,
  color: "warning",
};

const UnableToCheckRole = {
  title: "Unable to check tier",
  body:
    "If you see this message, please contact me at kayin@fdscanner.com and I will look into the issue",
  color: "danger",
};

const UnableToPullData = (text) => {
    return {
        title: "Unable to pull data",
        body: text,
        color: "danger"
    }

};

const TickerHeader = ({ ticker }) => {
  const tippyconent = `Instantly pulls and recomputes all data for ${ticker} on the Option Chain, Strike Chain, Tendies Index and Skew Calculation Tab. Available for Dedicated Tier subscribers.`;

  const [role, setRole] = useState("");

  const [showToast, setShowToast] = useState(false);
  const [toastInfo, setToastInfo] = useState("");

  const toastShowAndHide = (info) => {
    setToastInfo(info);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 5000);
  };

  const clickedRefresh = async () => {
    const token = Cookies.get("access_token") || "";
    const res = await fetch(`${AUTHURL}/token-check?token=${token}`);
    if (res.status == 200) {
      const json = await res.json();
      setRole(json.role);
      console.log(json.role);
      if (json.role == "dedicated") {
        tickerReload();
        toastShowAndHide(Loading);
      } else {
        toastShowAndHide(InvalidRole);
      }
    } else {
      console.log("Unable to get role info");
      toastShowAndHide(UnableToCheckRole);
    }
    setShowToast(true);
  };

  const tickerReload = async () => {
    const token = Cookies.get("access_token") || "";
    const res = await fetch(
      `${DEDICATEDURL}/ticker-reload?token=${token}&ticker=${ticker}`
    );
    if (res.status == 200) {
      console.log("done");
      window.location.reload();
    } else {
      console.log("Unable to reload for some reason");
      toastShowAndHide(UnableToPullData(res.text()));
      setShowToast(true);
    }
  };

  return (
    <>
      {showToast && (
        <Toast
          title={toastInfo.title}
          body={toastInfo.body}
          color={toastInfo.color}
        />
      )}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{ticker} Research</h1>
            </div>
            <div className="col-sm-6">
              <div className="pullright">
                <Tippy content={tippyconent} placement="bottom">
                  <button
                    type="button"
                    onClick={() => clickedRefresh()}
                    className={`btn btn-info`}
                  >
                    Refresh Data for {ticker}
                  </button>
                </Tippy>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TickerHeader;
