import React, { useState, useEffect } from "react";
import { CustomTable } from "../../Components/CustomTable";
import Template from "../../Components/Template";
import ContentHeader from "../../Components/ContentHeader";
import ScannerHeader from "../../Components/ContentHeader/ScannerHeader";
import { useLocation } from "react-router";
import queryString from "query-string";

import "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import { APIURL, ROWS_PER_PAGE, site_type, DEDICATEDURL } from "../../config";
import { getHeaderMapScanner, buildScannerArgs, filterMap, getCSVHeader } from "../../Util/index";
import ScannerFilter from "../../Components/Filter/ScannerFilter";
import { ScannerFilterSummary } from "../../Components/Filter/ScannerFilterSummary";
import Pagination from "../../Components/CustomTable/pagination";
import moment from "moment";
import HelmetComponent from "../../Components/Helmet";
import Toast from "../../Components/Toast";
import { CSVLink } from "react-csv";
import UpgradeFooter from "../../Components/Upgrade/UpgradeFooter";
import Cookies from "js-cookie";
import Tippy from "@tippyjs/react";

import auth from "../../Routes/auth";

const runSort = {
  'title': 'Sorting!',
  'body': 'Should take just a second. :)',
  'color': 'success'
}

const invalidSortColumn = {
  'title': 'Sorting error',
  'body': 'Sorting for this column is not enabled. Please try another column.',
  'color': 'warning'
}

const runningDedicatedScan = (tk) => {
  return{
  'title': 'Running Dedicated Scan!',
  'body': <p>Estimated Load Time: {tk * 6} seconds. Pulling data and re-computing all columns for {tk} tickers. Please wait on this page and do not navigate away. 
  <i className="fas fa-spinner fa-spin"></i>
  </p>,
  'color': 'warning'
  }
}

const dedicatedScanTooManyTK = {
  'title': 'Too many tickers selected',
  'body': `Dedicated scan is limited to 10 tickers for now. Please reduce number of tickers to 10 or less`,
  'color': 'danger'
}

const dedicatedScanFail = (title) => {
  return {
  'title': 'Error with Dedicated Scan',
  'body': title,
  'color': 'danger'
  }
}


const dedicatedScanMissingTK = {
  'title': 'Ticker list not set up',
  'body': `Pull Data and scan requires a custom ticker list of 1 - 10 tickers. It can be set in the Tickers filter tab`,
  'color': 'danger'
}

const dedicatedScanComplete = {
  'title': 'Dedicated Scan Complete',
  'body': `Pull data & scan completed!`,
  'color': 'success'
}

const Scanner = () => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [expiry, setExpiry] = useState(""); //Just the initial expiry
  // const [headerMap, setHeaderMap] = useState(getHeaderMapScanner());
  // const [hideFilter, setHideFilter] = useState(false)
  const [apiData, setApiData] = useState({});
  const [loadingIcon, setLoadingIcon] = useState(true);

  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false); // True for descending order

  const location = useLocation();
  const { load } = queryString.parse(location.search);

  const [showToast, setShowToast] = useState(false)
  const [toastInfo, setToastInfo] = useState(runSort)

  const [filterView, setFilterView] = useState(localStorage.getItem('slider_or_dropdown') || 0)

  const [displayExpiry, setDisplayExpiry] = useState("")

  const clickedSort = () => {
      setShowToast(true)
      setTimeout(() => {
          setShowToast(false)
      }, 5000)
    }

  const headerClicked = (headerName) => {
    //First time sorting
    console.log(headerName)
    if (!(headerName in filterMap)){
      console.log("Cant sort by this header")
      setToastInfo(invalidSortColumn)
      clickedSort()
      return
    }

    let apiHeaderName = filterMap[headerName]
    console.log(apiHeaderName)
    setToastInfo(runSort)
    clickedSort()
    setLoaded(false)
    if (!sortBy) {
      setSortBy(apiHeaderName);
      //GetOptionData();
      return;
    }

    // Not first time and clicking same header
    if (apiHeaderName == sortBy) {
      setSortOrder(!sortOrder);
      //GetOptionData();
      return;
    }

    //Not first time and different header
    setSortBy(apiHeaderName);
    setSortOrder(true);
    //GetOptionData();
  };

  let csvDL = <CSVLink
      headers={getCSVHeader()}
      data={data}
      filename={"download.csv"}
      className="btn btn-block btn-primary btn-sm"
      target="_blank"
    >
      Download This Page (CSV)
  </CSVLink>;

  const setPage = (page) => {
    console.log("got your page!", page);
    sessionStorage.setItem("scannerpage", page);
    GetOptionData();
  };

  const GetExpiry = async () => {
    const res = await fetch(`${APIURL}/expiry`);

    if (res.status == 200) {
      const json = await res.json();
      setExpiry(json.data[0]);
      console.log(json.data[0]);
    } else {
      console.log("API CALL FAIL");
    }
  };

  const GetTickers = () => {
    let tickers = JSON.parse(sessionStorage.getItem("selected_tickers") || '[]')
    let tkstr = ''
    tickers.forEach(ticker => {
      tkstr = `${tkstr}${tkstr.length > 0 ? ',' : ''}${ticker}`
    });
    return tickers
  }

  const GetOptionData = async () => {
    setLoadingIcon(true);
    let args = buildScannerArgs();
    let ss_expiry = sessionStorage.getItem("expiry") || expiry;
    let page = sessionStorage.getItem("scannerpage") || 0;
    let etfSelected = sessionStorage.getItem("etfSelected") || false;
    let tickers = GetTickers()
    console.log(ss_expiry, expiry);
    const res = await fetch(
      `${APIURL}/scanner?type=${site_type == "pro" ? "member" : 'f'}&page_size=${ROWS_PER_PAGE}&expiry=${ss_expiry}&${args}&page=${page}${
        sortBy != "" ? `&sort_by=${sortBy}&sort_asc=${sortOrder}` : ``
      }${tickers.length > 0 ? `&tickers=${tickers}` : ''}${`${site_type == "pro" ? `&token=${Cookies.get('access_token')}` : ''}`}${etfSelected ? `&etf=${etfSelected}` : ''}`
    );
    if (res.status == 200) {
      const json = await res.json();
      setData(json.data);
      // console.log(json.data)
      setApiData(json);
    } else {
      console.log("API CALL FAIL");
    }
    setLoadingIcon(false);
  };

  const GetDedicatedOptionData = async () => {

    let args = buildScannerArgs();
    let ss_expiry = sessionStorage.getItem("expiry") || expiry;
    let page = sessionStorage.getItem("scannerpage") || 0;
    let etfSelected = sessionStorage.getItem("etfSelected") || false;
    let tickers = GetTickers()
    if (tickers.length == 0){
      setToastInfo(dedicatedScanMissingTK)
      clickedSort()
      return
    }
    if (tickers.length > 10){
      setToastInfo(dedicatedScanTooManyTK)
      clickedSort()
      return
    }

    setToastInfo(runningDedicatedScan(tickers.length))
    setShowToast(true)
    setLoadingIcon(true);

    console.log(ss_expiry, expiry);
    const res = await fetch(
      `${DEDICATEDURL}/scanner?type=${site_type == "pro" ? "member" : 'f'}&page_size=${ROWS_PER_PAGE}&expiry=${ss_expiry}&${args}&page=${page}${
        sortBy != "" ? `&sort_by=${sortBy}&sort_asc=${sortOrder}` : ``
      }${tickers.length > 0 ? `&tickers=${tickers}` : ''}&token=${Cookies.get('access_token')}${etfSelected ? `&etf=${etfSelected}` : ''}`
    );
    if (res.status == 200) {
      const json = await res.json();
      setData(json.data);
      setApiData(json);
      setLoadingIcon(false);
      setToastInfo(dedicatedScanComplete)
      clickedSort()
    } else {
      setLoadingIcon(false);
      setToastInfo(dedicatedScanFail(res.text()))
      clickedSort()
      console.log("API CALL FAIL");
    }
    
  };

  useEffect(() => {
    if (!load) {
      sessionStorage.setItem("currentFilter", "{}");
      console.log("clear ss");
    }
    sessionStorage.setItem("selected_tickers", "[]");

    sessionStorage.removeItem("expiry");
    sessionStorage.setItem("scannerpage", 0);
    GetExpiry();
  }, []);

  useEffect(() => {
    if (expiry) {
      // sessionStorage.setItem('currentFilter', '{}')
      GetOptionData();
      setLoaded(true);
    }
  }, [expiry, sortBy, sortOrder]);

  useEffect(() => {
    //properly generate the expiry
    if (Object.keys(apiData).length > 1 && apiData.expiry.length){
      let e = apiData.expiry
      if (e.length == 1){
        setDisplayExpiry(<span>Selected expiry: {e[0]}</span>)
      } else if (e.length > 1){
        setDisplayExpiry(<Tippy content={JSON.stringify(e)}><span>{e.length} expiries shown.</span></Tippy>)
      }
    }
  }, [apiData])

  let title = `Option Scanner | Filter & Screen millions of options | FDscanner`;
  let description = `Use FDscanner's 15+ filters to screen millions of options for day trading and hedging`;

  return (
    <Template>
      <HelmetComponent title={title} description={description} />
      {/* <ContentHeader text={`Options Scanner`} /> */}
      <ScannerHeader text={`Options Scanner`} />
      {showToast && <Toast title={toastInfo.title} body={toastInfo.body} color={toastInfo.color} />}

      <div className="row">
        <div className="col-lg-9 col-md-9 col-sm-12">
          {loaded && <ScannerFilter GetOptionData={GetOptionData} filterView={filterView} GetDedicatedOptionData={GetDedicatedOptionData}/>}
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12">
          <ScannerFilterSummary
            GetOptionData={GetOptionData}
            loadingIcon={loadingIcon}
            csvDL={csvDL}
            filterView={filterView}
            setFilterView={setFilterView}
            GetDedicatedOptionData={GetDedicatedOptionData}
          />
        </div>
      </div>
      <div className="row pagiantion-row-pad">
        <div className="col-lg-4 col-md-4 col-sm-6"></div>
        <div className="col-lg-4 col-md-4 col-sm-6 text-center">
          {/* {apiData.expiry ? `Selected Expiry: ${apiData.expiry}` : ""} */}
        {displayExpiry}
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6">
          {loaded && (
            <Pagination
              page={apiData.current_page}
              setPage={setPage}
              maxPage={apiData.pages}
            />
          )}
        </div>
      </div>

      <div className="card card-primary card-outline card-outline-tabs">
        <div className="card-header p-0 border-bottom-0">
          <ul
            className="nav nav-tabs"
            id="custom-tabs-three-tab"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="nav-link active"
                id="custom-tabs-three-home-tab"
                data-toggle="pill"
                href="#custom-tabs-three-home"
                role="tab"
                aria-controls="custom-tabs-three-home"
                aria-selected="true"
              >
                Overview
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="custom-tabs-three-greeks-tab"
                data-toggle="pill"
                href="#custom-tabs-three-greeks"
                role="tab"
                aria-controls="custom-tabs-three-greeks"
                aria-selected="true"
              >
                Greeks
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="custom-tabs-three-profile-tab"
                data-toggle="pill"
                href="#custom-tabs-three-profile"
                role="tab"
                aria-controls="custom-tabs-three-profile"
                aria-selected="false"
              >
                Technicals
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="custom-tabs-three-messages-tab"
                data-toggle="pill"
                href="#custom-tabs-three-messages"
                role="tab"
                aria-controls="custom-tabs-three-messages"
                aria-selected="false"
              >
                ST Technicals
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="custom-tabs-three-settings-tab"
                data-toggle="pill"
                href="#custom-tabs-three-settings"
                role="tab"
                aria-controls="custom-tabs-three-settings"
                aria-selected="false"
              >
                Fundamentals
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="custom-tabs-three-uoa-tab"
                data-toggle="pill"
                href="#custom-tabs-three-uoa"
                role="tab"
                aria-controls="custom-tabs-three-uoa"
                aria-selected="false"
              >
                UOA
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="custom-tabs-three-others-tab"
                data-toggle="pill"
                href="#custom-tabs-three-others"
                role="tab"
                aria-controls="custom-tabs-three-others"
                aria-selected="false"
              >
                Others
              </a>
            </li>
          </ul>
        </div>
        <div className="card-body">
          <div className="tab-content" id="custom-tabs-three-tabContent">
            <div
              className="tab-pane fade show active"
              id="custom-tabs-three-home"
            >
              {loaded && (
                <CustomTable
                  data={data}
                  headerMap={getHeaderMapScanner("Overview")}
                  currentPage={apiData.current_page}
                  pageSize={ROWS_PER_PAGE}
                  headerClicked={headerClicked}
                  sortedBy={sortBy}
                  sortedOrder={sortOrder}
                />
              )}
            </div>
            <div
              className="tab-pane fade"
              id="custom-tabs-three-greeks"
            >
              {loaded && (
                <CustomTable
                  data={data}
                  headerMap={getHeaderMapScanner("Greeks")}
                  currentPage={apiData.current_page}
                  pageSize={ROWS_PER_PAGE}
                  headerClicked={headerClicked}
                  sortedBy={sortBy}
                  sortedOrder={sortOrder}
                />
              )}
            </div>
            <div className="tab-pane fade" id="custom-tabs-three-profile">
              {loaded && (
                <CustomTable
                  data={data}
                  headerMap={getHeaderMapScanner("Technicals")}
                  currentPage={apiData.current_page}
                  pageSize={ROWS_PER_PAGE}
                  headerClicked={headerClicked}
                  sortedBy={sortBy}
                  sortedOrder={sortOrder}
                />
              )}
            </div>
            <div className="tab-pane fade" id="custom-tabs-three-messages">
              {loaded && (
                <CustomTable
                  data={data}
                  headerMap={getHeaderMapScanner("STTechnicals")}
                  currentPage={apiData.current_page}
                  pageSize={ROWS_PER_PAGE}
                  headerClicked={headerClicked}
                  sortedBy={sortBy}
                  sortedOrder={sortOrder}
                />
              )}
            </div>
            <div className="tab-pane fade" id="custom-tabs-three-settings">
              {loaded && (
                <CustomTable
                  data={data}
                  headerMap={getHeaderMapScanner("Fundamentals")}
                  currentPage={apiData.current_page}
                  pageSize={ROWS_PER_PAGE}
                  headerClicked={headerClicked}
                  sortedBy={sortBy}
                  sortedOrder={sortOrder}
                />
              )}
            </div>
            <div className="tab-pane fade" id="custom-tabs-three-uoa">
              {loaded && (
                <CustomTable
                  data={data}
                  headerMap={getHeaderMapScanner("Uoa")}
                  currentPage={apiData.current_page}
                  pageSize={ROWS_PER_PAGE}
                  headerClicked={headerClicked}
                  sortedBy={sortBy}
                  sortedOrder={sortOrder}
                />
              )}
            </div>
            <div className="tab-pane fade" id="custom-tabs-three-others">
              {loaded && (
                <CustomTable
                  data={data}
                  headerMap={getHeaderMapScanner("Others")}
                  currentPage={apiData.current_page}
                  pageSize={ROWS_PER_PAGE}
                  headerClicked={headerClicked}
                  sortedBy={sortBy}
                  sortedOrder={sortOrder}
                />
              )}
            </div>
          </div>
          <div className="text-center">
            {apiData.last_updated &&
              `Last Updated: ${moment(
                new Date(0).setUTCSeconds(apiData.last_updated))}`}
          </div>
        </div>
        {/* /.card */}
      </div>
      <UpgradeFooter page="Options Scanner"/>
    </Template>
  );
};

export default Scanner;
