import React, { useState, useEffect } from "react";
import { Button, LinkedButton, Icon } from "../Button";
import { Link } from "react-router-dom";
import moment from "moment";
import Pagination from "./pagination";
import ProgressBar from "react-bootstrap/ProgressBar";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { descriptionsExpanded } from "../../Util";

// import { SparklineChart } from "../Chart/Sparkline";
import "moment-timezone";
import "./index.css";

const defaultHeaderClicked = (e) => {
  console.log(e)
}

const sortDsc = <i className="fas fa-sort-amount-down"></i>
const sortAsc = <i className="fas fa-sort-amount-up-alt"></i>

export const CustomTable = ({ data, headerMap, currentPage, pageSize, headerClicked = defaultHeaderClicked, sortedBy = "", sortedOrder = "", tooltip = false }) => {
  return (
    <div className="table-responsive p-0">
      <table className="table table-head-fixed text-nowrap table-bordered table-striped dataTable dtr-inline">
        <thead>
          <tr>
            {Object.keys(headerMap).map((e) => {
              return <Tippy content={descriptionsExpanded[headerMap[e]]} key={e}
                disabled={!descriptionsExpanded[headerMap[e]]}>
                <th className="header_cursor"
                  onClick={() => headerClicked(headerMap[e])}>
                  {headerMap[e]}&nbsp;&nbsp;
                  {e == sortedBy && (sortedOrder ? sortAsc : sortDsc)}
                </th>
              </Tippy>
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => {
            return (
              <tr key={i}>
                {Object.keys(headerMap).map((e) => (
                  <td key={e}>
                    {colDataType(headerMap[e], row[e], i, currentPage, pageSize, row)}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const colDataType = (key, value, i, currentPage, pageSize, row) => {
  //Add extra cases as necessary.
  try {
    switch (key) {

      case "No.":
        return currentPage * pageSize + i + 1;

      case "Strike":
      case "Bid":
      case "Ask":
      case "Last":
      case "SMA200":
      case "SMA50":
      case "Price":
      case "Close to Open":
      case "5 Days Chg":
      case "Price Target":
      case "1x NTR":
      case "0.5x NTR":
      case "0.25x NTR":
      case "110% SP Call Price":
      case "90% SP Put Price":
      case "110% SP":
      case "90% SP":
      case "Share Price (SP)":
      case "Share Price":
        return `$${value}`;

      case "Theo":
      case "Underpriced By":
      case "Overpriced By":
        return `$${Math.round(1000 * value, 3)/ 1000}`;

      case "1X NTR %":
      case "0.5X NTR %":
      case "0.25X NTR %":
      case "% of Strike":
      case "% OTM":
      case "SMA200 %":
      case "SMA50 %":
      case "Short Interest":
      case "Close to Open %":
      case "5 Days Chg %":
      case "Short Interest":
      case "IV":
      case "Theta Annual %":
      case "Theta Daily %":
      case "52 Week Change":
        return `${value}%`;

      case "Underpriced %":
      case "Overpriced %":
        return `${Math.round(1000 * value, 1)/10}%`;


      case "Delta":
        return `${row['t'] == "P" ? '-' : ''}${value}`

      case "Market Cap":
        let l = value.toString().length
        if (l > 12) {
          return `$${Math.round(value / 10 ** 10) / 100}T`
        }
        if (l > 9) {
          return `$${Math.round(value / 10 ** 7) / 100}B`
        }
        if (l > 6) {
          return `$${Math.round(value / 10 ** 4) / 100}M`
        }
        return `$${value}`

      case "Type":
        if (value == "C" || value == "c") {
          return "Call"
        }
        return "Put"

      case "Ticker":
        return <Link to={`/ticker/${value}`}>{value}</Link>

      case "Last Traded":
        let ltd = localStorage.getItem('last_traded_display') || 0
        //return moment(value).fromNow()
        if (ltd == 0) return moment(value).tz('America/New_York').format('LLL')
        if (ltd == 1) return moment(value).fromNow()
        if (ltd == 2) return value

      default:
        return value;
    }
  }
  catch {
    return ''
  }
};
