import React from 'react'
import Template from "../../Components/Template";
import ContentHeader from "../../Components/ContentHeader";
import { Link } from 'react-router-dom';

export default function PutAbout() {
    return (
        <>
            <p>Put skew is defined as the price of 10% OTM puts/10% OTM calls for the next monthly option expiry. A put skew of 2.1 means the 10% OTM put is 2.1x the price of 10% OTM call. There are often underlying reasons worth researching before entering option position for stocks with high call or put skew.

            <Link to="/aboutskew">Click here</Link> to read the detailed formula to compute skew. Alternatively search for your ticker and view the Skew Calculation tab to see the step by step computation</p>
            <h4>Potential Causes of Put Skew</h4>
            <p>Theoretically, an equally OTM put and call trades at the same price after accounting for cost of carry, dividends and shorting costs. However, the real world doesn't always follow theory. Demand supply imbalances do happen and below are some possible reasons.</p>
            <ul>
                <li>Insitutions using the Collar strategy, buying puts and selling calls to limit downside at the cost of upside.</li>
                <li>High demand/Low supply for puts, driving up put prices. Usual occurence during bear markets.</li>
                <li>Low demand/High supply for calls, driving down calls prices.</li>
                <li>Stock had recent huge upward price movements and is grossly overvalued, shifting the supply/demand curve for puts.</li>
                <li>Dividends contributes to put skew, especially if the dividend is a large percentage of stock price.</li>
                
            </ul>
            <h4>Stock Universe</h4>
            <p>Calculating the skew requires 4 option prices, the 2 put options surrounding 90% current share price, and the 2 call options surrounding 110% of current share price. All 4 of these options must have bid/ask spread of below 25% to be included in the Put/Call Skew Index.</p>
            <p>From the universe of S&P500 stocks, if the stock passes the above liquidity criteria, it will be placed in Put Skew if the put price exceeds call price, and placed in Call Skew if call price exceeds put price.</p>
            <h4>Option Expiry Used</h4>
            <p>Skew uses the next monthly expiration prices for computation.</p>
            <h4>Computation</h4>
            <p><Link to="/abouskew">Click here</Link> to view detailed step by step computation of skew</p>
            </>
    )
}
