import React, { Component, useEffect, useState } from "react";
import { APIURL, DEDICATEDURL, JSONURL, site_type } from "../../config";
import "./index.css"
// import auth from '../../routes/auth';
// import HelmetComponent from "../../components/Helmet"
import Cookies from "js-cookie";
import Tippy from "@tippyjs/react";

const chains = {
    'Midpoint': 'mid',
    'Delta': 'delta',
    'Theta': 'theta',
    'Theta Annualized': 'thetaannual',
    '% of Strike': 'pctStrike',
    '% OTM': 'pctOTM'
}

const prepend = {
    'Midpoint': '$',
    'Delta': '',
    'Theta': '',
    'Theta Annualized': '',
    '% of Strike': '',
    '% OTM': '',
    'Returns': ''
}

const append = {
    'Midpoint': '',
    'Delta': '',
    'Theta': '',
    'Theta Annualized': '%',
    '% of Strike': '%',
    '% OTM': '%',
    'Returns': 'x'
}

const put_pt = {
    'On 0.5x Price': 0.5,
    'On 0.75x Price': 0.75,
    'On 0.9x Price': 0.9,
    'On 0.95x Price': 0.95,
}

const call_pt = {
    'On 1.05x Price': 1.05,
    'On 1.1x Price': 1.1,
    'On 1.25x Price': 1.25,
    'On 1.5x Price': 1.5,
    'On 2x Price': 2,
}

export const StrikeChainTab = ({ ticker, name }) => {

    const [loaded, setLoaded] = useState(false)
    const [showCalls, setShowCalls] = useState(true)
    const [data, setData] = useState([])

    const [shownChain, setShownChain] = useState('Midpoint')
    const [visibleChain, setVisibleChain] = useState('Midpoint')

    const [selectedReturn, setSelectedReturn] = useState(0)

    const [pt, setPT] = useState(0)

    useEffect(() => {
        setLoaded(false)
        if (showCalls){
            setSelectedReturn(1.05)
        } else {
            setSelectedReturn(0.95)
        }
    }, [showCalls])

    useEffect(() => {
        setLoaded(false)
        if (shownChain == 'Returns'){
            loadReturnsData()
        } else {
            loadData()
        }
    }, [showCalls, ticker, shownChain])

    useEffect(() => {
        setLoaded(false)
        loadReturnsData()
    }, [selectedReturn])

    

    const loadData = async (overridechain = '') => {
        const res = await fetch(
            `${APIURL}/strikechain?ticker=${ticker}&view=${overridechain || chains[shownChain]}&type=${showCalls ? 'calls' : 'puts'}`
        );
        if (res.status == 200) {
            const json = await res.json();
            setData(json)
            setLoaded(true)
            setVisibleChain(shownChain)
        } else {
            console.log("API CALL FAIL");
        }
    }

    const loadReturnsData = async () => {
        const token = Cookies.get("access_token") || "";
        const res = await fetch(
            `${DEDICATEDURL}/strikechain-returns?ticker=${ticker}&type=${showCalls ? 'calls' : 'puts'}&returns=${selectedReturn}&token=${token}`
        );
        if (res.status == 200) {
            const json = await res.json();
            setData(json.data)
            setPT(json.target)
            setLoaded(true)
            setVisibleChain(shownChain)
        } else {
            console.log("API CALL FAIL");
        }
    }

    const headerPart = <div className="row">
        <div className="col-sm-2 strikechain-header-pad">
            <div className="text-left">
                <button className={`btn ${showCalls ? 'bg-primary' : 'bg-secondary'}`} onClick={() => setShowCalls(true)}>Calls</button>&nbsp;&nbsp;
                    <button className={`btn ${!showCalls ? 'bg-primary' : 'bg-secondary'}`} onClick={() => setShowCalls(false)}>Put</button>
            </div>
        </div>
        <div className="col-sm-8 text-center strikechain-header-pad">
            <div class="btn-group" role="group">
                {Object.keys(chains).map(e =>
                    <button type="button"
                        className={`btn btn-${shownChain == e ? 'primary' : 'secondary'} btn-sm w100`}
                        onClick={() => setShownChain(e)}>
                        {e}
                    </button>)}

                <Tippy content={"Returns strikechain requires Pro membership"} placement="top">
                    <div class="btn-group">
                        <button type="button" 
                                disabled={site_type != "pro"} 
                                class={`btn btn-${shownChain == 'Returns' ? 'primary' : 'secondary'} btn-sm dropdown-toggle dropdown-icon`} 
                                data-toggle="dropdown">
                            Returns {shownChain == 'Returns' ? `${selectedReturn}x` : ``}&nbsp;
                        </button>
                        <div class="dropdown-menu">
                            {Object.keys(showCalls ? call_pt : put_pt).map(e => 
                                <a class="dropdown-item" 
                                    onClick={() => {
                                        setSelectedReturn(showCalls ? call_pt[e] : put_pt[e])
                                        setShownChain('Returns')
                                        }}>{e}</a>)}
                            {/* <div className="dropdown-divider"></div>
                            <a class="dropdown-item">Custom <input></input></a> */}
                        </div>
                    </div>
                </Tippy>

            </div>
            
        </div>
        <div className="col-sm-2 text-right strikechain-header-pad">
            {!loaded && <><i className="fas fa-spinner fa-spin" />&nbsp;&nbsp;</>}
            <b>{showCalls ? 'Calls' : 'Puts'} for {ticker}</b></div>
    </div>

    // if (!loaded) {
    //     return null
    // }

    if (Object.keys(data).length == 0) {
        return <>{headerPart}<br />
        Insufficient liquidity for {showCalls ? 'Call' : 'Put'} strike chain.</>
    }

    let title = `${name} ${ticker} All Option Expiries | FDscanner`
    let description = `View all option expiries for ${ticker} in a grid view`

    return <>
        {/* <HelmetComponent title={title} description={description} /> */}

        {headerPart}
        <br />
        {shownChain == "Returns" && <p>Showing returns of options if {ticker} hits ${pt} at expiration.</p>}
        <div className="table-responsive p-0">
            <table className="table table-head-fixed text-nowrap table-bordered table-striped dataTable dtr-inline">
                <thead>
                    <tr>
                        <th></th>
                        {(Object.keys(data[Object.keys(data)[0]])).map(e => <th key={e}>{e}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {
                        showCalls ?
                            Object.keys(data).map((e, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{e}</td>
                                        {(Object.keys(data[e])).map((f, g) => <td key={`${g}${data[e][f]}`}>{data[e][f] && prepend[visibleChain]}{data[e][f]}{data[e][f] && append[visibleChain]}</td>)}
                                    </tr>)
                            })
                            :
                            Object.keys(data).slice().reverse().map((e, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{e}</td>
                                        {(Object.keys(data[e])).map((f, g) => <td key={`${g}${data[e][f]}`}>{data[e][f] && prepend[visibleChain]}{data[e][f]}{data[e][f] && append[visibleChain]}</td>)}
                                    </tr>)
                            })
                    }

                </tbody>
            </table>
        </div>
        *Ask/Bid must be below 2, and non-zero bids must exist for option to display on Strike Chain.
    </>
}

const headerMap = {
    'ltd': 'Last Trade Time',
    's': 'Strike',
    'op': 'Last Price',
    'b': 'Bid',
    'a': 'Ask',
    'v': 'Volume',
    'oi': 'Open Interest',
    'iv': 'Implied Volatility',
    'pos': 'pctOptionStrike',
    'po': '% OTM'
}

const prependMap = {
    's': '$',
    'op': '$',
    'b': '$',
    'a': '$',

}

const appendMap = {
    'iv': '%',
    'pos': '%',
    'po': '%'
}