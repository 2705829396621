import React from "react";
import { patreon_client_id } from "../config";

export const PatreonLogin = () => {
  return (
    <div className="text-center">
      <button type="button" className="btn btn-danger pill">
        <i class="fab fa-patreon"></i>&nbsp;&nbsp;Patreon Members
      </button>
      <br />
      Please use your Patreon email to login above. To get your password,{" "}
      <a href="https://fdscanner.com/lost-password/" target="_blank">
        reset it here
      </a>
      .
    </div>
  );
};
