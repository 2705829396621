import React, { Component, useEffect, useState } from "react";
import moment from 'moment';
import { Link } from "react-router-dom";
import { APIURL, JSONURL } from "../../config";
import "./index.css"


export const OptionTab = ({ ticker, expiry, name }) => {

    const [updateTime, setUpdateTime] = useState(0)
    const [optionChain, setOptionChain] = useState({})
    const [selectedExpiry, setSelectedExpiry] = useState(expiry[0])
    const [loaded, setLoaded] = useState(false)
    const [showCalls, setShowCalls] = useState(true)
    const [data, setData] = useState({})

    useEffect(() => {

        loadExpiry(selectedExpiry)

    }, [expiry, selectedExpiry, ticker])

    const loadExpiry = () => {
        console.log(selectedExpiry)
        fetch(`${APIURL}/options-tab?ticker=${ticker}&expiry=${selectedExpiry}`)
            .then(data => {
                if (data.status === 200) {
                    data.json().then(data => {
                        console.log(data.data.call)
                        setOptionChain({ 'call': data.data.call, 'put': data.data.put })
                        setUpdateTime(data.time)
                        setData(data)
                        setLoaded(true)

                    });
                } else {
                    setSelectedExpiry(expiry[1])
                    console.log("not 200")
                }
            })
            .catch(e => {
                console.log("not err")
            });
    }

    if (!loaded) {
        return null
    }

    let title = `${name} ${ticker} Option Chain | Calls/Puts | FDscanner`
    let description = `Get the latest call and put option data for ${name} (${ticker}) for trading and research`


    let optionChainData = showCalls ? optionChain.call : optionChain.put.slice().reverse()
    return <>
        {/* <HelmetComponent title={title} description={description} /> */}

        <div className="row">
            <div className="col-sm-4">
                <div className="text-left">
                    <button className={`btn ${showCalls ? 'bg-primary' : 'bg-secondary'}`} onClick={() => setShowCalls(true)}>Calls</button>&nbsp;&nbsp;
                    <button className={`btn ${!showCalls ? 'bg-primary' : 'bg-secondary'}`} onClick={() => setShowCalls(false)}>Put</button>
                </div>
            </div>
            <div className="col-sm-4"><div className="text-center"><b>
                {showCalls ? 'Calls' : 'Puts'} for {selectedExpiry}<br />

            </b></div></div>
            <div className="col-sm-4 text-right"><form className="form-inline fr">
                <label>Expiration</label>&nbsp;&nbsp;&nbsp;
                        <select className="form-control" onChange={(e) => {
                    setSelectedExpiry(expiry[e.target.value])
                }}>
                    {expiry.map((e, i) => <option value={i} key={e}>{e}</option>)}
                </select>

            </form></div>
        </div>
        <div className="row oispace">
            <div className="col-sm-3 text-left">
                <b>Call Vol/OI - {data.call_voloi}</b>
            </div>
            <div className="col-sm-3">
                <b>Put Vol/OI - {data.put_voloi}</b>
            </div>
            <div className="col-sm-3">
                <b>Call/Put Vol - {data.callput_vol_ratio}</b>
            </div>
            <div className="col-sm-3 text-right">
                <b>Call/Put OI - {data.callput_oi_ratio}</b>
            </div>
        </div>
        <div className="table-responsive p-0">
            <table className="table table-head-fixed text-nowrap table-bordered table-striped dataTable dtr-inline">
                <thead>
                    <tr>
                        {/* {optionChainData.length > 0 && Object.keys(optionChainData[0]).map((e, i) => <th className="text-left" key={e} >{headerMap[e]}</th>)} */}

                        {optionChainData.length > 0 && Object.keys(headerMap).map((e, i) => <th className="text-left" key={e} >{headerMap[e]}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {optionChainData.map((row, i) =>
                        <tr key={i}>
                            {Object.keys(headerMap).map((e, i) => {
                                if (e == 'ltd') return <td className="text-left" key={e}>
                                    {new Date(row[e]).toString().split(" ").slice(1, 5).join(" ")}</td>

                                return <td className="text-left" key={e}>{prependMap[e]}{row[e]}{appendMap[e]}</td>

                            }
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
        <div className="text-center">
            Last Updated: {new Date(updateTime * 1000).toString().split(" ").slice(1, 5).join(" ")}</div>
    </>
}

const headerMap = {
    'ltd': 'Last Trade Time',
    's': 'Strike',
    'op': 'Last Price',
    'b': 'Bid',
    'a': 'Ask',
    'v': 'Volume',
    'oi': 'Open Interest',
    'iv': 'Implied Volatility',
    'pos': '% of Strike',
    'po': '% OTM'
}

const prependMap = {
    's': '$',
    'op': '$',
    'b': '$',
    'a': '$',
    'price': '$',
}

const appendMap = {
    'iv': '%',
    'pos': '%',
    'po': '%'
}