import React, { useState, useEffect } from 'react'
import { Button, LinkedButton, Icon } from '../Button'
import { Link } from 'react-router-dom'
import moment from 'moment';
import Pagination from './pagination';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { SparklineChart } from '../../Components/Chart/Sparkline';
import 'moment-timezone'
import './index.css'

export default function Table({ TableName = "", body = [], headerMap = {}, realTime = false, rowLimit = 20, pagination = true, idx = true, fixedHeight = true, algo = "" }) {
    const [page, setPage] = useState(0)
    const [max, setMax] = useState(0)

    useEffect(() => {
        setMax(Math.ceil(body.length / rowLimit))
        setPage(0)
    }, [body.length])

    return (
        <div className="card" >
            <div className="card-header">
                <h3 className="card-title">{TableName}</h3>
                <div className="card-tools">
                    {pagination && <Pagination page={page} setPage={setPage} maxPage={max} />}
                </div>
            </div>
            <div className={`card-body table-responsive p-0 ${fixedHeight ? "fixedHeight" : ""}`} >
                {body.length == 0 ? <p className="text-center">'No Data, try to select another date'</p> :
                    <table className="table text-nowrap stable">
                        <thead>
                            <tr>
                                {idx && <th>#</th>}
                                {Object.keys(headerMap).length === 0 ?
                                    Object.keys(body[0]).map(e => <th key={e}>{e}</th>) :
                                    Object.keys(headerMap).map(e => <th key={e}>{headerMap[e]}{e == "Roc" ? <sup>2</sup> : ''}</th>)
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {body.slice(page * rowLimit, (page + 1) * rowLimit).map((row, i) => {
                                return (
                                    <tr key={i}>
                                        {idx && <td className="stable">{page * rowLimit + i + 1}</td>}
                                        {Object.keys(headerMap).length === 0 ?
                                            Object.keys(row).map(e => <td key={e} className={`stable`}>{colDataType(e, row[e], row, TableName, algo)}</td>) :
                                            Object.keys(headerMap).map(e => <td key={e} className={`stable`}>{colDataType(headerMap[e], row[e], row, TableName, algo)}</td>)}
                                    </tr>)
                            })}
                        </tbody>
                    </table>}
            </div>
            <div className="card-footer clearfix">
                {pagination && <Pagination page={page} setPage={setPage} maxPage={max} />}
            </div>
        </div>
    )
}

//If that field needs some modification before showing the data, such as wrapping in button/color change.
const colDataType = (key, value, row, TableName, algo) => {
    //Add extra cases as necessary.
    let color

    switch (key) {
        case "15m Change":
        case "10m Change":
        case "5m Change":
        case "1h Change":
        case "Day Change":
        case "Slope":
        case "20m LR":
            if (!value) return '-'
            return <span className={value > 0 ? 'text-success' : 'text-danger'}>{value > 0 && "+"}{value}%</span>

        case "1h Range":
        case "Day Range":
            return <span>{value}%</span>


        case "Dir":
            color = getColor(row['15m_pct'] || row['Slope'])
            if (color == 'success') {
                return <Icon iconName="fas fa-chevron-up" color={color} />
            } else if (color == "danger") {
                return <Icon iconName="fas fa-chevron-down" color={color} />
            }
            return '-'

        case "15m Chart":
        case "20m Chart":
            color = getColor(row['15m_pct'] || row['Slope'])
            if (color == 'success') {
                color = 'green'
            } else {
                color = 'red'
            }
            return <SparklineChart color={color} data={value}/>
            
        case "btnlink_table1":
        case "btnlink_table2":
            return <LinkedButton link={value} text="View more" />

        case "Ticker":
            color = getColor(row['15m_pct'] || row['Slope'] || row['start_end_change'])
            return (<Button text={value} color={color} />)

        //Gotta take the unchange header value
        case "Price":
        case "1h High":
        case "1h Low":
        case "Day Low":
        case "Day High":
            return `$${value}`

        case "Move":
            if (!value) return '-'
            return value

        case "Time":
            //return moment(value).tz('America/New_York').format('LLL')
            return moment(value).tz('America/New_York').format(localStorage.getItem('time_select') || 'LLL')

        case "Roc":
            return getMoveIcon(row['type'])

        case "Streak":
            return generateProgressBar(value)

        default:
            return value
    }
}

const generateProgressBar = (streak) => {
    const streak_select = localStorage.getItem('streak_select') || "bar"
    if (streak_select == "bar"){
        return (
            <div className="progress">
                <div className={streak >= 10 ? "progress-bar-max" : "progress-bar"} role="progressbar" aria-valuenow={60} aria-valuemin={0} aria-valuemax={100} style={{ width: `${streak * 10}%` }}>
                </div>
            </div>
        )
    }
    return streak
}

const getMoveIcon = (move) => {
    switch (move) {
        case "Steady Up Momentum":
        case "Steady Down Momentum":
            return <span><i className="fas fa-bacon text-info" />&nbsp;&nbsp;<b>STEADY</b></span >

        case "Waning Down Momentum":
        case "Waning Up Momentum":
            return <span><i className="fas fa-water text-warning" />&nbsp;&nbsp;<b>WANING</b></span>

        case "Surge Up Momentum":
        case "Surge Down Momentum":
            return <span><i className="fas fa-fire-alt text-danger"></i>&nbsp;&nbsp;<b>SURGE</b></span >

        default:
            return ''

    }

}

const getColor = (move) => {
    let color = 'secondary'
    if (move > 0) {
        color = 'success'
    } else if (move <= 0) {
        color = 'danger'
    }
    return color
}