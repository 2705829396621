import React, { useState, useEffect } from "react";
import { CustomTable } from "../../Components/CustomTable";
import Template from "../../Components/Template";
import ContentHeader from "../../Components/ContentHeader";
import { useLocation } from "react-router";
import queryString from "query-string";

import "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import { APIURL, ROWS_PER_PAGE, site_type } from "../../config";
import { getHeaderMapScanner, buildScannerArgs } from "../../Util/index";
import ScannerFilter from "../../Components/Filter/ScannerFilter";
import { ScannerFilterSummary } from "../../Components/Filter/ScannerFilterSummary";
import Pagination from "../../Components/CustomTable/pagination";
import moment from "moment";
import "./index.css";
import { Link } from "react-router-dom";
import { TendiesSummary } from "./TendiesSummary";
import { SkewSummary } from "./SkewSummary";
import { UoaSummary } from "./UoaSummary";
import HelmetComponent from "../../Components/Helmet";
import { SpacIntro } from "./Spac";
import { ETFIntro } from "./ETFs";
import { MiiAlgo } from "./MiiAlgo";
import UpgradeFooter from "../../Components/Upgrade/UpgradeFooter";

let title = `FDscanner | Option scanner and screener`;
let description = `Filter and scan over 15 million options with near real time data with our free to use option scanner.`;

export default function Home() {
  return (
    <Template>
      <HelmetComponent title={title} description={description} />
      <div className="card">
        <div className="card-header">
          <h3>Welcome to FDscanner V2</h3>
        </div>
        <div className="card-body">
          <p>
            FDscanner Version 2 brings a greatly improved mobile experience,
            10-15x speedup in running scans, saving/loading filters, and a
            livestream of real-time scans from other users.
            <br />
            <br />
            {site_type != "pro" ? (
              <>
                If you enjoy the app or it helped you make money/save
                significant time, consider{" "}
                <a href="https://fdscanner.com/subscribe" target="_blank">
                  upgrading to Pro{" "}
                </a>
                to unlock all 3702 US stocks with options for all features. The free
                site is limited to S&P500 stocks. For pro users,{" "}
                <a href="http://member.fdscanner.com/" target="_blank">
                  login here
                </a>
                .
              </>
            ) : (
              <>Thank you for supporting my work!</>
            )}
          </p>
          {/* <h2>Currently in beta testing</h2>
          <h5>During beta, some data may be missing or shown as zero during off market hours, it's normal.</h5>
          <p>There's a few more bugs to be fixed and features to add, including removing options for tickers after stock split or reverse-split, detailed skew computation
            on the ticker page and sorting for tables before launching. It's a brand new build, if you encounter any issues do drop me an email at kayin@fdscanner.com. Feedback welcome!</p>
            <h3>Why am I making a V2</h3>
            <p>There were some design decisions made in the beginning that enabled me to build FDscanner V1 back in April
              <ul>
              <li>Fast</li>
              <li>With lower running costs</li>
              <li>Without need to worry about scaling</li>
              <li>Without certain technical skillsets (which I have now acquired)</li>
                </ul>
                It was the 80/20% rule applied, because I wanted something quick and useful for my trading. 
                Computations were offloaded to the user's in-browser javascript which means less expensive servers on my end, no need to auto-scale servers, but long load & scan times
                and huge data consumption. It was not a great experience especially on mobile, which is a way higher % of users than my expectations. In-browser computation also have limitations and working around them to build features is going to take longer than a re-write.   
                <br />
            <br />
              So a rewrite was pursued with new skills I picked up, computations are now sent to my servers, and hopefully your experience becomes way better. :)

            </p> */}
          {/* <h3>Patreon members</h3>
            <p>After V2 launch, you'll need to use your Patreon email to login. To get your password, please reset it here: https://fdscanner.com/lost-password/.
              You may start to take this action now, which also enables you to login to current FDscanner member's site with the email.
            </p>
            <h3>Launch date</h3>
            <p>I'm targeting Dec 15</p> */}
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <h3>Financial Derivatives Scanner for Options</h3>
        </div>
        <div className="card-body">
          <p>
            Filter and Screen over 10 million options by 26+ metrics including
            implied volatility, liquidity, Percentage OTM and more. Now you can
            be much more efficient finding the ideal option for hedging,
            trading, or premium selling.
            <br />
            Bookmark filters and load them up instantly, try our filter presets
            and view a livestream of other user filter runs.
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <Link to="/scanner">
              <button className="btn btn-block btn-primary bmax2 btn-lg">
                Go to Stock Option Scanner
              </button>
            </Link>
          </div>
        </div>
      </div>


      <div className="card">
        <div className="card-header">
          <h3>Arbitrage Scanner</h3>
        </div>
        <div className="card-body">
          <p>
            The Arbitrage Scanner finds options that deviate significantly from their theoretical value (Theo), 
            allowing you to open positions and book an immediate profit. <br /><br />
            Underpriced options are defined as options with an Ask below Theo, allowing traders to buy below the theoretical value.
            Overpriced options have their bids above Theo, allowing traders to open short positions above theoretical value, useful for put option sellers.
          </p>

          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-2">
              <Link to={`/arbitragescanner/underpriced/calls`}><button className="btn btn-block btn-info tk-btn">Underpriced Calls</button></Link>
            </div>
            <div className="col-md-2">
              <Link to={`/arbitragescanner/overpriced/calls`}><button className="btn btn-block btn-info tk-btn">Overpriced Calls</button></Link>
            </div>
            <div className="col-md-2">
              <Link to={`/arbitragescanner/underpriced/puts`}><button className="btn btn-block btn-info tk-btn">Underpriced Puts</button></Link>
            </div>
            <div className="col-md-2">
              <Link to={`/arbitragescanner/overpriced/puts`}><button className="btn btn-block btn-info tk-btn">Overpriced Puts</button></Link>
            </div>
            <div className="col-md-2"></div>
            
          </div>
        </div>
      </div>

    
      <div className="card">
        <div className="card-header">
          <h3>ETFs are live!</h3>
        </div>
        <div className="card-body">
          <p>
            A selected list of ETFs are now on FDscanner. They are searchable, but will not appear in the scanner, tendies/skew index or unusual activity. If an ETF is missing,
            drop me an <Link to="/contact">email</Link> and I will add it.
          </p>
          <ETFIntro />
        </div>
      </div>


      <div className="card">
        <div className="card-header">
          <h3>Max Tendies Index</h3>
        </div>
        <div className="card-body">
          <p>
            A purpose built screener for weekly options with tight spreads. We
            built a custom indicator, NTR, based on past 10 days volatility and
            used it to set price targets for every stock. Then we rank all
            options by the number of times it will multibag if that price target
            is hit. The number of times it'll multibag is the Tendies Index.
          </p>

          <div className="row">
            <div className="col-md-6">
              <TendiesSummary type="call" />
            </div>
            <div className="col-md-6">
              <TendiesSummary type="put" />
            </div>
          </div>
        </div>
      </div>

      <UpgradeFooter page="all features" />

      <div className="card">
        <div className="card-header">
          <h3>Put/Call Skew Index</h3>
        </div>
        <div className="card-body">
            The Put/Call skew is a computed metric that shows you the ratio of
            <ul>
              <li>For Call Skew - 10% OTM call/10% OTM put</li>
              <li>For Put Skew - 10% OTM put/10% OTM call</li>
              <li>
                This metric is computed for the next monthly option expiry date.
              </li>
            </ul>

          <div className="row">
            <div className="col-md-6">
              <SkewSummary type="call" />
            </div>
            <div className="col-md-6">
              <SkewSummary type="put" />
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <h3>Unusual Option Activity</h3>
        </div>
        <div className="card-body">
          <p>
            Retrieves unusual stock option activity, defined by high volume to
            open interest ratio. Option volume that exceeds open interest
            signals new positions by insitutional traders
          </p>
          <UoaSummary type="call" />
        </div>
      </div>

     
      <div className="card">
        <div className="card-header">
          <h3>SPACs with Options</h3>
        </div>
        <div className="card-body">
          <p>
            A compliation of all SPACs with options. High call skew is common
            for SPACs near PIPE value due to capped downside but unlimited
            upside like a normal stock. Due to high IV, traders can consider
            covered calls or short puts plays that's close to or below PIPE
            value after collecting premium, in addition to normal long calls or
            spreads.
          </p>
          <SpacIntro />
        </div>
      </div>

 
      <UpgradeFooter page="all features" />

      <div className="card">
        <div className="card-header">
          <h3>Real Time Momentum Scanner</h3>
        </div>
        <div className="card-body">
          <p>
            If your trading strategy involves intraday momentum trading,
            checkout MiiAlgo. It's a new service I developed to find stocks
            starting to exhibiting unusual and extreme momentum.
            <br />
            <br />
            This allows you to get alerted in real-time just as moves begin, so
            that you can make the trading decision early. There's 2 algorithms
            available now
          </p>
          <ul>
            <li>
              1) Market Movers - Real-Time algorithm that detects the start of
              extreme momentum and volume.
            </li>
            <li>
              2) Consistent Movers - Finds stocks that have very consistent, yet
              significant price movement in a single direction for 15mins or
              more. (Think 15 green minute bars in a row, all of similar sizes)
            </li>
          </ul>
          <p>
            A free demo is available that shows all alerts from the market
            movers algorithm with a 15 mins delay.
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <a href="https://miialgo.com/" target="_blank">
              <button className="btn btn-block btn-primary bmax2">
                Learn More About MiiAlgo
              </button>
            </a>
          </div>
        </div>
      </div>
    </Template>
  );
}
