import React from 'react'
import Template from "../../Components/Template";
import ContentHeader from "../../Components/ContentHeader";
import MAimage from "../../assets/img/mastercard.png"

export default function SkewComputation() {
    const PriceTable = (
        <table
            className="table table-bordered table-striped dataTable"
            role="grid"
        >
            <thead>
                <tr>
                    <th>
                        Legend
                                </th>
                    <th>
                        Description
                                </th>
                    <th>
                        Computation
                                </th>
                    <th>
                        Output
                                </th>
                </tr>
            </thead>
            <tbody>
                {tableData.map((e) => {
                    return (
                        <tr>
                            {e.map((f) => {
                                return (
                                    <td>
                                        {f}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}

            </tbody>
        </table>
    )

    const OptionTable = (
        <table
            className="table table-bordered table-striped dataTable"
            role="grid"
        >
            <thead>
                <tr>
                    <th>
                        Option
                                </th>
                    <th>
                        Computation
                                </th>
                    <th>
                        Midpoint price
                                </th>

                </tr>
            </thead>
            <tbody>
                {optionTableData.map((e) => {
                    return (
                        <tr>
                            {e.map((f) => {
                                return (
                                    <td>
                                        {f}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}

            </tbody>
        </table>
    )

    const rawDataTable = (
        <table
            className="table table-bordered table-striped dataTable"
            role="grid"
        >
            <thead>
                <tr>
                    <th>
                        Option
                                </th>
                    <th>
                        Expiry
                                </th>
                    <th>
                        Bid
                                </th>
                    <th>
                        Ask
                                </th>
                    <th>
                        Ask/Bid
                                </th>
                </tr>
            </thead>
            <tbody>
                {rawTableData.map((e) => {
                    return (
                        <tr>
                            {e.map((f) => {
                                return (
                                    <td>
                                        {f}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}

            </tbody>
        </table>
    )

    const interpolateTable = (
        <table
            className="table table-bordered table-striped dataTable"
            role="grid"
        >
            <thead>
                <tr>
                    <th>
                        Option
                                </th>
                    <th>
                        Computation
                                </th>
                    <th>
                        Interpolated price
                                </th>

                </tr>
            </thead>
            <tbody>
                {interpolateData.map((e) => {
                    return (
                        <tr>
                            {e.map((f) => {
                                return (
                                    <td>
                                        {f}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}

            </tbody>
        </table>
    )

    const skewTable = (
        <table
            className="table table-bordered table-striped dataTable"
            role="grid"
        >
            <thead>
                <tr>
                    <th>
                        Category
                                </th>
                    <th>
                        Requirement
                                </th>
                    <th>
                        Skew
                                </th>

                </tr>
            </thead>
            <tbody>
                {skewData
                    .map((e) => {
                        return (
                            <tr>
                                {e.map((f) => {
                                    return (
                                        <td>
                                            {f}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}

            </tbody>
        </table>
    )
    return (
        <Template>
            <ContentHeader text={`Put Skew Index`} />

        <div className="card">
            <div className="card-body">
            <section className="content about-content" data-test="Dashboard1-content">
                <h4>Computing the Put/Call Skew</h4>
                <div>
                    Put/Call Skew requires the current share price, bid/ask of 2 call options surrounding 110% of share price, bid/ask of 2 put options surrounding 90%
                    of share price to compute. The expiry date is for next monthly expiry, and the bid/ask spread for all 4 options must be below 25%.
                        </div>
                        <br/>
                <h5>Example Computation for MasterCard</h5>
                <div>
                    <br />
                    <img src={MAimage} style={{ 'max-width': '100%' }} />
                    <br /> <br />
                        In this example, data is pulled from MasterCard (MA) after close on May 14 2020. On that day, in our Put Skew Index, MA had a put skew of 1.911, let's see how this number is computed from raw data.

                    </div>

                <br /><br />
                <h4>Step 1: Computing prices, getting the right strikes</h4>
                {PriceTable}

                <br />
                <h4>Step 2: Ensuring options pass liquidity checks</h4>
                {rawDataTable}
                    The liquidity check requires Ask/Bid of all 4 options to be below 1.25. If any are above 1.25, they are excluded from Put/Call Skew index.
                    Stocks that barely make the liquidity cut off may enter and exit the Put/Call Skew index multiple times per day.
                    <br /><br />
                <h4>Step 3: Computing option midpoints</h4>
                {OptionTable}
                    The midpoint price is taken as the price for that option.
                    <br /><br />
                <h4>Step 4: Interpolated Price</h4>
                {interpolateTable}
                    Interpolate between the midpoint price of the 2 call and put options.
                    <br /><br />
                <h4>Step 5: Calculate Skew</h4>
                {skewTable}

                    If the Put price is higher, the stock has a put skew. If Call price is higher, the stock has a call skew.
                    <br />
                    In this example of MA on May 14 2020, we manually computed Put skew with a result of 1.903 using the end of day data.<br />
                    There's a slight disparity to FDscanner's computed 1.911 because the exact time we used to retrieve option bid/ask is slightly different.
                    <br /><br /><br />
                <h4 className="text-center">Result for MasterCard May 14 2020:</h4>
                <h5 className="text-center">Manually Computed Put Skew: 1.903</h5>
                <h5 className="text-center">FDscanner's Put Skew: 1.911</h5>
                <p className="text-center">Close enough</p>
                <br /><br /><br />
            </section>
                </div>
        </div>
        </Template>
    )
}

const tableData = [

    ['Price', 'Current Share Price', '', '$269.16'],
    ['110% SP', '110% of Current Share Price', '$269.16 * 1.1', '$296.08'],
    ['90% SP', '90% of Current Share Price', '$92 * 0.9', '$242.24'],
    ['High Call Strike', 'Call option immediately above 110% SP', 'Strike above $296.08', '$300 Call'],
    ['Low Call Strike', 'Call option immediately below 110% SP', 'Strike below $296.08', '$295 Call'],
    ['High Put Strike', 'Put option immediately above 90% SP', 'Strike above $242.24', '$245 Put'],
    ['Low Put Strike', 'Put option immediately below 90% SP', 'Strike below $242.24', '$240 Put']
]

const optionTableData = [

    ['$295 Call', '(2.64 + 2.93)/2', '$2.785'],
    ['$300 Call', '(1.65 + 1.97)/2', '$1.81'],
    ['$240 Put', '(4.3 + 4.6)/2', '$4.45'],
    ['$245 Put', '(5.2 + 5.75)/2', '$5.475']

]

const rawTableData = [

    ['$295 Call', '6/19', '$2.64', '$2.93', '2.93/2.64 ~= 1.11'],
    ['$300 Call', '6/19', '$1.65', '$1.97', '1.97/1.65 ~= 1.19'],
    ['$240 Put', '6/19', '$4.30', '$4.60', '4.6/4.30 ~= 1.07'],
    ['$245 Put', '6/19', '$5.20', '$5.75', '5.75/5.20 ~= 1.10']

]

const interpolateData = [
    ['$296.08 Call', '2.785 - (2.785-1.81)/(300-295) * (296.08 - 295)', '$2.5744'],
    ['$242.24 Put', '4.45 + (5.475-4.45)/(245-240) * (242.24 - 240)', '$4.898'],
]

const skewData = [
    ['Call Skew', '10% OTM Call Price > 10% OTM Put Price', 'N/A'],
    ['Put Skew', '10% OTM Put Price > 10% OTM Call Price', '$4.898/2.5744 = 1.903'],
]