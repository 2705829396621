import React, { Component, useState, useEffect } from "react";
import Chart from "chart.js";
import "./index.css"
/**
 *
 * @param  param0
 */


var chart;
var chartCanvas
export const ScatterChart = ({
    data,
    xlabel,
    ylabel,
    title,
    height = 300,
    x,
    y,
    mapping,
    refreshDestroy = false,
    ntr
}) => {
    const [randomID, setRandomID] = useState(Math.random())
    const [init, setInit] = useState(false)

    // useEffect(() => {
    //     draw()
    //     setInit(true)
    // }, [])

    useEffect(() => {
        //chart.destroy()
        setRandomID(Math.random())
        draw()
    }, [data]);

    

    const draw = () => {
        console.log("Scatter chart", data)
        chartCanvas = document.getElementById(`scatterchart${randomID}`).getContext('2d');
        //chartCanvas.height = height
        let chartData = []
        for (const i in data) {
            chartData.push({ x: data[i][x], y: data[i][y] })
        }

        const dataset = {
            data: chartData
        }

        var gradient = chartCanvas.createLinearGradient(0, 0, 800, 0);
        gradient.addColorStop(0, 'rgba(0,0,255,0.5)');
        gradient.addColorStop(1, 'rgba(255,0,0,1)');
        console.log(dataset);

        // Create the line chart
        chart = new Chart(chartCanvas, {
            type: "scatter",
            data: {
                datasets: [{
                    label: title,
                    data: chartData,
                    backgroundColor: gradient
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    xAxes: [{
                        type: 'linear',
                        position: 'bottom',
                        scaleLabel: {
                            display: true,
                            labelString: xlabel
                        }
                    }],
                    yAxes: [{
                        type: 'linear',
                        position: 'left',
                        scaleLabel: {
                            display: true,
                            labelString: ylabel
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, a) {
                            var label = data[tooltipItem.index]['tk'];
                            return `${label} (${mapping[x]['name']}:` +
                                `${mapping[x]['prepend']}${data[tooltipItem.index][x]}${mapping[x]['append']},` +
                                `${mapping[y]['name']}:` +
                                `${mapping[y]['prepend']}${data[tooltipItem.index][y]}${mapping[y]['append']})`
                            //return label + ': ( Share Price: ' + data[tooltipItem.index]['sp'] + `, IV: ` + data[tooltipItem.index]['iv'] + '%, Put/Call Skew: ' + tooltipItem.yLabel + ' )';
                        }
                    }
                }
            }
        });

    }


    return (

        <div className="chart">
            
            <canvas id={`scatterchart${randomID}`}/>
        </div>

    );
};