import React from "react";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';

const MenuConfig = {
  "Options Scanner": {
    icon: "far fa-question-circle",
    title: "",
    defaultOpen: false,
    data: {
      Scanner: {
        icon: "fas fa-search-dollar",
        url: "/scanner",
      },
      "Preset Filters": {
        icon: "fas fa-gift",
        url: "/filters/presets",
      },
      "Bookmarked Filters": {
        icon: "far fa-bookmark",
        url: "/filters/bookmarked",
      },
      "Live Stream": {
        icon: "fas fa-stream",
        url: "/filters/livestream",
      },
      //   Advanced: {
      //     icon: "fas fa-chart-line",
      //     url: "/live/overview",
      //   },
    },
  },

  "Arbitrage Scanner": {
    icon: "far fa-question-circle",
    title: "",
    defaultOpen: false,
    data: {
      "Underpriced Calls": {
        icon: "fas fa-angle-down",
        url: "/arbitragescanner/underpriced/calls",
      },
      "Underpriced Puts": {
        icon: "fas fa-angle-down",
        url: "/arbitragescanner/underpriced/puts",
      },
      "Overpriced Calls": {
        icon: "fas fa-angle-up",
        url: "/arbitragescanner/overpriced/calls",
      }, 
      "Overpriced Puts": {
        icon: "fas fa-angle-up",
        url: "/arbitragescanner/overpriced/puts",
      },
      //   Advanced: {
      //     icon: "fas fa-chart-line",
      //     url: "/live/overview",
      //   },
    },
  },


  'Tendies Index': {
    icon: "fas fa-drumstick-bite",
    title: "",
    defaultOpen: false,
    data: {
      'Call Tendies': {
        icon: "fas fa-drumstick-bite",
        url: "/tendies/call",
      },
      'Put Tendies': {
        icon: "fas fa-drumstick-bite",
        url: "/tendies/put",
      },
      // "About": {
      //   icon: "fas fa-user-circle",
      //   url: "/tendies/about",
      // },
      "Computation": {
        icon: "fas fa-calculator",
        url: "/tendies/computation",
      },

    },
  },

  'Skew Index': {
    icon: "far fa-question-circle",
    title: "",
    defaultOpen: false,
    data: {
      'Call Skew Index': {
        icon: "fas fa-balance-scale",
        url: "/callskew",
      },
      'Put Skew Index': {
        icon: "fas fa-balance-scale",
        url: "/putskew",
      },
      "Computing Skew": {
        icon: "fas fa-calculator",
        url: "/aboutskew",
      },
    },
  },

  'UOA': {
    icon: "far fa-question-circle",
    title: "",
    defaultOpen: false,
    data: {
      'Unusual Options Activity': {
        icon: "fas fa-wave-square",
        url: "/unusualvolume",
      },
      // 'Email Alerts': {
      //   icon: "fas fa-cogs",
      //   url: "/unusualvolume/alerts",
      // },
    },
  },

  'Info': {
    icon: "far fa-question-circle",
    title: "",
    defaultOpen: false,
    data:
    {
      'Column Descriptions': {
        icon: "fas fa-info-circle",
        url: "/column-descriptions",
      },
      'FAQ': {
        icon: "far fa-question-circle",
        url: "/faq",
      },
      'Contact': {
        icon: "fas fa-phone",
        url: "/contact",
      },
      'Sitemap': {
        icon: "fas fa-globe",
        url: "/sitemap",
      },
      'Settings': {
        icon: "fas fa-cogs",
        url: "/settings",
      },
    },
  },

  // Account: {
  //   icon: "far fa-question-circle",
  //   title: "",
  //   defaultOpen: false,
  //   data: {
  //     Settings: {
  //       icon: "fas fa-cogs",
  //       url: "/account/settings",
  //     },
  //     "Account Management": {
  //       icon: "fas fa-user-circle",
  //       url: "http://miialgo.com/member-login",
  //     },

  //     "Reset Password": {
  //       icon: "fas fa-lock",
  //       url: "http://miialgo.com/lost-password/",
  //     },
  //   },
  // },
};

export default function Menu() {

  let t = Cookies.get("tier") || 'Free'
  t = t.charAt(0).toUpperCase() + t.slice(1);

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <span className=" brand-link brand-text font-weight-light text-center">
          FDscanner V2
        </span>
        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <a href="https://fdscanner.com/subscribe" className="nav-link" target="_blank">
                  <i className="nav-icon fas fa-layer-group" />
                  <p>Tier: {t}</p>
                </a>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  <i className="nav-icon fas fa-home" />
                  <p>Home</p>
                </Link>
              </li>

              {/* <li className="nav-item">
                <Link to="/email-alerts" className="nav-link">
                  <i className="nav-icon far fa-image" />
                  <p>Email Alerts (soon)</p>
                </Link>
              </li> */}


              {Object.keys(MenuConfig).map((section, i) => {
                const sectionObject = MenuConfig[section];
                const sectionData = sectionObject.data;
                return (
                  <React.Fragment key={section}>
                    <li className="nav-header">
                      {i == 0 && <span>&nbsp;&nbsp;</span>}
                      {section}&nbsp;
                      {sectionObject.title && (
                        <i
                          className={sectionObject.icon}
                          data-toggle="tooltip"
                          title={sectionObject.title}
                        />
                      )}
                    </li>

                    {Object.keys(sectionData).map((key) => {
                      return (
                        <React.Fragment key={key}>
                          <li className="nav-item">
                            {sectionData[key]["url"].startsWith("http") ? (
                              <a
                                href={sectionData[key]["url"]}
                                className="nav-link"
                                target="_blank"
                              >
                                <i className={sectionData[key]["icon"]}></i>
                                &nbsp;
                                <p>{key}</p>
                              </a>
                            ) : (
                                <Link
                                  to={sectionData[key]["url"]}
                                  className="nav-link"
                                >
                                  <i className={sectionData[key]["icon"]}></i>
                                &nbsp;
                                  <p>{key}</p>
                                </Link>
                              )}
                          </li>
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                );
              })}

            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
}
