import React, { Component, useState, useEffect } from "react";
import Chart from "chart.js";

var chart;
var chartCanvas
const LineChartTime = ({
    data,
    xlabel,
    ylabel,
    title,
    height = 500
}) => {

    const [randomID, setRandomID] = useState(Math.random())

    useEffect(() => {
        //chart.destroy()
        setRandomID(Math.random())
        draw(data)
        // console.log('a', data)
    }, [data]);

    const draw = (d) => {
        chartCanvas = document.getElementById(`lineChart${randomID}`).getContext('2d');
        chartCanvas.height = height

        var gradient = chartCanvas.createLinearGradient(0, 800, 0, 0);
        gradient.addColorStop(0, 'rgba(0,0,255,0.5)');
        gradient.addColorStop(1, 'rgba(255,0,0,1)');
        // console.log(dataset);

        let xlabels = []
        for (let i = 0; i < Object.keys(d).length; i ++){
            xlabels.push(d[Object.keys(d)[i]]['x'])
        }

        // Create the line chart
        chart = new Chart(chartCanvas, {
            type: "bar",
            data: {
                datasets: [{
                    label: title,
                    data: d,
                    backgroundColor: gradient
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        labels: xlabels,
                        type: 'category',
                        position: 'bottom',
                        scaleLabel: {
                            display: true,
                            labelString: xlabel
                        }
                    }],
                    yAxes: [{
                        type: 'linear',
                        position: 'left',
                        scaleLabel: {
                            display: true,
                            labelString: ylabel
                        }
                    }]
                },
                // tooltips: {
                //     callbacks: {
                //         label: function (tooltipItem, a) {
                //             var label = data[tooltipItem.index]['tk'];
                //             return `${label} (${mapping[x]['name']}:` +
                //                 `${mapping[x]['prepend']}${data[tooltipItem.index][x]}${mapping[x]['append']},` +
                //                 `${mapping[y]['name']}:` +
                //                 `${mapping[y]['prepend']}${data[tooltipItem.index][y]}${mapping[y]['append']})`
                //             //return label + ': ( Share Price: ' + data[tooltipItem.index]['sp'] + `, IV: ` + data[tooltipItem.index]['iv'] + '%, Put/Call Skew: ' + tooltipItem.yLabel + ' )';
                //         }
                //     }
                // }
            }
        });

    }



    return (
        <>
            <div className="chart" style={{ height: '500px' }} >
                <canvas id={`lineChart${randomID}`} style={{ height: height }} />
            </div>
        </>
    );
};

export default LineChartTime;