import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "./Routes/protectedRoute"
import withTracker from "./Util/withTracker";
//Pages
import Login from './Pages/Login';
import Scanner from './Pages/Scanner';
import BookmarkedFilters from './Pages/Scanner/bookmarked-filters';
import TendiesIndex from './Pages/Tendies';
import TendiesAbout from './Pages/Tendies/About';
import TendiesComputation from './Pages/Tendies/Computation';
import SkewComputation from './Pages/Skew/Computation';
import CallSkew from './Pages/Skew/CallSkew';
import PutSkew from './Pages/Skew/PutSkew';
import Ticker from './Pages/Ticker';
import PresetFilters from './Pages/Scanner/preset-filters';
import LivestreamFilters from './Pages/Scanner/livestream';
import UnusualActivity from './Pages/UnusualActivity';
import Home from './Pages/Home';
import Sitemap from './Pages/Sitemap';
import Contact from './Pages/Contact';
import Settings from './Pages/Settings';
import OptionSymbol from './Pages/OptionSymbol';
import FAQ from './Pages/FAQ';
import ColumnDescriptions from './Pages/Scanner/column-description';
import ArbScanner from './Pages/ArbScanner';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <ProtectedRoute path="/" exact component={withTracker(Home)} />
        <ProtectedRoute path="/scanner" exact component={withTracker(Scanner)} />
        <ProtectedRoute path="/filters/bookmarked" exact component={withTracker(BookmarkedFilters)} />
        <ProtectedRoute path="/filters/presets" exact component={withTracker(PresetFilters)} />
        <ProtectedRoute path="/filters/livestream" exact component={withTracker(LivestreamFilters)} />

        <ProtectedRoute path="/tendies/computation" exact component={withTracker(TendiesComputation)} />
        <ProtectedRoute path="/tendies/:type" exact component={withTracker(TendiesIndex)} />

        <ProtectedRoute path="/aboutskew" exact component={withTracker(SkewComputation)} />
        <ProtectedRoute path="/callskew" exact component={withTracker(CallSkew)} />
        <ProtectedRoute path="/putskew" exact component={withTracker(PutSkew)} />

        <ProtectedRoute path="/unusualvolume" exact component={withTracker(UnusualActivity)} />
        <ProtectedRoute path="/column-descriptions" exact component={withTracker(ColumnDescriptions)} />

        <ProtectedRoute path="/arbitragescanner/underpriced/calls" exact component={withTracker(() => <ArbScanner price="underpriced" options="call" />)} />
        <ProtectedRoute path="/arbitragescanner/underpriced/puts" exact component={withTracker(() => <ArbScanner price="underpriced" options="put" />)} />
        <ProtectedRoute path="/arbitragescanner/overpriced/calls" exact component={withTracker(() => <ArbScanner price="overpriced" options="call" />)} />
        <ProtectedRoute path="/arbitragescanner/overpriced/puts" exact component={withTracker(() => <ArbScanner price="overpriced" options="put" />)} />


        <ProtectedRoute path="/ticker/:ticker" exact component={withTracker(Ticker)} />
        <ProtectedRoute path="/ticker/:ticker/tendies" exact component={withTracker(() => <Ticker page="tendies"/>)} />
        <ProtectedRoute path="/ticker/:ticker/options" exact component={withTracker(() => <Ticker page="options"/>)} />
        <ProtectedRoute path="/ticker/:ticker/strikechain" exact component={withTracker(() => <Ticker page="strikechain"/>)} />
        <ProtectedRoute path="/ticker/:ticker/skew" exact component={withTracker(() => <Ticker page="skew"/>)} />
        <ProtectedRoute path="/ticker/:ticker/ntr" exact component={withTracker(() => <Ticker page="ntr"/>)} />

        {/* <ProtectedRoute path="/ticker/:ticker/:symbol" exact component={withTracker(OptionSymbol)} /> */}

        <Route path="/login" exact component={withTracker(Login)} />
        <ProtectedRoute path="/contact" exact component={withTracker(Contact)} />
        <ProtectedRoute path="/settings" exact component={withTracker(Settings)} />
        <ProtectedRoute path="/faq" exact component={withTracker(FAQ)} />
        <ProtectedRoute path="/sitemap" exact component={withTracker(Sitemap)} />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
