import React, { useState, useEffect } from "react";
import { ScatterChart } from "../../Components/Chart/scatterChart";
import { APIURL, site_type } from "../../config";
import { CustomTable } from "../../Components/CustomTable";
import { getHeaderMapSkewSummary } from "../../Util/index";
import { Link } from "react-router-dom";

export const SkewSummary = ({ type }) => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);


  const loadInfo = async () => {
    const res = await fetch(
      `${APIURL}/skew-index?type=${type}${site_type == 'pro' ? '&p=1' : ''}&page_size=5`
    );
    if (res.status == 200) {
      const json = await res.json();
      setData(json);
      setLoaded(true);
    } else {
      console.log("API CALL FAIL");
    }
  };

  useEffect(() => {
    loadInfo(true);
  }, [type]);

  return (
    <>
      <div className="row rp">
        <div className="col-md-6"><h6>Top 5 {type} Skew</h6></div>
        <div className="col-md-6">
          <Link to={`${type}skew`}><button className="btn btn-block btn-info">View all {type} Skew</button></Link>
        </div>
      </div>

      {loaded && (
        <CustomTable
          data={data.data}
          headerMap={getHeaderMapSkewSummary(type)}
          currentPage={data.current_page}
          pageSize={5}
        />
      )}
    </>
  );
};
