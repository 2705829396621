import React, { useState, useEffect } from 'react'
import { CustomTable } from "../../Components/CustomTable";
import Pagination from "../../Components/CustomTable/pagination"
import Template from "../../Components/Template";
import ContentHeader from "../../Components/ContentHeader";
import "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import { APIURL, ROWS_PER_PAGE, site_type } from "../../config";
import { getHeaderMapUOA, filterMap, getCSVHeaderCustom } from "../../Util/index";
import moment from 'moment';
import HelmetComponent from '../../Components/Helmet';

import { LoadingIcon } from "../../Components/LoadingIcon"
import UpgradeFooter from '../../Components/Upgrade/UpgradeFooter';
import { CSVLink } from "react-csv";
import Tippy from "@tippyjs/react";

export default function UnusualActivity() {
    const [data, setData] = useState([]);
    const [dldata, setDLData] = useState('');
    const [loaded, setLoaded] = useState(false);

    const [page, setPage] = useState(0)

    const [sortBy, setSortBy] = useState("");
    const [sortOrder, setSortOrder] = useState(true); // True for descending order

    const headerClicked = (headerName) => {
      //First time sorting
      console.log(headerName)
      if (!(headerName in filterMap)){
        console.log("Cant sort by this header")
        // setToastInfo(invalidSortColumn)
        // clickedSort()
        return
      }
  
      let apiHeaderName = filterMap[headerName]
      console.log(apiHeaderName)
      // setToastInfo(runSort)
      // clickedSort()
      // setLoaded(false)
      if (!sortBy) {
        setSortBy(apiHeaderName);
        setSortOrder(true);
        //GetOptionData();
        return;
      }
  
      // Not first time and clicking same header
      if (apiHeaderName == sortBy) {
        setSortOrder(!sortOrder);
        //GetOptionData();
        return;
      }
  
      //Not first time and different header
      setSortBy(apiHeaderName);
      setSortOrder(true);
      //GetOptionData();
    };
  

    const loadInfo = async () => {

        const res = await fetch(
            `${APIURL}/unusualvolume?p=${site_type == "pro" ? "1" : "0"}&page_size=${ROWS_PER_PAGE}&page=${page}${sortBy != "" ? `&sort_by=${sortBy}&sort_asc=${sortOrder}` : ``}`
        );
        if (res.status == 200) {
            const json = await res.json();
            setData(json)         
            setDLData(<CSVLink
                headers={getCSVHeaderCustom(`uoa`)}
                data={json.data}
                filename={"download.csv"}
                className="btn"
                target="_blank"
            >
                <Tippy content="Download this page. Change rows per page in settings to download more at once."><i className="fas fa-download"></i></Tippy>
            </CSVLink>)
            setLoaded(true)
        } else {
            console.log("API CALL FAIL");
        }
    }

    useEffect(() => {
        loadInfo()
    }, [page, sortBy, sortOrder])

    let title = `Unusual Option Volume Activity | FDscanner`
    let description = `Updated Hourly, the unusual option activity scanner finds options with high volume to open interest ratio. 
                        This signifies new positions by insitutional traders either for hedging or speculation.`


    return (
        <Template>
            <HelmetComponent title={title} description={description} />
            <ContentHeader text={`Unusual Option Activity`} />
            <p>Retrieves unusual stock option activity, defined by high volume to open interest ratio. Option volume that exceeds open interest signals new positions by insitutional traders</p>
            <div className="card">
                <div className="card-body">

                    <div>
                        <div className="text-centered">{!loaded && <LoadingIcon />}</div>

                        <div className="row">
                            <div className="col">{dldata}</div>
                            <div className="col text-center">{data.last_updated && `Last Updated: ${moment(new Date(0).setUTCSeconds(data.last_updated)).toString()}`}</div>
                            <div className="col text-right">{loaded && <Pagination page={data.current_page} setPage={setPage} maxPage={data.pages} />}
                            </div>
                        </div>
                        {loaded && <CustomTable 
                                        data={data.data} 
                                        headerMap={getHeaderMapUOA()} 
                                        currentPage={data.current_page} 
                                        pageSize={20}
                                        headerClicked={headerClicked}
                                        sortedBy={sortBy}
                                        sortedOrder={sortOrder} />}
                    </div>
                </div>
            </div>
            <UpgradeFooter page="Unusual Activity"/>

        </Template>
    )
}


