import React, { useEffect, useState } from "react";
import "./index.css";
import { APIURL } from "../../../config";
import DropdownFilter from "../DropdownFilter"
import { FilterNameValueMap } from "../../../Util";

export const TechnicalsTab = () => {
    return (
        <div className="row">
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="SMA 200 Change" options={FilterNameValueMap['SMA 200 Change']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="SMA 50 Change" options={FilterNameValueMap['SMA 50 Change']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Short Interest" options={FilterNameValueMap['Short Interest']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Overnight Gap" options={FilterNameValueMap['Overnight Gap']} /></div>
        
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="5 Days Change" options={FilterNameValueMap['5 Days Change']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Share Price" options={FilterNameValueMap['Share Price']} /></div>
        </div>
    )
}
