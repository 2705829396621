import React from "react";
import { site_type } from "../../config";
import "./index.css";
import { Link } from "react-router-dom";

export default function UpgradeFooter({ page }) {
  if (site_type == "pro") {
    return null
  }

  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <a href="https://fdscanner.com/subscribe/" target="_blank">
          <button type="button" className="btn btn-block btn-danger btn-sm bmax">
            Upgrade to Pro
        </button>
        </a>
      </div>
      <p className="text-center">
        Instantly unlock the full universe of 3702 stocks for {page} and more
        for $25/month.
      </p>
    </>
  );
}
