import React from 'react'
import { Link } from 'react-router-dom'

export default function Pagination({ page = 1, setPage, maxPage }) {
    return (

        <ul className="pagination pagination-sm m-0 float-right">
            <li className="page-item"><a className="page-link" onClick={() => setPage((page - parseInt(maxPage / 4)) < 0 ? 0 : page - parseInt(maxPage / 4))}>«</a></li>
            <li className="page-item"><a className="page-link" onClick={() => setPage(page > 0 ? page - 1 : 0)}>&lt;</a></li>
            <li className="page-item"><a className="page-link">Page {page + 1} of {maxPage}</a></li>
            <li className="page-item"><a className="page-link" onClick={() => setPage(page >= maxPage - 1 ? maxPage - 1 : page + 1)}>&gt;</a></li>
            <li className="page-item"><a className="page-link"
                onClick={() => setPage((page + parseInt(maxPage / 4)) > maxPage - 1 ? maxPage - 1 : page + parseInt(maxPage / 4))}>»</a></li>
        </ul>

    )
}
