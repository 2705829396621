export const presets = {
    'ThetaGangWeekly (Best Run on Monday Mornings)': {
        "opt_sel": "2,2",
        "pos": "1,9999",
        "po": "5,9999",
        "ctop": "-9999,0",
        "fpe": "0.01,9999",
        
    },
    'Weekly FDs': {
        "pos": "0,0.5",
        "po": "0,5",
    }
}