import React, { useState } from "react";

export default function Toast({
  title = "title",
  subtitle = "",
  body = "",
  color = "success"
}) {

  const [hide, setHide] = useState(false)

  if (hide){
    return null
  }

  return (
    <div id="toastsContainerTopRight" className="toasts-top-right fixed">
      <div
        className={`toast bg-${color} fade show`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="toast-header">
          <strong className="mr-auto">{title}</strong>
          <small>{subtitle}</small>
          <button
            data-dismiss="toast"
            type="button"
            className="ml-2 mb-1 close"
            aria-label="Close"
          >
            <span aria-hidden="true" onClick={() => setHide(true)}>×</span>
          </button>
        </div>
        <div className="toast-body">
          {body}
        </div>
      </div>
    </div>
  );
}
