import React, { useState, useEffect } from 'react'
import { CustomTable } from "../../Components/CustomTable";
import Pagination from "../../Components/CustomTable/pagination"
import Template from "../../Components/Template";
import ContentHeader from "../../Components/ContentHeader";
import "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import { APIURL, JSONURL, ROWS_PER_PAGE, site_type } from "../../config";
import { getHeaderMapTendies, filterMap, getCSVHeaderCustom } from "../../Util/index";
import {
    useParams,
} from "react-router-dom";
import TendiesAbout from './About';
import { ScatterChart } from "../../Components/Chart/scatterChart"
import moment from 'moment';
import './index.css'
import { Link } from 'react-router-dom';
import { LoadingIcon } from "../../Components/LoadingIcon"
import HelmetComponent from '../../Components/Helmet';
import UpgradeFooter from '../../Components/Upgrade/UpgradeFooter';
import { CSVLink } from "react-csv";
import Tippy from "@tippyjs/react";

export default function TendiesIndex() {
    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [dldata, setDLData] = useState('')

    const [ntr_multiplier, set_ntr_multiplier] = useState(1)
    const [page, setPage] = useState(0)
    const [scatterData, setScatterData] = useState([])
    let { type } = useParams(); //call or put

    const [sortBy, setSortBy] = useState("");
    const [sortOrder, setSortOrder] = useState(true); // True for descending order

    const headerClicked = (headerName) => {
      //First time sorting
      console.log(headerName)
      if (!(headerName in filterMap)){
        console.log("Cant sort by this header")
        // setToastInfo(invalidSortColumn)
        // clickedSort()
        return
      }
  
      let apiHeaderName = filterMap[headerName]
      console.log(apiHeaderName)
      // setToastInfo(runSort)
      // clickedSort()
      // setLoaded(false)
      if (!sortBy) {
        setSortBy(apiHeaderName);
        setSortOrder(true);
        //GetOptionData();
        return;
      }
  
      // Not first time and clicking same header
      if (apiHeaderName == sortBy) {
        setSortOrder(!sortOrder);
        //GetOptionData();
        return;
      }
  
      //Not first time and different header
      setSortBy(apiHeaderName);
      setSortOrder(true);
      //GetOptionData();
    };
  

    const loadInfo = async (loadall=false) => {

        const res = await fetch(
            `${APIURL}/tendies-index?type=${type}&p=${site_type == "pro" ? "1" : "0"}&page_size=${ROWS_PER_PAGE}\
            &page=${page}&loadall=${!loaded || loadall ? '1' : '0'}${ntr_multiplier != 1 ? `&ntr=${ntr_multiplier}` : ''}\
            ${sortBy != "" ? `&sort_by=${sortBy}&sort_asc=${sortOrder}` : ``}`
        );
        if (res.status == 200) {
            const json = await res.json();
            setData(json)
            setDLData(<CSVLink
                headers={getCSVHeaderCustom(`tendies_${ntr_multiplier}`)}
                data={json.data}
                filename={"download.csv"}
                className="btn"
                target="_blank"
            >
                <Tippy content="Download this page. Change rows per page in settings to download more at once."><i className="fas fa-download"></i></Tippy>
            </CSVLink>)
            if (json.all) {
                setScatterData(json.all)
            }
            setLoaded(true)
        } else {
            console.log("API CALL FAIL");
        }
    }

    useEffect(() => {
        loadInfo(true)
    }, [type])

    useEffect(() => {
        loadInfo()
    }, [page, ntr_multiplier, sortBy, sortOrder])

    
    let title = `${type == 'call' ? 'Call' : 'Put'} Options Tendies Index | FDscanner`
    let description = `Tendies index shows how many times the options will be worth at the end of this week, if a reasonable price target is hit.
                    We build this index because option mispricing occurs more often on short expiration, OTM options.`

    return (
        <Template>
            <HelmetComponent title={title} description={description}/>
            <ContentHeader text={`${type[0].toUpperCase() + type.slice(1)} Tendies Index`} />
            <div className="card">
                <div className="card-body">
                    <TendiesAbout />
                    <div className="text-centered">{!loaded && <LoadingIcon/>}</div>

                    {loaded && <ScatterChart
                        data={scatterData}
                        xlabel={'NTR %'}
                        ylabel={`Tendies`}
                        title={`Tendies Index`}
                        x="ntr_pct"
                        y="tendies"
                        mapping={defaultMapping}
                        refreshDestroy={true}
                    // ntr={ntr_multiplier}
                    // key={ntr_multiplier}
                    />}
                    <div className="row">
                        <div className="col">
                            <div className="form-group a">
                                <label>Select NTR multiplier</label>
                                <select
                                    className="form-control select2bs4 select2-hidden-accessible"
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                        set_ntr_multiplier(e.target.value)
                                    }}>
                                    <option value={1}>
                                        1x
                                    </option>
                                    <option value={0.5}>
                                        0.5x
                                    </option>
                                    <option value={0.25}>
                                        0.25x
                                    </option>
                                    
                                </select>
                        </div>
                    </div>
            
                    <div className="col text-center"></div>
                    <div className="col text-right">
                            <Link to={`/tendies/${type == 'call' ? 'put' : 'call'}`}><button type="button" className="btn btn-block btn-primary button-sm b">Go to {type == 'call' ? 'Put' : 'Call'} Tendies Index</button></Link>
                    </div>
                </div>

                <div className="row">
                        <div className="col">{dldata}</div>
                        <div className="col text-center">{data.last_updated && `Last Updated: ${moment(new Date(0).setUTCSeconds(data.last_updated)).toString()}`}</div>
                    <div className="col text-right">{loaded && <Pagination page={data.current_page} setPage={setPage} maxPage={data.pages} />}
                    </div>
                </div>
                    {loaded && <CustomTable 
                                data={data.data} 
                                headerMap={getHeaderMapTendies(ntr_multiplier)} 
                                currentPage={data.current_page} pageSize={20} 
                                headerClicked={headerClicked}
                                sortedBy={sortBy}
                                sortedOrder={sortOrder}/>
                    }
            </div>
            </div>
            <UpgradeFooter page="Tendiex Index"/>

        </Template >
    )
}

const defaultMapping = {
    'tk': {
        'name': 'Ticker',
        'prepend': '',
        'append': ''
    },
    'p': {
        'name': 'Price',
        'prepend': '$',
        'append': ''
    },
    's': {
        'name': 'Strike',
        'prepend': '$',
        'append': ''
    },
    't': {
        'name': 'Type',
        'prepend': '',
        'append': ''
    },
    'tendies': {
        'name': 'TENDIES INDEX',
        'prepend': '',
        'append': ''
    },
    'ntr': {
        'name': 'NTR',
        'prepend': '$',
        'append': ''
    },
    'ntr_pct': {
        'name': 'NTR %',
        'prepend': '',
        'append': '%'
    },
    'pt': {
        'name': 'Price Target',
        'prepend': '$',
        'append': ''
    },
    'b': {
        'name': 'Bid',
        'prepend': '$',
        'append': ''
    },

    'a': {
        'name': 'Ask',
        'prepend': '$',
        'append': ''
    },
    'op': {
        'name': 'Last Price',
        'prepend': '$',
        'append': ''
    },
    'v': {
        'name': 'Vol',
        'prepend': '',
        'append': ''
    },
    'oi': {
        'name': 'Open Int',
        'prepend': '',
        'append': ''
    },
}
