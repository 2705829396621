import React, { useEffect } from 'react'

import ReactPixel from 'react-facebook-pixel';
import { site_type } from "../../config";


const free_pixel = '2831148790432646'
const pro_pixel = '360313665297067'

const Footer = () => {
    useEffect(() => {
        if (site_type == 'free'){
            ReactPixel.init(free_pixel);
        } else if (site_type == 'pro'){
            ReactPixel.init(pro_pixel);
        } else {
            ReactPixel.init('0');
        }
    })

    return (
        <>
        </>
    )
}

export default Footer;


// < !--Facebook Pixel Code-- >
// <script>
//         !function(f,b,e,v,n,t,s)
//   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
//   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
//   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
//   n.queue=[];t=b.createElement(e);t.async=!0;
//   t.src=v;s=b.getElementsByTagName(e)[0];
//   s.parentNode.insertBefore(t,s)}(window, document,'script',
//   'https://connect.facebook.net/en_US/fbevents.js');
//   fbq('init', '2831148790432646');
//   fbq('track', 'PageView');
// </script>
// <noscript><img height="1" width="1" style="display:none"
//   src="https://www.facebook.com/tr?id=2831148790432646&ev=PageView&noscript=1"
// /></noscript>
// <!--End Facebook Pixel Code-- >


// < !--Facebook Pixel Code-- >
// <script>
//         !function(f,b,e,v,n,t,s)
//   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
//   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
//   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
//   n.queue=[];t=b.createElement(e);t.async=!0;
//   t.src=v;s=b.getElementsByTagName(e)[0];
//   s.parentNode.insertBefore(t,s)}(window, document,'script',
//   'https://connect.facebook.net/en_US/fbevents.js');
//   fbq('init', '360313665297067');
//   fbq('track', 'PageView');
// </script>
// <noscript><img height="1" width="1" style="display:none"
//   src="https://www.facebook.com/tr?id=360313665297067&ev=PageView&noscript=1"
// /></noscript>
// <!--End Facebook Pixel Code-- >