import React, { useEffect, useState } from "react";
import Template from "../../../Components/Template";
import ContentHeader from "../../../Components/ContentHeader";
import DisplayContent from "../DisplayContent";
import { APIURL } from "../../../config";

export default function LivestreamFilters() {
  const [data, setData] = useState([])
  let intervalID
  const GetData = async () => {
    const res = await fetch(
      `${APIURL}/livestream`
    );
    if (res.status == 200) {
      const json = await res.json();
      console.log(json)
      setData(json.data);
      
    } else {
      console.log("API CALL FAIL");
    }
  };

  useEffect(() => { 
    GetData()
    intervalID = setInterval(() => GetData(), 15000)
    return () => clearInterval(intervalID)
  }, []);


  const convertTime = (sec) => {
    var hours = Math.floor(sec / 3600);
    (hours >= 1) ? sec = sec - (hours * 3600) : hours = '00';
    var min = Math.floor(sec / 60);
    (min >= 1) ? sec = sec - (min * 60) : min = '00';
    (sec < 1) ? sec = '00' : void 0;

    // (min.toString().length == 1) ? min = '0' + min : void 0;
    // (sec.toString().length == 1) ? sec = '0' + sec : void 0;

    if (hours >= 1){
      return `${hours}hr ${min} mins ago`
    }

    if (min >= 1){
      return `${min} mins ago`
    }

    if (min == 0){
      return 'Less than a minute ago'
    }

  }

  const loadFilter = (key) => {
    let k = key
    delete k['expiry']
    delete k['time']

    sessionStorage.setItem('currentFilter', JSON.stringify(k))
    window.location.href = `/scanner?load=true`
  }



  return (
    <Template>
      <ContentHeader text={`Livestream of Scans`} />
      Real-time scans with over 3 parameters are livestreamed here.
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-body p-0">
            <table className="table table-striped projects">
              <thead>
                <tr>
                  <th style={{ width: "1%" }}>#</th>
                  <th>Scan Time</th>
                  <th>Expiry</th>
                  <th>Filter Parameters</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map((e, i) => {
                  let filterContent = data[e];
                  let f = JSON.parse(JSON.stringify(e))
                  
                  delete f['expiry']
                  delete f['sort_by']
                  delete f['sort_asc']
                  let time = f['time']
                  delete f['time']
                  delete f['type']
                  return (
                    <tr key={e}>
                      <td>{i + 1}</td>
                      <td>{convertTime(Math.round(new Date().getTime() / 1000 - time))}</td>
                      <td>
                        {e['expiry']}
                      </td>
                      <td>
                        <DisplayContent content={f} />
                      </td>
                     
                      <td className="project-actions text-right">
                        {/* <a className="btn btn-primary btn-sm" href="#" onClick={() => { loadFilter(e) }}> 
                          <i className="fas fa-folder"></i>
                          Load
                        </a>  */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
    </Template>
  );
}
