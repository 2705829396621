import React from "react";
import { Link } from "react-router-dom";

export default function Nodata({ ticker }) {
  return (
    <div>
      No recent alerts found for <b>{ticker}</b>. Try searching for more
      volatile tickers or click one of them below:
      <ul>
        <li>
          <Link to="/ticker/NVDA">NVDA</Link>
        </li>
        <li>
          <Link to="/ticker/AMD">AMD</Link>
        </li>
        <li>
          <Link to="/ticker/TSLA">TSLA</Link>
        </li>
        <li>
          <Link to="/ticker/STNG">STNG</Link>
        </li>

        <li>
          <Link to="/ticker/UBER">UBER</Link>
        </li>
        <li>
          <Link to="/ticker/KODK">KODK</Link>
        </li>
        <li>
          <Link to="/ticker/LYFT">LYFT</Link>
        </li>
        <li>
          <Link to="/ticker/FSLY">FSLY</Link>
        </li>
        <li>
          <Link to="/ticker/OSTK">OSTK</Link>
        </li>
        <li>
          <Link to="/ticker/STT">STT</Link>
        </li>
      </ul>
    </div>
  );
}
