import * as moment from "moment";

export const buildScannerArgs = () => {
  let args = JSON.parse(sessionStorage.getItem("currentFilter") || '{}');
  let argsStr = "";
  Object.keys(args).map((e) => {
    argsStr = `${argsStr}${argsStr == "" ? "" : "&"}${e}=${args[e]}`;
  });
  return argsStr;
};

export const getHeaderMapScanner = (page) => {
  const headerMap = {
    Overview: {
      no: "No.",
      tk: "Ticker",
      t: "Type",
      expiry: "Expiry",
      s: "Strike",
      sp: "Share Price",
      b: "Bid",
      a: "Ask",
      op: "Last",
      pos: "% of Strike",
      po: "% OTM",
      v: "Volume",
      oi: "Open Interest",
      iv: "IV",
    },
    Greeks: {
      no: "No.",
      tk: "Ticker",
      t: "Type",
      s: "Strike",
      sp: "Share Price",
      b: "Bid",
      a: "Ask",
      op: "Last",
      theo: "Theo",
      delta: "Delta",
      gamma: "Gamma",
      vega: "Vega",
      theta: "Theta",
      thetaAnnualPct: "Theta Annual %",
      thetaDailyPct: "Theta Daily %",
      po: "% OTM",
      iv: "IV",
    },
    Technicals: {
      no: "No.",
      tk: "Ticker",
      t: "Type",
      s: "Strike",
      sp: "Share Price",
      s200: "SMA200",
      pd200: "SMA200 %",
      s50: "SMA50",
      pd50: "SMA50 %",
      si: "Short Interest",
      ed: "Earnings Date",
    },
    STTechnicals: {
      no: "No.",
      tk: "Ticker",
      t: "Type",
      s: "Strike",
      sp: "Share Price",
      cto: "Close to Open",
      ctop: "Close to Open %",
      c5d: "5 Days Chg",
      c5dp: "5 Days Chg %",
    },
    Fundamentals: {
      no: "No.",
      tk: "Ticker",
      t: "Type",
      s: "Strike",
      sp: "Share Price",
      beta: "Beta",
      fpe: "Forward PE",
      tpe: "PE ratio",
      tps: "PS ratio",
      pb: "PB ratio",
      mcap: "Market Cap"
    },
    Uoa: {
      no: "No.",
      tk: "Ticker",
      t: "Type",
      s: "Strike",
      uoa: "UOA Vol/OI",
      total_voloi: "Total Vol/OI",
      call_voloi: "Call Vol/OI",
      put_voloi: "Put Vol/OI",
      callput_vol_ratio: "Call/Put Vol",
      callput_oi_ratio: "Call/Put OI",
    },
    Others: {
      no: "No.",
      tk: "Ticker",
      t: "Type",
      s: "Strike",
      contractSymbol: "Contract Symbol",
      lastTradeDate: "Last Traded",
      b: "Bid",
      a: "Ask",
      op: "Last",
      po: "% OTM",
    },
  };

  if (page == 'all') return headerMap

  return headerMap[page];
};

export const getCSVHeader = () => {
  let headerMapScanner = getHeaderMapScanner('all')
  let alreadyPlaced = {}
  let headers = []
  Object.keys(headerMapScanner).forEach(e => {
    Object.keys(headerMapScanner[e]).forEach(f => {
      if (!(f in alreadyPlaced)) {
        headers.push({ 'label': headerMapScanner[e][f], 'key': f })
      }
      alreadyPlaced[f] = headerMapScanner[e][f]
    })
  })
  return headers
}


export const getHeaderMapTendies = (ntr) => {
  const headerMap = {

    no: "No.",
    tk: "Ticker",
    s: "Strike",
    t: "Type",
    tendies: "TENDIES INDEX",
    ntr: `${ntr}x NTR`,
    ntr_pct: `${ntr}X NTR %`,
    sp: "Share Price",
    pt: "Price Target",
    b: "Bid",
    a: "Ask",
    op: "Last",
    // pos: "% of Strike",
    // po: "% OTM",
    v: "Volume",
    oi: "Open Interest",
    // iv: "IV",

  };
  return headerMap
}


export const getHeaderMapTendiesSummary = () => {
  const headerMap = {

    tk: "Ticker",
    tendies: "TENDIES",
    sp: "Share Price",
    pt: "Price Target",

  };
  return headerMap
}

export const getHeaderMapSkewSummary = (skew) => {
  const headerMap = {
    tk: "Ticker",
    pd: `${skew} Skew`,
    iv: "IV",
    sp: "Share Price (SP)",
  };
  return headerMap
}

export const getHeaderMapUOASummary = () => {
  const headerMap = {

    tk: "Ticker",
    s: "Strike",
    t: "Type",
    expiry: "Expiry",

    b: "Bid",
    a: "Ask",
    op: "Last",
    iv: "IV",
    v: "Volume",
    oi: "Open Interest",
    "vol/oi": "Vol/OI",

  };
  return headerMap
}

export const getHeaderMapUOA = () => {
  const headerMap = {

    no: "No.",
    tk: "Ticker",
    po: "% OTM",
    s: "Strike",
    t: "Type",
    expiry: "Expiry",

    b: "Bid",
    a: "Ask",
    op: "Last",

    v: "Volume",
    oi: "Open Interest",
    "vol/oi": "Vol/OI",
    iv: "IV"
  };
  return headerMap
}

export const getHeaderMapArbOP = () => {
  const headerMap = {

    no: "No.",
    tk: "Ticker",
    po: "% OTM",
    s: "Strike",
    t: "Type",
    expiry: "Expiry",

    b: "Bid",
    a: "Ask",
    theo: "Theo",
    arb_v: 'Overpriced By',
    arb: 'Overpriced %',
    op: "Last",

    v: "Volume",
    oi: "Open Interest",
    iv: "IV"
  };
  return headerMap
}

export const getHeaderMapArbUP = () => {
  const headerMap = {

    no: "No.",
    tk: "Ticker",
    po: "% OTM",
    s: "Strike",
    t: "Type",
    expiry: "Expiry",

    b: "Bid",
    a: "Ask",
    theo: "Theo",
    arb_v: 'Underpriced By',
    arb: 'Underpriced %',
    op: "Last",

    v: "Volume",
    oi: "Open Interest",
    iv: "IV"
  };
  return headerMap
}

export const getHeaderMapSkew = (skew) => {
  const headerMap = {
    no: "No.",
    tk: "Ticker",
    pd: `${skew} Skew`,
    name: "Name",
    sec: "Sector",
    sp: "Share Price (SP)",
    iv: "IV",
    hp: "110% SP",
    lp: "90% SP",
    hpcp: "110% SP Call Price",
    lppp: "90% SP Put Price"
  }
  return headerMap
}

export const filterMap = {
  //Fundamentals
  "Forward PE": "fpe",
  "PE Ratio": "tpe",
  "PS Ratio": "tps",
  "PB Ratio": "pb",
  Beta: "beta",
  "Market Cap": "mcap",

  //Liquidity
  Volume: "v",
  "Open Interest": "oi",

  //Overview
  "% of Strike": "pos",
  "% OTM": "po",
  IV: "iv",

  //Technicals
  "Short Interest": "si",
  "SMA 200 Change": "pd200",
  "SMA 50 Change": "pd50",
  "Overnight Gap": "ctop",
  "5 Days Change": "c5dp",

  "Zero Bids": "zero_bids",
  "Option Selection": "opt_sel",
  "Strike": "s",
  "Share Price": "sp",
  "Price": "sp",
  "Last": "op",
  "Last Traded": "lastTradeDate",

  "Delta": "delta",
  "Vega": "vega",
  "Gamma": "gamma",
  "Theta Annual %": "thetaAnnualPct",
  "TENDIES INDEX": "tendies",
  "1X NTR %": "ntr_pct",
  "0.5X NTR %": "ntr_pct",
  "0.25X NTR %": "ntr_pct",
  "1x NTR": "ntr",
  "0.5x NTR": "ntr",
  "0.25x NTR": "ntr",

  "Vol/OI": "vol/oi",
  "Expiry": "expiry",
  "Type": "t",

  "Total Vol/OI": "total_voloi",
  "Call Vol/OI": "call_voloi",
  "Put Vol/OI": "put_voloi",
  "Call/Put Vol": "callput_vol_ratio",
  "Call/Put OI": "callput_oi_ratio",
  "UOA Vol/OI": "uoa"
};

export const filterMapReverse = {
  //Fundamentals
  fpe: "Forward PE",
  tpe: "PE Ratio",
  tps: "PS Ratio",
  pb: "PB Ratio",
  beta: "Beta",

  //Liquidity
  v: "Volume",
  oi: "Open Interest",

  //Overview
  pos: "% of Strike",
  po: "% OTM",
  iv: "IV",

  //Greeks
  gamma: "Gamma",
  delta: "Delta",
  vega: "Vega",
  thetaAnnualPct: "Theta Annual %",

  //Technicals
  si: "Short Interest",
  pd200: "SMA 200 Change",
  pd50: "SMA 50 Change",
  ctop: "Overnight Gap",
  c5dp: "5 Days Change",

  zero_bids: "Zero Bids",
  opt_sel: "Option Selection",

  sp: "Share Price",
  s: "Strike",
  op: "Last",
  lastTradeDate: "Last Traded",

  etf: "",

  total_voloi: "Total Vol/OI",
  call_voloi: "Call Vol/OI",
  put_voloi: "Put Vol/OI",
  callput_vol_ratio: "Call/Put Vol",
  callput_oi_ratio: "Call/Put OI",
  uoa: "UOA Vol/OI"
};

export const FilterNameValueMap = {
  "Forward PE": {
    "Show All": [-9999, 9999],
    "Low (< 15)": [0, 15],
    "Profitable > 0": [0.01, 9999],
    "Losing Money < 0": [-9999, 0],
    "Under 5": [0, 5],
    "Under 10": [0, 10],
    "Under 15": [0, 15],
    "Under 20": [0, 20],
    "Under 30": [0, 30],
    "Under 40": [0, 40],
    "Under 50": [0, 50],
    "Above 5": [5, 9999],
    "Above 10": [10, 9999],
    "Above 15": [15, 9999],
    "Above 20": [20, 9999],
    "Above 30": [30, 9999],
    "Above 40": [40, 9999],
    "Above 50": [50, 9999],
  },
  "PE Ratio": {
    "Show All": [-9999, 9999],
    "Low (< 15)": [0, 15],
    "Profitable > 0": [0.01, 9999],
    "Losing Money < 0": [-9999, 0],
    "Under 5": [0, 5],
    "Under 10": [0, 10],
    "Under 15": [0, 15],
    "Under 20": [0, 20],
    "Under 30": [0, 30],
    "Under 40": [0, 40],
    "Under 50": [0, 50],
    "Above 5": [5, 9999],
    "Above 10": [10, 9999],
    "Above 15": [15, 9999],
    "Above 20": [20, 9999],
    "Above 30": [30, 9999],
    "Above 40": [40, 9999],
    "Above 50": [50, 9999],
  },
  "PS Ratio": {
    "Show All": [-9999, 9999],
    "Low (< 1)": [0, 1],
    "High > 10": [10, 9999],
    "Under 1": [0, 1],
    "Under 2": [0, 2],
    "Under 3": [0, 3],
    "Under 4": [0, 4],
    "Under 5": [0, 5],
    "Under 7": [0, 7],
    "Under 10": [0, 10],
    "Under 20": [0, 20],
    "Under 50": [0, 50],
    "Above 1": [1, 9999],
    "Above 2": [2, 9999],
    "Above 3": [3, 9999],
    "Above 5": [5, 9999],
    "Above 10": [10, 9999],
    "Above 20": [20, 9999],
    "Above 50": [50, 9999],
  },
  "PB Ratio": {
    "Show All": [-9999, 9999],
    Negative: [-9999, 0],
    "Low < 1": [0, 1],
    "High > 10": [10, 9999],
    "Under 1": [0, 1],
    "Under 2": [0, 2],
    "Under 3": [0, 3],
    "Under 4": [0, 4],
    "Under 5": [0, 5],
    "Under 7": [0, 7],
    "Under 10": [0, 10],
    "Under 20": [0, 20],
    "Under 50": [0, 50],
    "Above 1": [1, 9999],
    "Above 2": [2, 9999],
    "Above 3": [3, 9999],
    "Above 5": [5, 9999],
    "Above 10": [10, 9999],
    "Above 20": [20, 9999],
    "Above 50": [50, 9999],
  },
  Beta: {
    "Show All": [-9999, 9999],
    Negative: [-9999, 0],
    "Below 0.5": [0, 0.5],
    "Below 1": [0, 1],
    "Below 1.5": [0, 1.5],
    "Below 2": [0, 2],
    Positive: [0, 9999],
    "Above 0.5": [0.5, 9999],
    "Above 1": [1, 9999],
    "Above 1.5": [1.5, 9999],
    "Above 2": [2, 9999],
    "0 - 0.5": [0, 0.5],
    "0.5 - 1": [0.5, 10],
    "1 - 1.5": [1, 1.5],
    "1.5 - 2": [1.5, 2],
  },
  Volume: {
    "Show All": [0, 9999],
    "> 5": [5, 9999],
    "> 25": [25, 9999],
    "> 100": [100, 9999],
    "> 250": [250, 9999],
    "> 500": [500, 9999],
  },
  "Open Interest": {
    "Show All": [0, 9999],
    "> 5": [5, 9999],
    "> 25": [25, 9999],
    "> 100": [100, 9999],
    "> 250": [250, 9999],
    "> 500": [500, 9999],
  },
  "% of Strike": {
    "Show All": [0, 9999],
    "< 0.5%": [0, 0.5],
    "< 1%": [0, 1],
    "< 2%": [0, 2],
    "< 3%": [0, 3],
    "< 4%": [0, 4],
    "< 5%": [0, 4],
    "> 1%": [1, 9999],
    "> 2%": [2, 9999],
    "> 3%": [3, 9999],
    "> 4%": [4, 9999],
    "> 5%": [5, 9999],
    "> 7.5%": [7.5, 9999],
    "> 10%": [10, 9999],
  },
  "% OTM": {
    "Show All": [0, 9999],
    "Over 5% OTM": [5, 9999],
    "Over 10% OTM": [10, 9999],
    "Over 20% OTM": [20, 9999],
    "Over 30% OTM": [30, 9999],
    "Over 40% OTM": [40, 9999],
    "Over 50% OTM": [50, 9999],
    "Under 5% OTM": [0, 5],
    "Under 10% OTM": [0, 10],
    "Under 20% OTM": [0, 20],
    "Under 30% OTM": [0, 30],
    "Under 40% OTM": [0, 40],
    "Under 50% OTM": [0, 50],
  },
  IV: {
    "Show All": [0, 9999],
    "Greater than 50% IV": [50, 9999],
    "Greater than 100% IV": [100, 9999],
    "Greater than 250% IV": [250, 9999],
    "Less than 25% IV": [0, 25],
    "Less than 50% IV": [0, 50],
    "Less than 75% IV": [0, 75],
    "Less than 100% IV": [0, 100],
  },
  "5 Days Change": {
    'Show All': [-9999, 9999],
    'Positive (Above 0%)': [0, 9999],

    'Above 5%': [5, 9999],
    'Above 10%': [10, 9999],
    'Above 15%': [15, 9999],
    'Above 20%': [20, 9999],
    'Negative (Below 0%)': [-9999, 0],
    'Below -5%': [-9999, -5],
    'Below -10%': [-9999, -10],
    'Below -15%': [-9999, -15],
    'Below -20%': [-9999, -20],

  },
  "Overnight Gap": {
    'Show All': [-9999, 9999],
    'Opened Above Previous Close': [0, 9999],
    'Over 2% Gap up': [2, 9999],
    'Over 5% Gap up': [5, 9999],
    'Over 10% Gap up': [10, 9999],
    'Opened Below Previous Close': [-9999, 0],
    'Over 2% Gap Down': [-9999, -2],
    'Over 5% Gap Down': [-9999, -5],
    'Over 10% Gap Down': [-9999, -10],
  },
  "Short Interest": {
    'Show All': [-9999, 9999],
    'Under 5%': [0, 5],
    'Under 10%': [0, 10],
    'Under 15%': [0, 15],
    'Under 20%': [0, 20],
    'Under 25%': [0, 25],
    'Above 5%': [5, 9999],
    'Above 10%': [10, 9999],
    'Above 15%': [15, 9999],
    'Above 20%': [20, 9999],
    'Above 25%': [25, 9999],

  },
  "SMA 50 Change": {
    'Show All': [-9999, 9999],
    'Below SMA50': [-9999, 0],
    'Above SMA50': [0, 9999],
    '> 50% Below SMA50': [-9999, -50],
    '> 40% Below SMA50': [-9999, -40],
    '> 30% Below SMA50': [-9999, -30],
    '> 20% Below SMA50': [-9999, -20],
    '> 10% Below SMA50': [-9999, -10],

    '> 10% Above SMA50': [10, 9999],
    '> 20% Above SMA50': [20, 9999],
    '> 30% Above SMA50': [30, 9999],
    '> 40% Above SMA50': [40, 9999],
    '> 50% Above SMA50': [50, 9999],
  },
  "SMA 200 Change": {
    'Show All': [-9999, 9999],
    'Below SMA200': [-9999, 0],
    'Above SMA200': [0, 9999],
    '> 50% Below SMA200': [-9999, -50],
    '> 40% Below SMA200': [-9999, -40],
    '> 30% Below SMA200': [-9999, -30],
    '> 20% Below SMA200': [-9999, -20],
    '> 10% Below SMA200': [-9999, -10],

    '> 10% Above SMA200': [10, 9999],
    '> 20% Above SMA200': [20, 9999],
    '> 30% Above SMA200': [30, 9999],
    '> 40% Above SMA200': [40, 9999],
    '> 50% Above SMA200': [50, 9999],
  },

  "Strike": {
    'Show All': [-9999, 9999],
    'Below $5': [-9999, 5],
    'Below $10': [-9999, 10],
    'Below $20': [-9999, 20],
    'Below $50': [-9999, 50],
    'Below $100': [-9999, 100],
    'Below $500': [-9999, 500],

    'Above $5': [5, 9999],
    'Above $10': [10, 9999],
    'Above $20': [20, 9999],
    'Above $50': [50, 9999],
    'Above $100': [100, 9999],
    'Above $500': [500, 9999],
  },

  "Share Price": {
    'Show All': [-9999, 9999],
    'Below $5': [-9999, 5],
    'Below $10': [-9999, 10],
    'Below $20': [-9999, 20],
    'Below $50': [-9999, 50],
    'Below $100': [-9999, 100],
    'Below $250': [-9999, 250],
    'Below $500': [-9999, 500],

    'Above $5': [5, 9999],
    'Above $10': [10, 9999],
    'Above $20': [20, 9999],
    'Above $50': [50, 9999],
    'Above $100': [100, 9999],
    'Above $250': [250, 9999],
    'Above $500': [500, 9999],
  },

  "Option Selection": {
    'Show Calls & Puts': [0, 0],
    'Show Calls Only': [1, 1],
    'Show Puts Only': [2, 2]
  },
  "Zero Bids": {
    'Show': [0, 0],
    'Hide': [1, 1],
  },

  "Last": {
    'Show All': [0, 9999],
    'Under $0.05': [0, 0.05],
    'Under $0.10': [0, 0.10],
    'Under $0.25': [0, 0.25],
    'Under $0.5': [0, 0.5],
    'Under $1': [0, 1],
    'Under $2.5': [0, 2.5],
    'Under $5': [0, 5],
    'Under $10': [0, 10],
    'Under $25': [0, 25],
  },

  "Last Traded": {
    'Show All': [0, 9999],
    'Within 1 day': [new Date().getTime() - 86400 * 1000, 9999],
    'Within 3 days': [new Date().getTime() - 3 * 86400 * 1000, 9999],
    'Within 1 week': [new Date().getTime() - 7 * 86400 * 1000, 9999],
  },

  "Delta": {
    'Show All': [-9999, 9999],
    'Under 0.1': [-9999, 0.1],
    'Under 0.2': [-9999, 0.2],
    'Under 0.3': [-9999, 0.3],
    'Under 0.4': [-9999, 0.4],
    'Over 0.1': [0.1, 9999],
    'Over 0.2': [0.2, 9999],
    'Over 0.3': [0.3, 9999],
    'Over 0.4': [0.4, 9999],
    '0 - 0.1': [-9999, 0.1],
    '0.1 - 0.2': [0.1, 0.2],
    '0.2 - 0.3': [0.2, 0.3],
    '0.3 - 0.4': [0.3, 0.4],
    '0.4 - 0.5': [0.4, 0.5],
  },

  "Gamma": {
    'Show All': [-9999, 9999],
    'Under 0.1': [-9999, 0.1],
    'Under 0.2': [-9999, 0.2],
    'Under 0.3': [-9999, 0.3],
    'Under 0.4': [-9999, 0.4],
    'Under 0.5': [-9999, 0.5],
    'Above 0.5': [0.5, 9999],
    'Above 0.6': [0.6, 9999],
    'Above 0.7': [0.7, 9999],
    'Above 0.8': [0.8, 9999],
    'Above 0.9': [0.9, 9999],
    '0.1 - 0.25': [0.1, 0.25],
    '0.25 - 0.4': [0.25, 0.4],
    '0.25 - 0.4': [0.25, 0.4],
    '0.4 - 0.6': [0.4, 0.6],
    '0.6 - 0.75': [0.6, 0.75],
    '0.75 - 0.9': [0.75, 0.9],
  },

  "Gamma": {
    'Show All': [-9999, 9999],
    'Under 0.1': [-9999, 0.1],
    'Under 0.2': [-9999, 0.2],
    'Under 0.3': [-9999, 0.3],
    'Under 0.4': [-9999, 0.4],
    'Under 0.5': [-9999, 0.5],
    'Above 0.5': [0.5, 9999],
    'Above 0.6': [0.6, 9999],
    'Above 0.7': [0.7, 9999],
    'Above 0.8': [0.8, 9999],
    'Above 0.9': [0.9, 9999],
    '0.1 - 0.25': [0.1, 0.25],
    '0.25 - 0.4': [0.25, 0.4],
    '0.25 - 0.4': [0.25, 0.4],
    '0.4 - 0.6': [0.4, 0.6],
    '0.6 - 0.75': [0.6, 0.75],
    '0.75 - 0.9': [0.75, 0.9],
  },

  "Vega": {
    'Show All': [-9999, 9999],
    'Under 0.025': [-9999, 0.025],
    'Under 0.05': [-9999, 0.05],
    'Under 0.1': [-9999, 0.1],
    'Under 0.2': [-9999, 0.2],
    'Under 0.3': [-9999, 0.3],
    'Above 0.1': [0.1, 9999],
    'Above 0.2': [0.2, 9999],
    'Above 0.25': [0.25, 9999],
    'Above 0.3': [0.3, 9999],
    'Above 0.4': [0.4, 9999],
    '0.025 - 0.05': [0.025, 0.05],
    '0.05 - 0.1': [0.05, 0.1],
    '0.1 - 0.2': [0.1, 0.2],
    '0.2 - 0.3': [0.2, 0.3],
    '0.3 - 0.4': [0.3, 0.4],
  },

  "Theta Annual %": {
    'Show All': [-9999, 9999],
    'Under 2.5%': [-9999, 2.5],
    'Under 5%': [-9999, 5],
    'Under 10%': [-9999, 10],
    'Under 20%': [-9999, 20],
    'Under 30%': [-9999, 30],
    'Under 40%': [-9999, 40],
    'Under 50%': [-9999, 50],
    'Above 20%': [20, 9999],
    'Above 30%': [30, 9999],
    'Above 40%': [40, 9999],
    'Above 50%': [50, 9999],
    'Above 75%': [75, 9999],
    'Above 100%': [100, 9999],
  },

  "Market Cap": {
    'Show All': [-9999, 9999],
    'Under 100m': [-9999, 100 * 10**6],
    'Under 250m': [-9999, 250 * 10**6],
    'Under 500m': [-9999, 500 * 10**6],
    'Under 1b': [-9999, 1 * 10**9],
    'Under 2.5b': [-9999, 2.5 * 10**9],
    'Under 5b': [-9999, 5 * 10**9],
    'Under 10b': [-9999, 10 * 10**9],
    'Under 25b': [-9999, 25 * 10**9],
    'Under 50b': [-9999, 50 * 10**9],
    'Under 100b': [-9999, 100 * 10**9],
    'Above 100m': [100 * 10 ** 6, 9999],
    'Above 250m': [250 * 10 ** 6, 9999],
    'Above 500m': [500 * 10 ** 6, 9999],
    'Above 1b': [1 * 10 ** 9, 9999],
    'Above 2.5b': [2.5 * 10 ** 9, 9999],
    'Above 5b': [5 * 10 ** 9, 9999],
    'Above 10b': [10 * 10 ** 9, 9999],
    'Above 25b': [25 * 10 ** 9, 9999],
    'Above 50b': [50 * 10 ** 9, 9999],
    'Above 100b': [100 * 10 ** 9, 9999],
    'Above 250b': [250 * 10 ** 9, 9999],
    'Above 500b': [500 * 10 ** 9, 9999],
    'Above 1T': [1 * 10 ** 12, 9999],
  },

  "Total Vol/OI": {
    'Show All': [-9999, 9999],
    'Over 1.0': [1, 9999],
    'Over 2.0': [2, 9999],
    'Over 3.0': [3, 9999],
    'Over 4.0': [4, 9999],
    'Over 5.0': [5, 9999],
  },

  "Call/Put Vol": {
    'Show All': [-9999, 9999],
    'Over 1.0': [1, 9999],
    'Over 2.0': [2, 9999],
    'Over 3.0': [3, 9999],
    'Over 4.0': [4, 9999],
    'Over 5.0': [5, 9999],
    'Under 1.0': [-9999, 1],
    'Under 0.5': [-9999, 0.5],
    'Under 0.33': [-9999, 0.33],
    'Over 0.25': [-9999, 0.25],
    'Under 0.2': [-9999, 0.2],
  },

  "Call/Put OI": {
    'Show All': [-9999, 9999],
    'Over 1.0': [1, 9999],
    'Over 2.0': [2, 9999],
    'Over 3.0': [3, 9999],
    'Over 4.0': [4, 9999],
    'Over 5.0': [5, 9999],
    'Under 1.0': [-9999, 1],
    'Under 0.5': [-9999, 0.5],
    'Under 0.33': [-9999, 0.33],
    'Under 0.25': [-9999, 0.25],
    'Under 0.2': [-9999, 0.2],
  },

  "UOA Vol/OI": {
    'Show All': [-9999, 9999],
    'Over 1.0': [1, 9999],
    'Over 2.0': [2, 9999],
    'Over 3.0': [3, 9999],
    'Over 4.0': [4, 9999],
    'Over 5.0': [5, 9999],
    'Over 7.5': [7.5, 9999],
    'Over 10': [10, 9999],
    'Over 20': [20, 9999],
    'Over 30': [30, 9999],
    'Over 40': [40, 9999],
    'Over 50': [50, 9999],

  },

  "Call Vol/OI": {
    'Show All': [-9999, 9999],
    'Over 1.0': [1, 9999],
    'Over 2.0': [2, 9999],
    'Over 3.0': [3, 9999],
    'Over 4.0': [4, 9999],
    'Over 5.0': [5, 9999],
  },

  "Put Vol/OI": {
    'Show All': [-9999, 9999],
    'Over 1.0': [1, 9999],
    'Over 2.0': [2, 9999],
    'Over 3.0': [3, 9999],
    'Over 4.0': [4, 9999],
    'Over 5.0': [5, 9999],
  },
};

export const descriptions = {
  "Overview": {
    "Ticker": "Ticker",
    "Type": "Option type, either Call or Put",
    "Strike": "Strike price of the option",
    "Share Price": "Share price of the stock",
    "Bid": "Top of book Bid of the option",
    "Ask": "Top of book Ask of the option",
    "Last": "Last trade price of option contract. It can fall outside of Bid-Ask for low liquidity options",
    "% of Strike": "Last traded price divided by Strike",
    "% OTM": "How far Out The Money is the option",
    "Volume": "Today's traded volume for the option",
    "Open Interest": "Open interest of the option, as of previous trading close. This value only updates once, at the start of each trading day",
    "% OTM": "How far Out The Money is the option",
    "IV": "Implied Volatility",
  },
  "Greeks": {
    "Delta": "The point movement of the option contract for every point the stock moves. Note: The filter operates on the absolute value of Delta",
    "Gamma": "Rate of change of delta",
    "Vega": "Measures an option’s sensitivity to implied volatility",
    "Theo": "Theoretical value of the option, often used to calculate mark to market profit or loss on open option positions",
    "Theta": "The daily option value loss in dollars due to time decay",
    "Theta Annual %": "Annualized % gain on collateral if you do cash secured puts or covered calls. Formula for Puts : Theta/Strike * 100 * 365, Formula for Calls: Theta/Stock Price * 100 * 365",
    "Theta Daily %": "Daily % gain on collateral if you do cash secured puts or covered calls. Formula for Puts : Theta/Strike * 100, Formula for Calls: Theta/ Stock Price * 100",
  },
  "Technicals": {
    "SMA200": "200 day Simple Moving Average",
    "SMA200 %": "Formula: (Current Share Price-SMA200)/SMA200 * 100",
    "SMA50": "50 day Simple Moving Average",
    "SMA50 %": "Formula: (Current Share Price-SMA50)/SMA50 * 100",
    "Short Interest": "% Shorted of float",
    "Earnings Date": "The next earnings date",
  },

  "ST Technicals": {
    "Close to Open": "Share Price difference between Yesterday's close and Today's Open",
    "Close to Open %": "% Share Price difference between Yesterday's close and Today's Open",
    "5 Days Chg": "Share Price difference between Close of 5 trading days ago and Today's Open. Example if today's a Wednesday, it will compare today's Open to last Wednesday's Close",
    "5 Days Chg %": "% Share Price difference between Close of 5 trading days ago and Today's Open. Example if today's a Wednesday, it will compare today's Open to last Wednesday's Close",
  },

  "Fundamentals": {
    "Beta": "Correlation with the S&P500",
    "Forward PE": "Forward PE ratio, share price divided by earnings estimates for next fiscial year.",
    "PE ratio": "Trailing PE ratio, share price/earnings for current fiscial year",
    "PS ratio": "Trailing PS ratio, share price/revenue for current fiscial year",
    "PB ratio": "PB ratio, share price/book value",
  },

  "Uoa": {
    "Total Vol/OI": "Total option Volume divided by total Open Interest for all strikes below 0.7 Delta on this stock and expiration. As volume accumulates throughout the day while OI does not, this value rises through the day",
    "Call Vol/OI": "Total Call Volume divided by Call Open Interest for all strikes below 0.7 Delta on this stock and expiration. As volume accumulates throughout the day while OI does not, this value rises through the day",
    "Put Vol/OI": "Total Put Volume divided by Put Open Interest for all strikes below 0.7 Delta on this stock and expiration. As volume accumulates throughout the day while OI does not, this value rises through the day",
    "Call/Put Vol": "Call Volume divided by Put Volume for all strikes below 0.7 Delta on this stock and expiration",
    "Call/Put OI": "Total Call Open Interest divided by Total Put Open Interest for all strikes below 0.7 Delta on this stock and expiration. As OI is static through the day, this value is static through the day.",
    "UOA Vol/OI": "Same as Unusual Option Activity data. For this single contract, Volume divided by Open Interest if Open Interest exceeds 100, otherwise data field is empty."
  },

  "Others": {
    "Contract Symbol": "The option contract symbol",
    "Last Traded": "When was the option last traded",
  },
}

export const descriptionsExpanded = {}

Object.keys(descriptions).forEach(e => {
  Object.keys(descriptions[e]).forEach(f => {
    descriptionsExpanded[f] = descriptions[e][f]
  })
})



const tablenameMap = {
  'arb_op': getHeaderMapArbOP(),
  'arb_up': getHeaderMapArbUP(),
  'call_skew': getHeaderMapSkew('Call'),
  'put_skew': getHeaderMapSkew('Put'),
  'tendies_0.25': getHeaderMapTendies(0.25),
  'tendies_0.5': getHeaderMapTendies(0.5),
  'tendies_1': getHeaderMapTendies(1),
  'uoa': getHeaderMapUOA(),
}

export const getCSVHeaderCustom = (tablename) => {
  let headerMapScanner = tablenameMap[tablename]
  let alreadyPlaced = {}
  let headers = []
  Object.keys(headerMapScanner).forEach(e => {
    
        headers.push({ 'label': headerMapScanner[e], 'key': e })
      
  })
  return headers
}
