import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { APIURL } from "../../../config";
import Toast from "../../../Components/Toast"

const searchFail = {
    'title': 'Ticker not found!',
    'body': 'If the ticker is missing, drop me a message at kayin@fdscanner.com',
    'color': 'danger'
}

export default function Search() {
  const history = useHistory();

  const [showToast, setShowToast] = useState(false)
  const [toastInfo, setToastInfo] = useState(searchFail)

  const clickedSave = () => {
      setShowToast(true)
      setTimeout(() => {
          setShowToast(false)
      }, 5000)
  }

  const handleSearch = async () => {
    let ticker = document.getElementById("TickerSearchBar").value
    ticker = ticker.split(' ').join('')
    let exist = await getUniverse(ticker)
    if (exist){
        history.push(`/ticker/${ticker}`);
    } else {
        console.log('dont exist')
        clickedSave()
    }
  };

  const getUniverse = async (ticker) => {
    const res = await fetch(`${APIURL}/valid-ticker?ticker=${ticker.toUpperCase()}`);
    if (res.status == 200) {
      const json = await res.json();
      console.log(json);
      if (json.exist == '1'){
          return true
      }
    } else {
      console.log("API CALL FAIL");
    }
    return false
  };

  return (
    <div className="form-inline ml-3">
        {showToast && <Toast title={toastInfo.title} body={toastInfo.body} color={toastInfo.color} />}
      <div className="input-group input-group-sm">
        <input
          className="form-control form-control-navbar"
          type="search"
          placeholder="Search Ticker"
          aria-label="Search"
          id="TickerSearchBar"
          onKeyDown={(e) => {
            e.keyCode == 13 && handleSearch();
          }}
        />
        <div className="input-group-append">
          <button
            className="btn btn-navbar"
            onClick={() => handleSearch()}
          >
            <i className="fas fa-search" />
          </button>
        </div>
      </div>
    </div>
  );
}

//<Redirect to={`/ticker/${document.getElementById('TickerSearchBar').value}`} />
