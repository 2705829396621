import React, { useEffect, useState } from "react";
import Template from "../../../Components/Template";
import ContentHeader from "../../../Components/ContentHeader";
import DisplayContent from "../DisplayContent";
import { fil } from "date-fns/locale";

export default function BookmarkedFilter() {
  const [allFilters, setAllFilters] = useState(JSON.parse(localStorage.getItem("savedFilters") || "{}"))

  useEffect(() => {}, []);

  const deleteFilter = (key) => {
    let currentFilter = JSON.parse(localStorage.getItem("savedFilters"))
    delete currentFilter[key]
    localStorage.setItem("savedFilters", JSON.stringify(currentFilter))
    setAllFilters(currentFilter)
  }

  const loadFilter = (key) => {
    sessionStorage.setItem('currentFilter', JSON.stringify(allFilters[key]))
    window.location.href = `/scanner?load=true`
  } 

  return (
    <Template>
      <ContentHeader text={`Bookmarked Filters`} />
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-body p-0">
            <table className="table table-striped projects">
              <thead>
                <tr>
                  <th style={{ width: "1%" }}>#</th>
                  <th>Filter Name</th>

                  <th>Filter Parameters</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(allFilters).map((e, i) => {
                  let filterContent = allFilters[e];
                  return (
                    <tr key={e}>
                      <td>{i + 1}</td>
                      <td>
                        {e}
                      </td>
                      <td>
                        <DisplayContent content={filterContent} />
                      </td>
                      <td className="project-actions text-right">
                        <a className="btn btn-primary btn-sm" href="#" onClick={() => {loadFilter(e)}}>
                          <i className="fas fa-folder"></i>
                          Load
                        </a>
                        {/* <a className="btn btn-info btn-sm" href="#">
                          <i className="fas fa-pencil-alt"></i>
                          Edit
                        </a> */}
                        <a className="btn btn-danger btn-sm" href="#" onClick={() => deleteFilter(e)}>
                          <i className="fas fa-trash"></i>
                          Delete
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
    </Template>
  );
}
