import React, { useState, useEffect } from "react";
import Xmasimage from "../../assets/img/xmas2.png";
import "./index.css";
import { site_type } from "../../config";
import Cookies from 'js-cookie';

let xmasDate = new Date("Dec 25, 2020 23:59:59").getTime();

const Modal = () => {
  const [show, setShow] = useState(false);
  const [closeTimer, setCloseTimer] = useState(5);

  const [countdown, setCountdown] = useState(9999);

  useEffect(() => { // 
    if (site_type == "pro" || Cookies.get('popupShown')){
        return
    }
    setTimeout(() => setShow(true), 1000 * 120);
  }, []);

  // useEffect(() => {
  //   setInterval(
  //     () => setCountdown(Math.ceil((xmasDate - new Date().getTime()) / 1000)),
  //     1000
  //   );
  // }, []);

  useEffect(() => {
    if (show){
        Cookies.set('popupShown', Date(), { expires: 1 })
    }
  }, [show])

  useEffect(() => {
    if (show) {
      setInterval(
        () => setCloseTimer((prev) => (prev > 0 ? prev - 1 : 0)),
        1000
      );
    }
  }, [show]);

  if (!show){
      return null
  }

  return (
    <div
      className={`modal fade ${show ? "show" : ""}`}
      id="modal-default"
      style={{ paddingRight: 16, display: "block" }}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Unlock FDscanner Pro for just $25!</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => !closeTimer && setShow(false)}>
                ×
              </span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              
              {/* <h5 className="text-center">Sign up before Xmas for 50% off!*</h5><br/> */}
              {/* <h3 className="text-center">
                Time left: 
                <br />
                {Math.floor(countdown / (60 * 60 * 24))}D{" "}
                {Math.floor((countdown % (60 * 60 * 24)) / (60 * 60))}H{" "}
                {Math.floor((countdown % (60 * 60)) / 60)}M{" "}
                {Math.floor(countdown % 60)}S
              </h3> */}
             
              If the app made you over $100 consider upgrading to Pro. After upgrading, you get the following benefits<br /><br />
              <ul>
                <li>Full Access to all 3702 US stocks on all site features</li>
                <li>Members exclusive discord with 100+ members</li>
                <li>Email/Chat support</li>
                <li>Early access to new features</li>
              </ul>
              {/* <br />
              <br />
               instantly get full access to all 1961 US stocks on the scanner, tendies index and all other site features.
              <br />
              <br />
              You also get access to our 100+ member discord, and real time notifications of all my trades.
              <br />
              <br /> */}
              Your support helps me get access to even more computing resources,
              which I will put to good use making FDscanner even better.
            </p>
          </div>
          <div className="modal-footer justify-content-between">
            <a href="https://fdscanner.com/subscribe/" target="_blank">
              <button
                type="button"
                className="btn btn-danger"
                //   onClick={() => setShow(false)}
              >
                Upgrade and Support!
              </button>
            </a>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShow(false)}
              disabled={closeTimer}
            >
              Maybe later ({closeTimer})
            </button>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
};

export default Modal;
