import React, { useEffect, useState } from "react";
import "./index.css";
import { APIURL } from "../../../config";
import DropdownFilter from "../DropdownFilter"
import { FilterNameValueMap } from "../../../Util"

export const UOATab = () => {
    return (
        <div className="row">
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="UOA Vol/OI" options={FilterNameValueMap['UOA Vol/OI']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Total Vol/OI" options={FilterNameValueMap['Total Vol/OI']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Call Vol/OI" options={FilterNameValueMap['Call Vol/OI']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Put Vol/OI" options={FilterNameValueMap['Put Vol/OI']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Call/Put Vol" options={FilterNameValueMap['Call/Put Vol']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Call/Put OI" options={FilterNameValueMap['Call/Put OI']} /></div>
        </div>
    )
}


