import React, { useEffect, useState } from "react";
import "./index.css";
import { APIURL, site_type } from "../../../config";
import DualListBox from "react-dual-listbox";
import Toast from "../../Toast";

import "react-dual-listbox/lib/react-dual-listbox.css";
import Tippy from "@tippyjs/react";

const saveList = {
  'title': 'Saved your ticker list',
  'body': 'Use the dropdown below to load them.',
  'color': 'info'
}

const loadList = {
  'title': 'Ticker list loaded',
  'body': 'Click Run Fiters button to perform the scan.',
  'color': 'info'
}

const deleteList = {
  'title': 'Ticker list deleted',
  'body': 'The ticker list has been deleted.',
  'color': 'warning'
}

export const TickersTab = () => {
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);

  const [savedWatchlists, setSavedWatchlists] = useState({})

  const [showToast, setShowToast] = useState(false)
  const [toastInfo, setToastInfo] = useState({})

  const [etfSelected, setEtfSelected] = useState(0)
  const [etfs, setETFs] = useState([])
  const [spacs, setSpacs] = useState([])
  const [tickers, setTickers] = useState([])

  const clickedSave = () => {
    setShowToast(true)
    setTimeout(() => {
      setShowToast(false)
    }, 5000)
  }

  const onChange = (tickers) => {
    setSelected(tickers);
    sessionStorage.setItem("selected_tickers", JSON.stringify(tickers));
  };

  const generateOptions = () => {
    let o = [];
    let tk = []
    if (etfSelected == 0){
      tk = tickers
    } else if (etfSelected == 1){
      tk = etfs.concat(tickers)
    } else if (etfSelected == 2){
      tk = etfs
    } else {
      tk = spacs
    }
    tk.forEach((ticker) => {
      o.push({ value: ticker, label: ticker });
    });
    setOptions(o);
  };

  const loadTickers = async () => {
    const res = await fetch(
      `${APIURL}/${site_type == "pro" ? "universe" : "spy-tickers"}`
    );
    if (res.status == 200) {
      const json = await res.json();
      setTickers(json.data)
    } else {
      console.log("API CALL FAIL");
    }
  };

  const loadETFs = async () => {
    const res = await fetch(
      `${APIURL}/etf-tickers`
    );
    if (res.status == 200) {
      const json = await res.json();
      setETFs(json.data);
    } else {
      console.log("API CALL FAIL");
    }
  }

  const loadSPACs = async () => {
    const res = await fetch(
      `${APIURL}/spac-universe`
    );
    if (res.status == 200) {
      const json = await res.json();
      setSpacs(json.data);
    } else {
      console.log("API CALL FAIL");
    }
  }

  const loadWatchlist = () => {
    const wl = JSON.parse(localStorage.getItem("saved_watchlists") || '{}');
    setSavedWatchlists(wl)
  }

  const selectWatchlist = (e) => {
    setSelected(savedWatchlists[e])
    onChange(savedWatchlists[e])
  }

  const deleteWatchlist = (e) => {
    let curr = JSON.parse(JSON.stringify(savedWatchlists))
    delete curr[e]
    localStorage.setItem('saved_watchlists', JSON.stringify(curr))
    loadWatchlist()
  }

  const saveWatchlist = (name) => {
    let curr = JSON.parse(JSON.stringify(savedWatchlists))
    curr[name] = selected
    localStorage.setItem('saved_watchlists', JSON.stringify(curr))
    loadWatchlist()
  }

  useEffect(() => {
    sessionStorage.setItem('etfSelected', etfSelected)
  }, [etfSelected])

  useEffect(() => {
    loadTickers();
    loadETFs()
    loadSPACs()
    loadWatchlist()
  }, []);

  useEffect(() => {
    generateOptions()
  }, [tickers, etfs, etfSelected])



  if (options.length == 0) {
    return null;
  }

  return (
    <>
      {showToast && <Toast title={toastInfo.title} body={toastInfo.body} color={toastInfo.color} />}
      <p>
        Build your custom ticker list for FDscanner here, or just scan for a single ticker.
        For dedicated members, select up to 10 tickers here to pull the latest data and run scan.
        <br />
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={etfSelected == 0}
                onClick={() => setEtfSelected(0)}
              />
              <label className="form-check-label">Stocks Only</label>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-3">
            <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={etfSelected == 1}
              onClick={() => setEtfSelected(1)}
            />
              <label className="form-check-label">Stocks + ETFs</label>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-3">
            <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={etfSelected == 2}
              onClick={() => setEtfSelected(2)}
            />
              <label className="form-check-label">ETFs Only</label>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={etfSelected == 3}
                onClick={() => setEtfSelected(3)}
                disabled={site_type != "pro"}
              />
              <label className="form-check-label">SPACs Only</label>
            </div>
          </div>
        </div>
        
        {/* Free tier: S&P 500 stocks. Pro tier: All 1961 US stocks with options. */}
      </p>
      <DualListBox
        options={options}
        selected={selected}
        onChange={onChange}
        canFilter
      />
      {/* <br/>
      <div className="text-center">
        <h6>{selected.length} Tickers Selected</h6>
      </div> */}
      <br />
      <form className="form-horizontal">
        <div className="card-body tkfmpad">
          <div className="form-group row">
            <label htmlFor="inputNameTk" className="col-sm-2 col-form-label">
              Save Watchlist
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                id="inputNameTk"
                placeholder="Give it a name"
              />
            </div>
            <div className="col-sm-2">
              <button
                type="button"
                className="btn btn-info"
                onClick={() => {
                  saveWatchlist(document.getElementById("inputNameTk").value);
                  setToastInfo(saveList)
                  clickedSave()
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        {/* /.card-body */}
      </form>

      <div className="form-horizontal">
        <div className="card-body tkfmpad">
          <div className="form-group row">
            <label htmlFor="fc" className="col-sm-2 col-form-label">
              Load Watchlist
            </label>
            <div className="col-sm-4">
              <select className="form-control" id="fc">
                {Object.keys(savedWatchlists).map(e => {
                  return <option value={e}>{e}</option>
                })}
              </select>
            </div>
            <div className="col-sm-4">
              <label>
                <button
                  className="btn btn-info"
                  type="button"
                  onClick={() => {
                    selectWatchlist(document.getElementById('fc').value)
                    setToastInfo(loadList)
                    clickedSave()
                  }}
                >
                  Load
                </button>
              </label>&nbsp;&nbsp;
              <label>
                <button
                  className="btn btn-danger"
                  type="button"
                  onClick={() => {
                    deleteWatchlist(document.getElementById('fc').value)
                    setToastInfo(deleteList)
                    clickedSave()
                  }}
                >
                  Delete
                </button>
              </label>
            </div>

          </div>
        </div>
      </div>

      {/* <div className="text-center">
        <h6>{selected.length} Tickers Selected</h6>
        <p>
          <b>For Dedicated tier members</b> - You can pull the latest data and
          run the scanner for up to 15 tickers. <br />
          Estimated loading time: {selected.length * 4} seconds
        </p>
        <div className="d-flex justify-content-center align-items-center">
          <Tippy content={content} placement="bottom">
            <button
              type="button"
              className="btn btn-sm btn-block btn-success filter-width"
            >
              Pull Data & Run Filter
            </button>
          </Tippy>
        </div>
      </div> */}
    </>
  );
};
