export const APIURL = "/api2";
export const JSONURL = "/api";

export const AUTHURL = "/auth";
export const DEDICATEDURL = "/dedicated-api";


export const JWTLOGIN = "https://fdscanner.com";
export const site_type = process.env.REACT_APP_SITE_TYPE
export const LOGINURL = "https://member.fdscanner.com/loginapi"


export const MIIALGOURL = "https://app.miialgo.com/api";


export const ROWS_PER_PAGE = localStorage.getItem('rows_per_page') || 20

const clientID_map = {
    'stage.pro.fdscanner.com': 'el7GkPaqgXfYjnVGN7hD7X90vXh3OZFky4lV1Q_NsS84cEIHvFIEKJOvyYIt-bp0',
    'stage.member.fdscanner.com': 'el7GkPaqgXfYjnVGN7hD7X90vXh3OZFky4lV1Q_NsS84cEIHvFIEKJOvyYIt-bp0',
    'member.fdscanner.com': 'aly-bjsef7nr6lECVTMYsbU9asNs9XXzfRFVU7n3zUYMzi1PtMvwDbStcwQTscoh'
}

export const patreon_client_id = clientID_map[window.location.hostname] || 'aly-bjsef7nr6lECVTMYsbU9asNs9XXzfRFVU7n3zUYMzi1PtMvwDbStcwQTscoh'

// console.log = function () {}