import React, { Component, useEffect, useState } from "react";
import { APIURL, JSONURL } from "../../config";
import "./index.css";
import { Line, Bar } from "react-chartjs-2";

// import { Link } from "react-router-dom";
// import HelmetComponent from "../../components/Helmet"

const chartOptions = {
  title: {
    display: true,
    text: "Chart.js - Fixed X and Y Axis",
  },
  scales: {
    xAxes: [
      {
        type: "linear",
        position: "bottom",
        ticks: {
          min: -1,
          max: 8,
          stepSize: 1,
          fixedStepSize: 1,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          min: -2,
          max: 4,
          stepSize: 1,
          fixedStepSize: 1,
        },
      },
    ],
  },
};

export const SkewComputationTab = ({ ticker }) => {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({});

  const [chartLp, setChartLp] = useState({});
  const [chartHp, setChartHp] = useState({});

  const [skewExpiry, setSkewExpiry] = useState("");
  const [skewUpdateTime, setSkewUpdateTime] = useState("");

  const setChartData = () => {
    setChartLp({
      labels: [data.lp_s1_dict.s, data.lp, data.lp_s2_dict.s],
      datasets: [
        {
          label: "Put Option value",
          // data: [data.lp_s1_dict.mid, data.lppp, data.lp_s2_dict.mid],
          data: [
            { x: data.lp_s1_dict.s, y: data.lp_s1_dict.mid },
            { x: data.lp, y: data.lppp },
            { x: data.lp_s2_dict.s, y: data.lp_s2_dict.mid },
          ],
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
          pointRadius: 5,
          pointHoverRadius: 8,
        },
      ],
    });

    setChartHp({
      labels: [data.hp_s1_dict.s, data.hp, data.hp_s2_dict.s],
      datasets: [
        {
          label: "Call Option value",
          // data: [data.lp_s1_dict.mid, data.lppp, data.lp_s2_dict.mid],
          data: [
            { x: data.hp_s1_dict.s, y: data.hp_s1_dict.mid },
            { x: data.hp, y: data.hpcp },
            { x: data.hp_s2_dict.s, y: data.hp_s2_dict.mid },
          ],
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
          pointRadius: 5,
          pointHoverRadius: 8,
        },
      ],
    });
  };

  const loadSkewExpiry = async () => {
    const res = await fetch(`${APIURL}/skew-expiry-date`);
    if (res.status == 200) {
      const json = await res.json();
      setSkewExpiry(json.date);
    } else {
      console.log("API CALL FAIL");
    }
  };

  const loadSkewUpdateTime = async () => {
    const res = await fetch(`${APIURL}/skew-update-time?ticker=${ticker}`);
    if (res.status == 200) {
      const json = await res.json();
      setSkewUpdateTime(json.time);
    } else {
      console.log("API CALL FAIL");
    }
  };

  useEffect(() => {
    if (Object.keys(data).length > 5) {
      setChartData();
    }
  }, [data]);

  const loadInfo = async () => {
    const res = await fetch(`${APIURL}/skew-info?ticker=${ticker}`);
    if (res.status == 200) {
      const json = await res.json();

      let data = json;
      if (data.pd == 0) {
        setData(data);
        setLoaded(true);
        return;
      }

      let all_strike_keys = [
        "hp_s1_dict",
        "hp_s2_dict",
        "lp_s1_dict",
        "lp_s2_dict",
      ];
      for (let j = 0; j < all_strike_keys.length; j++) {
        for (let i = 0; i < Object.keys(data[all_strike_keys[j]]).length; i++) {
          let k = Object.keys(data[all_strike_keys[j]])[i];
          let v = data[all_strike_keys[j]][k];
          v = Math.round(v * 1000) / 1000;
          console.log(k, v);
          data[all_strike_keys[j]][k] = v;
        }
      }

      setData(data);
      setLoaded(true);
    } else {
      console.log("API CALL FAIL");
    }
  };

  useEffect(() => {
    loadInfo();
    loadSkewExpiry();
    loadSkewUpdateTime();
  }, [ticker]);

  if (!loaded) {
    return "not loaded";
  }

  if (data.pd == 0) {
    return (
      <b>
        The options of {ticker} did not pass liquidity requirements for skew
        computation.
      </b>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <h6>Computation Steps for Put/Call Skew</h6>
      </div>

      <div className="d-flex justify-content-center align-items-center">
        <p>
          Expiration used: <b>{skewExpiry}</b>
        </p>
      </div>
      
      <br />

      <div className="col-sm-4 text-right">
        <b></b>
      </div>
      <p>
        <span>
          <b>
            {ticker} (${data.sp}) has a {data.skew_type} skew of {data.pd}.
            Below is how this is computed.
          </b>
        </span>
      </p>
      <b>
        1) With current price of ${data.sp}, Get 90% and 110% of current price
      </b>
      <ul>
        <li>
          90% of price - <b>${data.lp}</b>
        </li>
        <li>
          110% of price - <b>${data.hp}</b>
        </li>
      </ul>
      <b>
        2) Get the 2 call strikes surrounding 110% price, and 2 put strikes
        surrounding 90% price
      </b>
      <div>
        <ul>
          <li>
            For 90% price of <b>${data.lp}</b>, the 2 surrounding{" "}
            <b>put strikes</b> are <b>${data.lp_s1_dict.s}P</b> and{" "}
            <b>${data.lp_s2_dict.s}P</b>
          </li>
          <li>
            For 110% price of <b>${data.hp}</b>, the 2 surrounding{" "}
            <b>call strikes</b> are <b>${data.hp_s1_dict.s}C</b> and{" "}
            <b>${data.hp_s2_dict.s}C</b>
          </li>
        </ul>
        Next, we get the bid/ask of these 4 options to compute Midpoint price.
        And perform a liquidity check, <b>Bid-Ask spread must be below 25%</b>,
        otherwise the ticker is excluded from skew computation.
        <br />
        <br />
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Options Data</h3>
          </div>
          {/* /.card-header */}
          <div className="card-body p-0">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Option</th>
                  <th>Bid</th>
                  <th>Ask</th>

                  <th>Midpoint</th>
                  <th>Bid-Ask Spread</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>${data.lp_s1_dict.s}P</td>
                  <td>${data.lp_s1_dict.b}</td>
                  <td>${data.lp_s1_dict.a}</td>
                  <td>${Math.round(data.lp_s1_dict.mid * 1000) / 1000}</td>
                  <td>
                    ({data.lp_s1_dict.a} - {data.lp_s1_dict.b})/
                    {data.lp_s1_dict.b} ={" "}
                    {(
                      ((data.lp_s1_dict.a - data.lp_s1_dict.b) /
                        data.lp_s1_dict.b) *
                      100
                    ).toFixed(3)}
                    %
                  </td>
                </tr>
                <tr>
                  <td>${data.lp_s2_dict.s}P</td>
                  <td>${data.lp_s2_dict.b}</td>
                  <td>${data.lp_s2_dict.a}</td>
                  <td>${Math.round(data.lp_s2_dict.mid * 1000) / 1000}</td>
                  <td>
                    ({data.lp_s2_dict.a} - {data.lp_s2_dict.b})/
                    {data.lp_s2_dict.b} ={" "}
                    {(
                      ((data.lp_s2_dict.a - data.lp_s2_dict.b) /
                        data.lp_s2_dict.b) *
                      100
                    ).toFixed(3)}
                    %
                  </td>
                </tr>
                <tr>
                  <td>${data.hp_s1_dict.s}C</td>
                  <td>${data.hp_s1_dict.b}</td>
                  <td>${data.hp_s1_dict.a}</td>
                  <td>${Math.round(data.hp_s1_dict.mid * 1000) / 1000}</td>
                  <td>
                    ({data.hp_s1_dict.a} - {data.hp_s1_dict.b})/
                    {data.hp_s1_dict.b} ={" "}
                    {(
                      ((data.hp_s1_dict.a - data.hp_s1_dict.b) /
                        data.hp_s1_dict.b) *
                      100
                    ).toFixed(3)}
                    %
                  </td>
                </tr>
                <tr>
                  <td>${data.hp_s2_dict.s}C</td>
                  <td>${data.hp_s2_dict.b}</td>
                  <td>${data.hp_s2_dict.a}</td>
                  <td>${Math.round(data.hp_s2_dict.mid * 1000) / 1000}</td>
                  <td>
                    ({data.hp_s2_dict.a} - {data.hp_s2_dict.b})/
                    {data.hp_s2_dict.b} ={" "}
                    {(
                      ((data.hp_s2_dict.a - data.hp_s2_dict.b) /
                        data.hp_s2_dict.b) *
                      100
                    ).toFixed(3)}
                    %
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        <br />
        <b>3) Interpolation time!</b>
        <p>
          Now we have the strikes surrounding the 90% and 110% price and their
          value. This step simply interpolates the 2 strikes to get the value of
          the Put strike at exactly <b>${data.lp}</b> and the Call strike at
          exactly <b>${data.hp}</b>.
          <br />
          We believe it's a close enough estimation. After all whether the call
          skew is 2.4 or 2.375, the information you can take away is the same.
        </p>
        <div className="row">
          <div className="col-md-6 col-12">
            <h5>Put Strike Interpolation</h5>
            <Line
              data={chartLp}
              options={{
                scales: {
                  xAxes: [
                    {
                      type: "linear",
                    },
                  ],
                },
              }}
            />
            <h5 className="text-center">
              <b>
                ${data.lp}P value - ${data.lppp}
              </b>
            </h5>
          </div>
          <div className="col-md-6 col-12">
            <h5>Call Strike Interpolation</h5>
            <Line
              data={chartHp}
              options={{
                scales: {
                  xAxes: [
                    {
                      type: "linear",
                    },
                  ],
                },
              }}
            />
            <h5 className="text-center">
              <b>
                ${data.hp}C value - ${data.hpcp}
              </b>
            </h5>
          </div>
        </div>
      </div>
      <b>4) Final step, Compute the Skew</b>
      <p>
        We've done the computations to get the value of a put at{" "}
        <b>${data.lp}</b> and value of a call at <b>${data.hp}</b>. These 2
        signifies the value of a 90% OTM put and 110% OTM call respectively.
        Financial theory states an equally OTM put and call should be priced the
        same, or with minor differences at most due to interest rates, etc.
      </p>
      <p>
        If their price is vastly different, there should be a reason for the
        imbalance. Whether it's speculation activity, limited downside for
        SPACs, upcoming binary events or a dividend, it's up to you to figure
        that out.
      </p>
      {data.skew_type == "call" ? (
        <>
          In this case, the value of the call exceeds the put. We divide the
          call value by the put value: <br />
          <br />
          <h4 className="text-center">
            ${data.hpcp}/${data.lppp} = Call Skew: {data.pd}
          </h4>
        </>
      ) : (
        <>
          In this case, the value of the put exceeds the call. We divide the put
          value by the call value: <br />
          <br />
          <h4 className="text-center">
            ${data.lppp}/${data.hpcp} = Put Skew: {data.pd}
          </h4>
        </>
      )}
      <div className="d-flex justify-content-center align-items-center">
        <p>
          Last Updated: <b>{new Date(skewUpdateTime * 1000).toString().split(" ").slice(1, 5).join(" ")}</b>
        </p>
      </div>
      <br />
      <br />
      <br />
    </>
  );
};
