import React, { useState, useEffect } from "react";
// import Slider, { Range } from "rc-slider";
import Slider from "@material-ui/core/Slider";
// import "rc-slider/assets/index.css";
import { filterMap } from "../../Util";
import "./index.css";

export const RangeSlider = ({ name = "", unit = "", multiplier = 1 }) => {
  const [current, setCurrent] = useState([0, 100]);

  
  const HandleSliderChange = (value) => {
    let currentFilter = JSON.parse(sessionStorage.getItem("currentFilter"));
    const apiNameCode = filterMap[name];
    currentFilter[apiNameCode] = `${value[0] * multiplier},${
      value[1] !== 100 ? value[1] * multiplier : 9999
    }`;
    sessionStorage.setItem("currentFilter", JSON.stringify(currentFilter));
  };

  
  useEffect(() => {
    let initFilters = JSON.parse(sessionStorage.getItem('currentFilter'))
    if (filterMap[name] in initFilters){
        let currValue = initFilters[filterMap[name]].split(',')
        setCurrent([Math.max(parseInt(currValue[0]/multiplier), 0), parseInt(Math.min(currValue[1]/multiplier, 100))])
    }

}, [])

  return (
    <div className="outer">
      <div className="tgt">
        <label>{name}</label>
        <span className="badge bg-primary float-right customLabel">
          {unit == "$" && unit}
          {current[0] * multiplier}
          {unit == "%" && unit} - {unit == "$" && unit}
          {current[1] * multiplier}
          {unit == "%" && unit}
        </span>
      </div>

      <div className="">
        <Slider
          value={[current[0], current[1]]}
          onChange={(e, n) => {
            console.log(n[0], n[1]);
            setCurrent([n[0], n[1]]);
            HandleSliderChange(n);
          }}
          aria-labelledby="range-slider"
        />
        {/* <span className="badge bg-primary float-right">12</span> */}
      </div>
    </div>
  );
};
