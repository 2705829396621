import React, { useEffect, useState } from "react";
import "./index.css";
import { APIURL } from "../../../config";
import DropdownFilter from "../DropdownFilter"
import { FilterNameValueMap } from "../../../Util"

export const FundamentalsTab = () => {
    return (
        <div className="row">
           
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Forward PE" options={FilterNameValueMap['Forward PE']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="PE Ratio" options={FilterNameValueMap['PE Ratio']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="PS Ratio" options={FilterNameValueMap['PS Ratio']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="PB Ratio" options={FilterNameValueMap['PB Ratio']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Beta" options={FilterNameValueMap['Beta']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
            <DropdownFilter name="Market Cap" options={FilterNameValueMap['Market Cap']} /></div>
        </div>
    )
}


