import React, { useState, useEffect } from "react";
import { ScatterChart } from "../../Components/Chart/scatterChart";
import { APIURL, site_type } from "../../config";
import { CustomTable } from "../../Components/CustomTable";
import { getHeaderMapTendiesSummary } from "../../Util/index";
import { Link } from "react-router-dom";

export const TendiesSummary = ({ type }) => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [ntr_multiplier, set_ntr_multiplier] = useState(1);
  const [page, setPage] = useState(0);
  const [scatterData, setScatterData] = useState([]);

  const loadInfo = async (loadall = false) => {
    const res = await fetch(
      `${APIURL}/tendies-index?type=${type}${site_type == 'pro' ? '&p=1' : ''}&page_size=5&page=${page}&loadall=${
        !loaded || loadall ? "1" : "0"
      }${ntr_multiplier != 1 ? `&ntr=${ntr_multiplier}` : ""}`
    );
    if (res.status == 200) {
      const json = await res.json();
      setData(json);
      // console.log(json);
      if (json.all) {
        setScatterData(json.all);
      }
      setLoaded(true);
    } else {
      console.log("API CALL FAIL");
    }
  };

  useEffect(() => {
    loadInfo(true);
  }, [type]);

  return (
    <>
      <div className="row rp">
        <div className="col-md-6"><h6>Top 5 {type} Tendies</h6></div>
        <div className="col-md-6">
          <Link to={`/tendies/${type}`}><button className="btn btn-block btn-info">View all {type} tendies</button></Link>
        </div>
      </div>

      {loaded && (
        <CustomTable
          data={data.data}
          headerMap={getHeaderMapTendiesSummary()}
          currentPage={data.current_page}
          pageSize={5}
        />
      )}
    </>
  );
};
