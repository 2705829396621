import React, { Component, useEffect, useState } from "react";

import "./index.css"

export const DescriptionTab = ({ ticker, data }) => {
    return <>
        <b>{data.name} ({ticker})</b><br /><br />

        {data.divDate ? <p><b>Ex-Dividend Date:</b> <br />{new Date(data.divDate * 1000).toUTCString().split(" ").slice(1, 4).join(" ")}</p> :
            <p><b>No upcoming Dividend Event</b></p>}

        {data.ed ? <p><b>Next Earnings Date:</b><br /> {data.ed}</p> :
            <b>No Upcoming Earnings</b>}<br /><br />

        <b>Summary</b>
        <table className="table table-sm">
            <tbody>
                <tr>
                    <td><b>Price</b></td>
                    <td>${data.p}</td>
                    <td>
                        <b>52 Week Change</b>
                    </td>
                    <td>{data.wk52c}%</td>
                </tr>
                <tr>
                    <td><b>SMA200</b></td>
                    <td>${data.s200}</td>
                    <td>
                        <b>SMA200 Change</b>
                    </td>
                    <td>{data.pd200}%</td>
                </tr>
                <tr>
                    <td><b>SMA50</b></td>
                    <td>${data.s50}</td>
                    <td>
                        <b>SMA50 Change</b>
                    </td>
                    <td>{data.pd50}%</td>
                </tr>
                <tr>
                    <td><b>PE ratio</b></td>
                    <td>{data.tpe}</td>
                    <td>
                        <b>Forward PE</b>
                    </td>
                    <td>{data.fpe}</td>
                </tr>
                <tr>
                    <td><b>PS ratio</b></td>
                    <td>{data.tps}</td>
                    <td>
                        <b>PB ratio</b>
                    </td>
                    <td>{data.pb}</td>
                </tr>
                <tr>
                    <td><b>Profit Margin</b></td>
                    <td>{data.pm}%</td>
                    <td>
                        <b>EPS</b>
                    </td>
                    <td>${data.teps}</td>
                </tr>
                <tr>
                    <td><b>Institutional Holdings</b></td>
                    <td>{data.inhld}%</td>
                    <td>
                        <b>Market Cap</b>
                    </td>
                    <td>{abbreviate_number(data.mcap, 3)}</td>
                </tr>
                <tr>
                    <td><b>Short Interest</b></td>
                    <td>{data.si}%</td>
                    <td>
                        <b>Dividend Yield</b>
                    </td>
                    <td>{data.divYield}%</td>
                </tr>
            </tbody></table>

        <p><b>Company Description</b><br />{data.desc}</p>
    </>
}

const abbreviate_number = (num, fixed) => {

    if (num === null || num === undefined) { return null; } // terminate early
    if (num === 0) { return '0'; } // terminate early
    fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
    var b = (num).toPrecision(2).split("e"), // get power
        k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
        c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
        d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
        e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
    return e;
}