import React, { useEffect, useState } from "react";
import "./index.css";
import { APIURL } from "../../../config";
import DropdownFilter from "../DropdownFilter"
import { FilterNameValueMap } from "../../../Util"

export const LiquidityTab = () => {
    return (
        <div className="row">
            {/* <div className="col-lg-2 col-md-3 col-sm-4">
            <div className="form-group">
                <label>Hide</label>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked
                    />
                    <label className="form-check-label">Zero Bid</label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked
                    />
                    <label className="form-check-label">Zero Open Interest</label>

                </div>
            </div>
            </div> */}
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Zero Bids" options={FilterNameValueMap['Zero Bids']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Volume" options={FilterNameValueMap['Volume']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Open Interest" options={FilterNameValueMap['Open Interest']} /></div>
            <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Last Traded" options={FilterNameValueMap['Last Traded']} /></div>
            {/* <div className="col-lg-2 col-md-3 col-sm-4">
                <DropdownFilter name="Option Spread/Stock Price" options={{
                    'Show All': [0, 9999],
                    '< 0.1%': [0, 0.1],
                    '< 0.2%': [0, 0.2],
                    '< 0.3%': [0, 0.3],
                    '< 0.4%': [0, 0.4],
                    '< 0.5%': [0, 0.5],
                }} /></div> */}
        </div>
    )
}
