import React, { useState, useEffect } from "react";
import { APIURL } from "../../config";
import { CustomTable } from "../../Components/CustomTable";
import { getHeaderMapUOASummary } from "../../Util/index";
import { Link } from "react-router-dom";

export const UoaSummary = ({ type }) => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const loadInfo = async () => {
    const res = await fetch(
      `${APIURL}/unusualvolume?page_size=10`
    );
    if (res.status == 200) {
      const json = await res.json();
      setData(json);
      setLoaded(true);
    } else {
      console.log("API CALL FAIL");
    }
  };

  useEffect(() => {
    loadInfo(true);
  }, [type]);

  return (
    <>
      <div className="row rp">
        <div className="col-md-6"><h6>Top 10 Unusual Option Activity</h6></div>
        <div className="col-md-6 br">
          <Link to="unusualvolume"><button className="btn btn-block btn-info bmax br">View all UOA</button></Link>
        </div>
      </div>

      {loaded && (
        <CustomTable
          data={data.data}
          headerMap={getHeaderMapUOASummary()}
          pageSize={10}
        />
      )}
    </>
  );
};
